import {
  NotificationActionTypes,
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATIONS,
  TOGLE_NOTIFICATIONS
} from './notification.types';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';

export const getNotifications = async (
  dispatch: Dispatch<NotificationActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/notifications`);
    if (typeof res === "undefined" || !res) return;
    const notificationsData = res.data.data;

    dispatch({
      type: GET_NOTIFICATIONS,
      payload: notificationsData
    });
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};

export const deleteNotificitaion = async (
  dispatch: Dispatch<NotificationActionTypes>,
  id: string
): Promise<any> => {
  try {
    const res = await api.delete(`${API_ROOT}/notifications/${id}`);
    if (typeof res === "undefined" || !res) return;
    const notificationsData = res.data.data;

    dispatch({
      type: DELETE_NOTIFICATIONS,
      payload: notificationsData
    });
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};

export const toggleSeen = async (
  dispatch: Dispatch<NotificationActionTypes>,
  id: string
): Promise<any> => {
  try {
    await api.patch(`/notifications/${id}`);
    dispatch({
      type: TOGLE_NOTIFICATIONS,
      payload: id
    });
  } catch (error) {
    if (error.data && error.data.meta)
      toastUtil('error', error.data.meta.message);
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};
