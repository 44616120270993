import * as Yup from 'yup';

export default Yup.object().shape({
  nickname: Yup.string().required('required'),
  customer_id: Yup.string()
    .required('required'),
  token: Yup.string()
    .required('required'),
  password: Yup.string()
    .min(8, 'minLength')
    .required('required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'matching')
    .required('required'),
  tos_accepted: Yup.boolean().oneOf([true], 'matching'),
  age: Yup.boolean().oneOf([true], 'matching')
});
