import { Dispatch } from 'react';
import api from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';
import { Booking } from '../booking/booking.types';
import { DashboardActionTypes, GET_DRAFT_EVENTS } from './dashboard.types';

export const getUpcomingRides = async (): Promise<any> => {
  try {
    const rides = await api.get('/dashboard/upcoming_rides');
    return rides.data.data;
  } catch (err) {
    if (err.data && err.data.meta) toastUtil('error', err.data.meta.message);
    return [];
  }
};

export const getDraftEvents = async (
  dispatch: Dispatch<DashboardActionTypes>
): Promise<Booking[]> => {
  let draftEvents: Booking[] = [];

  try {
    const events = await api.get('/dashboard/draft_events');
    draftEvents = events.data.data;
  } catch (err) {
    const error = err as any;
    if (error.data && error.data.meta)
      toastUtil('error', error.data.meta.message);
  }

  dispatch({
    type: GET_DRAFT_EVENTS,
    payload: draftEvents
  });

  return draftEvents;
};

export const getDraftById = async (
  dispatch: Dispatch<DashboardActionTypes>,
  id: string
): Promise<Booking | null> => {
  return (
    (await getDraftEvents(dispatch)).find((draft) => draft.id === id) || null
  );
};

export const getCustomerInfo = async (): Promise<any> => {
  try {
    const info = await api.get('/dashboard/customer_info');
    return info.data.data;
  } catch (err) {
    if (err.data && err.data.meta) toastUtil('error', err.data.meta.message);
  }
};
