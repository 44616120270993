import React, { useEffect, useState, useMemo } from 'react';
import Select from '../FormField/Select';
import { getLoungeAvailableTimes } from '../../store/booking/booking.actions';
import { SetStateType } from '../../types/utils.types';
import { useTranslation } from 'react-i18next';

interface BookingTime {
  lounge: string;
  time: string;
  setTime: SetStateType<string>;
  showWarning?: boolean;
  date: Date | undefined;
  loadingData?: boolean;
}

interface TimeOptions {
  id: string;
  label: any;
}

const timeFromIsoDate = (isoDate: string) => isoDate.match(/\d\d:\d\d/);

const BookingTime = React.memo(
  ({ lounge, time, setTime, showWarning, date, loadingData }: BookingTime) => {
    const [t] = useTranslation();
    const [availableTimes, setAvailableTimes] = useState<string[]>([]);
    const options = useMemo(() => {
      let times: TimeOptions[] = [];
      if (availableTimes) {
        times = availableTimes.map((t) => ({
          id: t,
          label: timeFromIsoDate(t)
        }));
      }
      return times;
    }, [availableTimes]);
    const setFieldValue = (name: string, value: string) => {
      setTime(value);
    };

    useEffect(() => {
      (async (lounge: string | number) => {
        try {
          const response = await getLoungeAvailableTimes(
            lounge,
            date ? date.toISOString() : ''
          );
          setAvailableTimes(response);
          // check if base center has selected time
          if (time) {
            let timeObj = new Date(time);
            const timeExists = response.find((t: string) => {
              let tObj = new Date(t);
              return (
                timeObj.getUTCHours() === tObj.getUTCHours() &&
                timeObj.getUTCMinutes() === tObj.getUTCMinutes()
              );
            });
            if (!timeExists) {
              setTime('');
            } else {
              setTime(timeExists);
            }
          }
        } catch (err) {
          console.warn('==== fetchReservations error');
        }
      })(lounge);
      // eslint-disable-next-line
    }, [lounge, date]);

    return (
      <div className="BookingTime">
        <Select
          i18nKey="field.bookingTime"
          name="booking-time"
          value={time}
          list={options}
          setFieldValue={setFieldValue}
          disabled={!options.length || loadingData}
          errorMessage={showWarning ? t('booking.timeError') : undefined}
        />
      </div>
    );
  }
);

export default BookingTime;
