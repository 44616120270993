import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TermsPrivacy from '../TermsPrivacy';
import MobileChampionshipDetails from './MobileChampionshipDetails';
import MobileChampionships from './MobileChampionships';

const Mobile = () => (
  <Switch>
    <Route exact path="/mobile/championships/:lang" component={MobileChampionships} />
    <Route
      exact
      path="/mobile/championships/:id/:lang"
      component={MobileChampionshipDetails}
    />
    <Route
      exact
      path="/mobile/terms-of-service/:lang"
      render={props => <TermsPrivacy {...props} isMobilePage isTerms />}
    />
    <Route
      exact
      path="/mobile/privacy-policy/:lang"
      render={props => <TermsPrivacy {...props} isMobilePage />}
    />
  </Switch>
);

export default Mobile;
