import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import './Delete.scss';

interface Props {
  onSuccess: () => void;
}

const Delete = ({ onSuccess }: Props) => {
  const [t] = useTranslation();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleClick = async () => {
    try {
      setSubmitting(true);
      onSuccess();
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <div className="Delete">
      <h2>{t('profile.deleteTitle')}</h2>
      <p>{t('profile.deleteSubtitle')}</p>
      <Button
        onClick={handleClick}
        label={t('profile.yesConfirm')}
        submitting={submitting}
      />
    </div>
  );
};

export default Delete;
