// import React, { useState, ChangeEvent, useEffect, FormEvent } from 'react';
import React, { useState, FormEvent, ChangeEvent } from 'react';
import { format } from 'date-fns';
import {
  injectStripe,
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  Elements
} from 'react-stripe-elements';
import StripeField from './StripeField';
import Button from '../Button';
import CheckboxRadio from '../FormField/CheckboxRadio';
// import TextInput from '../FormField/TextInput';
import { useTranslation } from 'react-i18next';
import { toastUtil } from '../../utils/toast.utils';
import useWithDispatch from '../../hooks/useWithDispatch';
import useWithSelection from '../../hooks/useWithSelection';
import {
  updatePaymentInfo,
  getPaymentInfo,
  deletePaymentInfo
} from '../../store/customer/customer.actions';
import { paymentInfoSelector } from '../../store/customer/customer.selectors';

import './CardForm.scss';

export interface CardForm {
  stripe?: any;
  elements?: any;
  newDetails?: boolean;
  onSubmitCallback?: Function;
}

const CardForm = ({ stripe, newDetails, onSubmitCallback }: CardForm) => {
  const updatePaymentWithDispatch = useWithDispatch(updatePaymentInfo);
  const getPaymentInfoWithDispatch = useWithDispatch(getPaymentInfo);
  const deleteCard = useWithDispatch(deletePaymentInfo);
  const paymentInfo = useWithSelection(paymentInfoSelector);
  const [t] = useTranslation();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [saveCard, setSaveCard] = useState<boolean>(false);
  // const [name, setName] = useState<string>('');
  // const [errors, setErrors] = useState<any>({ name: null });
  // const [touched, setTouched] = useState<any>({ name: false });

  // const handleChange = (evt: ChangeEvent<HTMLInputElement>): void => {
  //   setName(evt.target.value);
  // };

  // const handleBlur = (evt: ChangeEvent<HTMLInputElement>): void => {
  //   if (touched['name'] && !name)
  //     setErrors({ ...errors, name: !evt.target.value ? 'required' : null });

  //   if (!touched[name] && name) setTouched({ name: true });
  // };

  const onCheckboxChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setSaveCard(evt.target.checked);
  };

  const createToken = async () => {
    try {
      const stripeResponse = await stripe.createToken();

      if (stripeResponse.error) {
        const error = { meta: { message: stripeResponse.error.message } };
        throw error;
      }

      return stripeResponse.token.id;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      const response = await deleteCard();
      toastUtil('info', response.meta.message);
      setIsDeleting(false);
    } catch (err) {
      setIsDeleting(false);
      if (err && err.meta.message) toastUtil('error', err.meta.message);
    }
  };

  const onSubmit = async (evt: FormEvent) => {
    evt.preventDefault();
    if (submitting) {
      // We can't submit twice form that's already submitting
      return;
    }
    setSubmitting(true);
    // setTouched({ name: true });
    try {
      let token = await createToken();

      if (onSubmitCallback) {
        await onSubmitCallback({
          card_token: token,
          save_card: saveCard
        });

        if (saveCard) {
          getPaymentInfoWithDispatch();
        }
        return;
      }

      const response = await updatePaymentWithDispatch(token);
      setSubmitting(false);
      toastUtil('info', response.meta.message);
    } catch (err) {
      setSubmitting(false);
      if (err && err.meta.message) toastUtil('error', err.meta.message);
      // if (!name) setErrors({ ...errors, name: 'required' });
    }
  };

  // useEffect(() => {
  //   if (paymentInfo && paymentInfo.name) setName(paymentInfo.name);
  // }, [paymentInfo]);

  return (
    <div className="CardForm">
      <h2>{t(`billing.${newDetails ? 'enter' : 'update'}DetailsTitle`)}</h2>
      <h4>{t(`billing.${newDetails ? 'enter' : 'update'}DetailsSubtitle`)}</h4>
      <form onSubmit={onSubmit}>
        {/* <TextInput
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          i18nKey="billing.cardHolderName"
          value={name}
          errors={errors}
          touched={touched}
        /> */}
        <StripeField
          StripeField={CardNumberElement}
          i18nKey="billing.card_number"
          placeholder={
            paymentInfo &&
            paymentInfo.card_number_fragment &&
            `···· ···· ···· ${paymentInfo.card_number_fragment}`
          }
          brand={paymentInfo && paymentInfo.card_brand}
        />
        <div className="row">
          <div className="col-6">
            <StripeField
              StripeField={CardExpiryElement}
              i18nKey="billing.card_expiration"
              placeholder={
                paymentInfo &&
                paymentInfo.card_expires_on &&
                format(new Date(paymentInfo.card_expires_on), 'MM/yy')
              }
            />
          </div>
          <div className="col-6">
            <StripeField
              StripeField={CardCVCElement}
              i18nKey="billing.card_cvc"
              placeholder={
                paymentInfo && paymentInfo.card_number_fragment && '···'
              }
            />
          </div>
        </div>
        {onSubmitCallback && (
          <CheckboxRadio
            value="saveCard"
            onChange={onCheckboxChange}
            i18nKey="field.saveCardInformation"
            name="saveCardInfo"
          />
        )}
        <div className="CardForm-actions">
          <Button
            disabled={submitting}
            submitting={submitting}
            type="submit"
            label={t(`billing.${newDetails ? 'enter' : 'update'}Submit`)}
          />
          {!onSubmitCallback && (
            <Button
              secondary
              submitting={isDeleting}
              onClick={onDelete}
              disabled={
                !paymentInfo ||
                (paymentInfo && !paymentInfo.card_number_fragment)
              }
              label={t('billing.removeCard')}
            />
          )}
        </div>
      </form>
    </div>
  );
};

const InjectedStripeForm = injectStripe(CardForm);

const WrappedForm = ({ newDetails, onSubmitCallback }: CardForm) => (
  <Elements>
    <InjectedStripeForm
      newDetails={newDetails}
      onSubmitCallback={onSubmitCallback}
    />
  </Elements>
);

export default WrappedForm;
