import React, { forwardRef, useImperativeHandle } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import styles from './ConfirmationDialog.module.scss';

export interface IConfirmationDialogRef {
  open: () => void;
  close: () => void;
}

export interface IConfirmationDialogProps {
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  okText?: string;
  cancelText?: string;
  okIcon?: React.ReactNode;
  type?: 'default' | 'delete';
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog = forwardRef<
  IConfirmationDialogRef,
  IConfirmationDialogProps
>(
  (
    { title, text, okText, cancelText, okIcon, type, onCancel, onConfirm },
    ref
  ) => {
    const [t] = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    useImperativeHandle(
      ref,
      () => ({
        open: () => setOpen(true),
        close: () => setOpen(false)
      }),
      []
    );

    return (
      <Dialog className={styles.dialog} open={open}>
        <DialogContent>
          <div className={styles.content}>
            {title ? (
              <h1 className={styles.content__title}>
                {typeof title === 'string' ? t(title) : title}
              </h1>
            ) : (
              ''
            )}
            {text ? (
              <p className={styles.content__text}>
                {typeof text === 'string' ? t(text) : text}
              </p>
            ) : (
              ''
            )}

            <div className={styles.content__buttons}>
              <Button
                small
                outline
                label={cancelText ? t(cancelText) : ''}
                onClick={onCancel}
              />
              <Button
                small
                deleteClass={type === 'delete'}
                className={styles.content__okButton}
                label={okText ? t(okText) : ''}
                icon={okIcon}
                onClick={onConfirm}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

export default ConfirmationDialog;
