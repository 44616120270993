import React, { useState, useEffect } from 'react';
import AuthAction from '../../components/AuthAction';
import { useParams, Redirect } from 'react-router-dom';
import { accountActivation } from '../../store/customer/customer.actions';
import Loader from '../../components/Loader';

import './AccountActivation.scss';

interface OpenDialog {
  openDialog: (msg: string) => void;
}

const AccountActivation = ({ openDialog }: OpenDialog) => {
  const { customer_id, token } = useParams();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        if (customer_id && token) {
          const response = await accountActivation({ customer_id, token });
          setTimeout(() => {
            openDialog(response.meta.message);
          }, 2000);
        }
      } catch (err) {
        setError(err.meta.message);
      }
    })();
    // eslint-disable-next-line
  }, []);

  if (!token) return <Redirect to="/auth/sign-in" />;

  return (
    <>
      <AuthAction isSignUp onlyBtn />
      <div className="AccountActivation">
        <Loader />
        {error && error}
      </div>
    </>
  );
};

export default AccountActivation;
