import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import visaImg from '../../images/visa-card.svg';
import mcImage from '../../images/mastercard.svg';
import amexImage from '../../images/amex.svg';

const cardMap = new Map([
  ['visa', visaImg],
  ['amex', amexImage],
  ['mastercard', mcImage]
]);

const style = {
  base: {
    fontSize: '17px',
    lineHeight: '40px',
    fontWeight: '400',
    color: '#14006b',
    '::placeholder': {
      color: '#70707d'
    }
  },
  invalid: {
    color: '#14006b'
  }
};

interface StripeField {
  StripeField: React.ElementType;
  i18nKey: string;
  placeholder?: string;
  brand?: string;
  outlined?: boolean;
}

const StripeField = ({
  StripeField,
  i18nKey,
  placeholder,
  brand,
  outlined
}: StripeField) => {
  const [t] = useTranslation();
  const [fieldBrand, setFieldBrand] = useState<string>('');
  const [focus, setFocus] = useState(false);
  const [errorCode, setError] = useState(null);
  const [isEmpty, setEmpty] = useState(true);

  const handleChange = useCallback((elementObj) => {
    elementObj.error ? setError(elementObj.error.code) : setError(null);
    setEmpty(elementObj.empty);
    if (elementObj.brand) {
      setFieldBrand(elementObj.brand.toLowerCase());
    }
  }, []);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <div
      className={`FormField ${!isEmpty || placeholder ? 'has-value' : ''} ${
        outlined ? 'outlined' : ''
      }`}
    >
      {i18nKey && <label>{t(`${i18nKey}.label`)}</label>}
      <div
        className={`FormField-input stripe-field ${
          brand ? 'is-card-field' : ''
        } ${focus ? 'is-focused' : ''} ${outlined ? 'outlined' : ''}`}
      >
        <StripeField
          onChange={handleChange}
          style={style}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={isEmpty && !focus && placeholder ? placeholder : ''}
        />
        {isEmpty && brand && !focus && (
          <img src={cardMap.get(brand.toLowerCase())} alt="" />
        )}
        {!isEmpty && fieldBrand && cardMap.has(fieldBrand.toLowerCase()) && (
          <img src={cardMap.get(fieldBrand.toLowerCase())} alt="" />
        )}
        <span className="FormField-focusIndicator" />
      </div>
      {errorCode && (
        <p className="FormField-error">{t(`${i18nKey}.${errorCode}`)}</p>
      )}
    </div>
  );
};

export default StripeField;
