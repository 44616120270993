const links = {
  top: [
    {
      i18nKey: 'header.about',
      url: 'https://www.racing-unleashed.com/about-us'
    },
    {
      i18nKey: 'header.connect',
      url: 'https://www.racing-unleashed.com/details/news'
    },
    {
      i18nKey: 'header.signin',
      url: 'https://formula-v.com/en',
      hideOnSignIn: true,
      isReactLink: true
    }
  ],
  bottom: [
    {
      i18nKey: 'header.dashboard',
      to: '/'
    },
    {
      i18nKey: 'header.rides',
      to: '/booking'
    },
    {
      i18nKey: 'header.ranking',
      to: '/ranking'
    },
    {
      i18nKey: 'header.championships',
      to: '/championships'
    },
    {
      i18nKey: 'header.events',
      to: '/events'
    }
  ]
};

export default links;
