import React, { useMemo } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import styles from './FoodStep.module.scss';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { EventProduct } from '../../../store/eventProducts/eventProducts.types';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';
import CheckboxRadio from '../../FormField/CheckboxRadio';
import AppFormField from '../../AppFormField/AppFormField';
import AppTextarea from '../../AppTextarea';
import { getFormError } from '../../../utils/get-form-error.utils';
import { useTranslation } from 'react-i18next';
import { requiredTextValidator } from '../../../validators/requiredText.validator';

export interface IFoodStepProps
  extends Pick<
    IBaseCardProps,
    | 'collapsed'
    | 'onForward'
    | 'onBack'
    | 'invalid'
    | 'revalidateStep'
    | 'isLoading'
  > {
  options: EventProduct[];
}

const FoodStep: React.FC<IFoodStepProps> = ({
  options,
  revalidateStep,
  ...props
}) => {
  const [t] = useTranslation();
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext<IEventBookForm>();

  const isSpecialRequest = watch('isSpecialRequest');
  const selectedOption = watch('foodId');
  const collapsedValue = useMemo(() => {
    const selected = options.find(({ id }) => id === selectedOption);

    return selected ? selected.label : '';
  }, [selectedOption, options]);

  return (
    <BaseCard
      title="booking.steps.food.title"
      subtitle="booking.steps.food.subtitle"
      forwardTitle="booking.steps.food.forwardButton"
      collapseData={collapsedValue}
      {...props}
      onForward={selectedOption ? props.onForward : undefined}
    >
      <div className={styles.foodStep}>
        <Controller
          control={control}
          name="foodId"
          rules={{
            required: 'Required'
          }}
          render={({ value, onChange }) => (
            <div className={styles.foodStep__grid}>
              {options.map(
                ({
                  id,
                  label,
                  price,
                  currency,
                  description,
                  product_image_url
                }) => {
                  const details = description.split('\r\n');

                  return (
                    <div
                      className={classNames(styles.card, {
                        [styles.card_selected]: value === id
                      })}
                      key={id}
                      onClick={() => {
                        onChange(id);
                        revalidateStep && revalidateStep();
                      }}
                    >
                      {product_image_url ? (
                        <img
                          className={styles.card__image}
                          src={product_image_url}
                          alt=""
                        />
                      ) : (
                        ''
                      )}
                      <h4 className={styles.cardTitle}>
                        <span className={styles.cardTitle__name}>{label}</span>
                        <span className={styles.cardTitle__priceContainer}>
                          <span className={styles.cardTitle__price}>
                            {price} {currency}
                          </span>
                          <span className={styles.cardTitle__caption}>
                            /{t('booking.steps.food.person')}
                          </span>
                        </span>
                      </h4>
                      {details.length > 1 ? (
                        <ul
                          className={classNames(
                            styles.card__details,
                            styles.card__details_paddingLeft
                          )}
                        >
                          {details.map((detail, index) => (
                            <li key={index}>{detail}</li>
                          ))}
                        </ul>
                      ) : (
                        <div className={styles.card__details}>{details[0]}</div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          name="isSpecialRequest"
          render={({ value, onChange }) => (
            <CheckboxRadio
              primary
              checked={value}
              name="additionalSpecialRequest"
              value="additionalSpecialRequest"
              i18nKey="field.additionalSpecialRequest.label"
              onChange={() => {
                onChange(!value);

                if (value) {
                  setValue('specialRequest', '');
                  clearErrors('specialRequest');
                  revalidateStep && revalidateStep();
                }
              }}
            />
          )}
        />
        <div
          className={classNames(styles.collapsableWrapper, {
            [styles.collapsableWrapper_collapsed]: !isSpecialRequest
          })}
        >
          <Controller
            control={control}
            name="specialRequest"
            rules={
              isSpecialRequest
                ? {
                    ...requiredTextValidator({
                      required: t('field.specialRequest.required'),
                      emojis: t('field.validations.emojisNotAllowed')
                    })
                  }
                : undefined
            }
            render={({ value, onChange }) => (
              <AppFormField
                label="field.specialRequest.label"
                error={getFormError('specialRequest', errors)}
                currentLength={value.length}
                maxLength={512}
                required
              >
                <AppTextarea
                  value={value}
                  rows={4}
                  maxLength={512}
                  placeholder="field.specialRequest.placeholder"
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                />
              </AppFormField>
            )}
          />
        </div>
      </div>
    </BaseCard>
  );
};

export default FoodStep;
