import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getChampionships } from '../../store/championships/championships.actions';
import './MobileChampionshipDetails.scss';

const MobileChampionshipDetails = () => {
  const { lang } = useParams();
  const [championships, setChampionships] = useState([]);
  const _isMounted = useRef(true);

  const fetchChallanges = async () => {
    try {
      const response = await getChampionships(lang);
      if (_isMounted.current) setChampionships(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchChallanges();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="ru-championships ru-championship-mobile">
      {championships.length > 0 &&
        championships.map((championship: any) => (
          <Link
            to={`/mobile/championships/${championship.id}/${lang}`}
            className="ru-championship ru-championship--preview"
          >
            <h3>{championship.title}</h3>
            <img src={championship.featured_image_medium} alt="" />
            <div className="ru-championship-content">
              {championship.description_excerpt}
            </div>
          </Link>
        ))}
    </div>
  );
};

export default MobileChampionshipDetails;
