import { CodeActionTypes, GET_EVENT_TYPES, GET_GENDERS } from './code.types';
import i18n from '../../i18n/index';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';

const options = {
  headers: {
    'x-locale': i18n.language
  }
};

export const getGenderCodes = async (
  dispatch: Dispatch<CodeActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/codes/genders`, options);

    var codeData = [];
    if (typeof res.data != 'undefined') {
      codeData = res.data.data;
    }

    dispatch({
      type: GET_GENDERS,
      payload: codeData
    });
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const getEventTypeCodes = async (
  dispatch: Dispatch<CodeActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/codes/event_types`, options);

    var codeData = [];
    if (typeof res.data != 'undefined') {
      codeData = res.data.data;
    }

    dispatch({
      type: GET_EVENT_TYPES,
      payload: codeData
    });
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};
