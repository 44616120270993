import api from '../../config/api.config';
import i18n from '../../i18n';
import { objectToFormData } from '../../utils/object-to-form-data';
import { BookingPayload, TimeSlot } from './booking.types';
import qs from 'qs';

export const getTimeSlots = async (
  isGroupRide?: boolean,
  lounge?: string,
  duration?: number,
  controller?: AbortController
): Promise<TimeSlot[]> => {
  const params = {
    group_ride: isGroupRide || null,
    lounge_id: lounge || null,
    duration_in_minutes: duration || null
  };
  try {
    const response = await api.get('/time_slots', {
      params,
      signal: controller && controller.signal
    });
    return response.data.data.sort(
      (a: TimeSlot, b: TimeSlot) => +a.price - +b.price
    );
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const getProductTimeSlots = async (
  controller?: AbortController
): Promise<TimeSlot[]> => {
  try {
    const response = await api.get('/time_slots/products', {
      signal: controller && controller.signal
    });
    return response.data.data.sort(
      (a: TimeSlot, b: TimeSlot) => +a.price - +b.price
    );
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const getAvailableDates = async (
  simulators: number,
  time_slot_id: string,
  lounge_id: string,
  date_from: string,
  date_to: string,
  selected_time: string,
  controller?: AbortController
) => {
  try {
    const response = await api.get('/available_dates', {
      params: {
        date_from,
        date_to,
        simulators,
        time_slot_id,
        lounge_id,
        selected_time: selected_time || null
      },
      signal: controller && controller.signal
    });
    return response.data.data;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const getLoungeAvailableTimes = async (
  lounge_id: string | number,
  date: string,
  isEvent?: boolean,
  controller?: AbortController
): Promise<string[]> => {
  try {
    const response = await api.get(`/lounges/${lounge_id}/available_times`, {
      params: {
        date,
        event_times: !!isEvent
      },
      signal: controller && controller.signal
    });
    return response.data.data;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const getAvailableTimes = async (
  lounge_id: string,
  time_slot_id: string,
  date: string,
  duration: number,
  simulators: number,
  controller?: AbortController
): Promise<string[]> => {
  try {
    const response = await api.get(`/available_times`, {
      params: {
        lounge_id,
        time_slot_id,
        date,
        duration,
        simulators
      },
      signal: controller && controller.signal
    });
    return response.data.data;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const createReservation = async (
  booking: Partial<BookingPayload>,
  isRide: boolean = false
) => {
  const formData = new FormData();

  objectToFormData(booking, formData);

  const body = isRide ? booking : formData;
  const params = isRide
    ? undefined
    : {
        headers: { 'Content-Type': 'multipart/form-data' }
      };

  try {
    const response = await api.post('/reservations', body, params);
    return response.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const deleteReservation = async (id: string) => {
  try {
    const response = await api.delete('/reservations/cancel', {
      params: { existing_reservation_id: id }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const fetchSummary = async (
  {
    start_time,
    time_slot_id,
    simulators,
    lounge_id,
    coupon_code,
    rides_sharing,
    event_information
  }: Partial<BookingPayload>,
  controller?: AbortController
) => {
  const languageParams = {
    headers: {
      'x-locale': i18n.language
    }
  };

  try {
    const response = await api.get('/reservations/summary_info', {
      ...languageParams,
      params: {
        start_time,
        time_slot_id,
        simulators,
        lounge_id,
        coupon_code: coupon_code
          ? api.prototype.fixedEncodeURIComponent(coupon_code)
          : undefined,
        rides_sharing,
        event_information
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
      signal: controller && controller.signal
    });

    return response.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};
