import { RegisterOptions } from 'react-hook-form';

type TErrors = {
  required?: string;
  maxLength: string;
  invalid: string;
};

export const phoneNumberValidator = ({
  required,
  maxLength,
  invalid
}: TErrors): RegisterOptions => ({
  required: required,
  maxLength: { value: 20, message: maxLength },
  validate: (value: string | undefined) => {
    if (value && !/^(\+[0-9-]+)\s[0-9\-\s]+[0-9]$/.test(value)) {
      return invalid;
    }
  }
});
