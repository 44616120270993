import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { toastUtil } from '../../../utils/toast.utils';
import { signIn } from '../../../store/customer/customer.actions';
import useWithDispatch from '../../../hooks/useWithDispatch';
import { formModel, initialValues } from './formModel';
import FormField from '../../../components/FormField';
import CheckboxRadio from '../../../components/FormField/CheckboxRadio';
import Button from '../../../components/Button';
import validationSchema from './validationSchema';

const SignInForm = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [t] = useTranslation();
  const signInAction = useWithDispatch(signIn);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        await signInAction(values);
        window.BroadcastChannel &&
          new BroadcastChannel('onAuth').postMessage(true);
      } catch (err) {
        setSubmitting(false);
        if (err.meta.message)
          formMethods.setFieldError('password', err.meta.message);
        if (err.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (err && err.meta) toastUtil('error', err.meta.message);
      }
    }
  });
  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <div className="SignInForm Auth-form">
      <h2>{t('auth.signIn.title')}</h2>
      <h4>{t('auth.signIn.subtitle')}</h4>
      <form onSubmit={formik.handleSubmit}>
        {formModel.map((field) => (
          <FormField
            key={field.name}
            i18nKey={field.i18nKey}
            type={field.type}
            errors={formik.errors}
            touched={formik.touched}
            setFieldValue={formik.setFieldValue}
            {...formik.getFieldProps(field.name)}
          />
        ))}
        <CheckboxRadio
          onChange={formik.handleChange}
          name="remember_me"
          value={formik.values.remember_me}
          i18nKey="field.remember_me.label"
        />
        <div className="Auth-form-footer">
          <Button
            type="submit"
            disabled={submitting || !isValidForm}
            submitting={submitting}
            label={t('field.signIn')}
          />
          <Link to="/auth/forgot-password">
            {t('auth.forgotPassword.linkToPage')}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;
