import React, { useState } from 'react';
import styles from './SurveyWelcomeStep.module.scss';
import AppFormField from '../AppFormField/AppFormField';
import { SelectItemRenderer, SelectRenderer } from 'react-dropdown-select';
import { ILanguage, langs } from '../Header/Lang';
import classNames from 'classnames';
import AppSelect from '../AppSelect/AppSelect';
import { useTranslation } from 'react-i18next';

interface IWelcomeStepProps {
  title: React.ReactNode;
  description: React.ReactNode[];
}

const SurveyWelcomeStep: React.FC<IWelcomeStepProps> = ({
  title,
  description
}) => {
  const [, i18next] = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<ILanguage>(langs[0]);

  const contentRenderer: (props: SelectRenderer<ILanguage>) => JSX.Element = ({
    state
  }) => {
    const value = state.values[0];
    return value ? (
      <div
        className={classNames(
          styles.languageSelect__option,
          styles.languageSelect__option_withoutPadding
        )}
      >
        <img className={styles.option__image} src={value.flag} alt="" />
        {value.text}
      </div>
    ) : (
      <></>
    );
  };

  const itemRenderer: (props: SelectItemRenderer<ILanguage>) => JSX.Element = ({
    item,
    methods,
    state
  }) => {
    const current = state.values[0];

    return (
      <div
        className={classNames(styles.languageSelect__option, {
          [styles.languageSelect__option_selected]:
            current && current.key === item.key
        })}
        onClick={() => methods.addItem(item)}
      >
        <img className={styles.option__image} src={item.flag} alt="" />
        {item.text}
      </div>
    );
  };

  return (
    <div className={styles.welcome}>
      <h1 className={styles.welcome__title}>{title}</h1>
      {description.map((paragraph, index) => (
        <p key={index} className={styles.welcome__info}>
          {paragraph}
        </p>
      ))}
      <AppFormField
        className={styles.welcome__formField}
        label="eventParticipantDriver.fields.surveyLanguage.label"
      >
        <AppSelect<ILanguage>
          size="big"
          placeholder=""
          labelField="text"
          valueField="key"
          searchable={false}
          contentRenderer={contentRenderer}
          itemRenderer={itemRenderer}
          options={langs}
          values={selectedLanguage ? [selectedLanguage] : []}
          onChange={(value) => {
            i18next.changeLanguage(value[0].key);
            setSelectedLanguage(value[0]);
          }}
        />
      </AppFormField>
    </div>
  );
};

export default SurveyWelcomeStep;
