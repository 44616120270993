import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Route, RouteChildrenProps, Switch } from 'react-router-dom';
import EventBooking from '../EventBooking';
import styles from './Public.module.scss';
import useWithDispatch from '../../hooks/useWithDispatch';
import { getCountries } from '../../store/country/country.actions';
import PartcipantForm from '../SignIntoEvent/SignIntoEvent';
import classNames from 'classnames';
import EventParticipantDrivers from '../EventParticipantDrivers/EventParticipantDrivers';
import ConfirmSubscription from '../ConfirmSubscription/ConfirmSubscription';
// import RideBooking from '../RideBooking/RideBooking';

const Public: React.FC<RouteChildrenProps> = ({ match }) => {
  const [data] = useState<any>({});
  const countriesDispatch = useWithDispatch(getCountries);

  useEffect(() => {
    countriesDispatch();
    // eslint-disable-next-line
  }, []);

  // TODO: Fix when have time
  const isSurvey = !window.location.href.includes('booking');

  return (
    <>
      <Header
        data={data}
        hideTop={isSurvey}
        logoLink={process.env.REACT_APP_WEBSITE_URL}
        hideNavigation
      />
      <section
        className={classNames(styles.public, {
          [styles.public_shortHeader]: isSurvey
        })}
      >
        <Switch>
          {/* <Route
            exact
            path={`${match ? match.url : ''}/ride-booking`}
            component={RideBooking}
          /> */}
          <Route
            exact
            path={`${match ? match.url : ''}/event-booking`}
            component={EventBooking}
          />
          <Route
            exact
            path={`${match ? match.url : ''}/event-booking/:id`}
            component={EventBooking}
          />
          <Route
            exact
            path={`${match ? match.url : ''}/sign-into-event/:id`}
            component={PartcipantForm}
          />
          <Route
            exact
            path={`${match ? match.url : ''}/event-participant-drivers/:id`}
            component={EventParticipantDrivers}
          />
          <Route
            exact
            path={`${match ? match.url : ''}/confirm-subscription/:token`}
            component={ConfirmSubscription}
          />
        </Switch>
      </section>
    </>
  );
};

export default Public;
