import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Protected from './views/Protected';
import Public from './views/Public';
import Mobile from './views/Mobile';
import Auth from './views/Auth';
import Fb from './views/Fb';
import TermsPrivacy from './views/TermsPrivacy';

import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  return (
    <>
      <ToastContainer />

      <Router>
        <Switch>
          <Route path="/public" component={Public} />
          <Route path="/mobile" component={Mobile} />
          <Route
            exact
            path="/terms-of-service/"
            render={(props) => <TermsPrivacy {...props} isTerms />}
          />
          <Route
            exact
            path="/privacy-policy"
            render={(props) => <TermsPrivacy {...props} />}
          />
          <Route path="/auth" component={Auth} />
          <Route path="" component={Protected} />
        </Switch>
        <Fb />
      </Router>
    </>
  );
};

export default App;
