import React, { useState } from 'react';
import AppSelect, { AppSelectProps } from '../AppSelect/AppSelect';
import styles from './AppSelectWithSearchAndIcon.module.scss';
import { SelectRenderer } from 'react-dropdown-select';
import { isSubstring } from '../../utils/is-substring.utils';
import SearchBox from '../SearchBox';
import { AutoSizer, List } from 'react-virtualized';
import classNames from 'classnames';

export interface AppSelectWithIconItem {
  id: string;
  label: string;
  image: string;
}

const searchHeight = 60;
const itemHeight = 40;

const AppSelectWithSearchAndIcon = (
  props: AppSelectProps<AppSelectWithIconItem>
) => {
  const [search, setSearch] = useState<string>('');
  const { placeholder, options, values } = props;

  const contentRenderer: (
    props: SelectRenderer<AppSelectWithIconItem>
  ) => JSX.Element = ({ state }) => {
    const value = state.values[0];

    if (!value)
      return (
        <div className={classNames(styles.option, styles.option_placeholder)}>
          {placeholder}
        </div>
      );

    return (
      <div className={classNames(styles.option, styles.option_withoutPadding)}>
        <img className={styles.option__image} src={value.image} alt="" />
        {value.label}
      </div>
    );
  };

  const dropdownRenderer: (
    props: SelectRenderer<AppSelectWithIconItem>
  ) => JSX.Element = ({ methods }) => {
    const filteredCountries = search
      ? options.filter(({ label }) => isSubstring(label, search))
      : options;

    const listHeight = filteredCountries.slice(0, 5).length * itemHeight;

    return (
      <div style={{ height: listHeight + searchHeight }}>
        <SearchBox
          className={styles.search}
          value={search}
          onChange={setSearch}
        />
        <AutoSizer style={{ height: '200px' }}>
          {({ width }) => (
            <List
              className={styles.list}
              height={listHeight}
              rowCount={filteredCountries.length}
              rowHeight={itemHeight}
              width={width - 2}
              rowRenderer={({ index, style, key }) => {
                const item = filteredCountries[index];

                return (
                  <div
                    key={key}
                    className={classNames(styles.option, {
                      [styles.option_selected]:
                        values.length && values[0].id === item.id
                    })}
                    style={style}
                    onClick={() => methods.addItem(item)}
                  >
                    <img
                      className={styles.option__image}
                      src={item.image}
                      alt=""
                    />
                    {item.label}
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    );
  };

  return (
    <AppSelect<AppSelectWithIconItem>
      className={styles.appSelectWithSearchAndIcon}
      searchable={false}
      contentRenderer={contentRenderer}
      dropdownRenderer={dropdownRenderer}
      onDropdownClose={() => setSearch('')}
      {...props}
    />
  );
};

export default AppSelectWithSearchAndIcon;
