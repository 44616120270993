import React from 'react';
import styles from './ParticipantForm.module.scss';
import AppInput from '../AppInput';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import CheckboxRadio from '../FormField/CheckboxRadio';
import { requiredTextValidator } from '../../validators/requiredText.validator';
import { emailValidator } from '../../validators/email.validator';
import { getFormError } from '../../utils/get-form-error.utils';
import { IParticipant } from '../../store/participant-drivers/participant-drivers.types';

export interface IEventParticipantDriverForm
  extends Omit<IParticipant, 'participant_driver_id'> {}

interface IParitipantFormProps {
  /**
   * @default false
   */
  organiserView?: boolean;
}

const ParticipantForm: React.FC<IParitipantFormProps> = ({
  organiserView = false
}) => {
  const [t] = useTranslation();
  const {
    control,
    formState: { errors }
  } = useFormContext<IEventParticipantDriverForm>();

  const labelField = organiserView ? 'organiserLabel' : 'label';
  const placeholderField = organiserView
    ? 'organiserPlaceholder'
    : 'placeholder';

  return (
    <div className={styles.form}>
      <div className={styles.formField}>
        <h2 className={styles.formField__label}>
          {t(`eventParticipantDriver.fields.firstName.${labelField}`)}
        </h2>
        <Controller
          name="first_name"
          control={control}
          rules={{
            maxLength: {
              value: 64,
              message: t('field.validations.maxLength', { length: 64 })
            },
            ...requiredTextValidator({
              required: t('field.firstName.required'),
              emojis: t('field.validations.emojisNotAllowed')
            })
          }}
          render={({ value, onChange, onBlur }) => (
            <AppInput
              inputSize="big"
              placeholder={`eventParticipantDriver.fields.firstName.${placeholderField}`}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <div className={styles.formField__error}>
          {getFormError('firstName', errors)}
        </div>
      </div>
      <div className={styles.formField}>
        <h2 className={styles.formField__label}>
          {t(`eventParticipantDriver.fields.lastName.${labelField}`)}
        </h2>
        <Controller
          name="last_name"
          control={control}
          rules={{
            maxLength: {
              value: 64,
              message: t('field.validations.maxLength', { length: 64 })
            },
            ...requiredTextValidator({
              required: t('field.lastName.required'),
              emojis: t('field.validations.emojisNotAllowed')
            })
          }}
          render={({ value, onChange, onBlur }) => (
            <AppInput
              inputSize="big"
              placeholder={`eventParticipantDriver.fields.lastName.${placeholderField}`}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <div className={styles.formField__error}>
          {getFormError('lastName', errors)}
        </div>
      </div>
      <div className={styles.formField}>
        <h2 className={styles.formField__label}>
          {t(`eventParticipantDriver.fields.email.${labelField}`)}
        </h2>
        <Controller
          name="email"
          control={control}
          rules={{
            ...emailValidator({
              required: t('field.email.required'),
              minLength: t('field.validations.minLength', { length: 6 }),
              maxLength: t('field.validations.maxLength', { length: 64 }),
              invalid: t('field.email.invalidEmail')
            })
          }}
          render={({ value, onChange, onBlur }) => (
            <AppInput
              inputSize="big"
              placeholder={`eventParticipantDriver.fields.email.${placeholderField}`}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        />
        <div className={styles.formField__error}>
          {getFormError('email', errors)}
        </div>
      </div>
      {!organiserView ? (
        <div className={styles.formField}>
          <h2 className={styles.formField__label}>
            {t('eventParticipantDriver.fields.receiveEmail.label')}
          </h2>
          <Controller
            name="newsletter"
            control={control}
            render={({ value, onChange }) => (
              <div className={styles.formField__frame}>
                <CheckboxRadio
                  primary
                  value="receiveEmail"
                  name="receiveEmail"
                  i18nTrans={
                    <Trans i18nKey="field.getUpdates.label">
                      I would like to subscribe to updates from Racing Unleashed
                      on upcoming events and promotions. I confirm that I have
                      reviewed and agree to the
                      <a
                        className={styles.form__link}
                        href={t('field.termsOfUse.link')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                      <a
                        className={styles.form__link}
                        href={t('field.privacyPolicy.link')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </Trans>
                  }
                  checked={value}
                  onChange={({ target: { checked } }) => onChange(checked)}
                />
              </div>
            )}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ParticipantForm;
