import React from 'react';
import Lottie from 'react-lottie';
import loaderAnimation from './ru-loader.json';
import loaderAnimationNeon from './ru-loader-neon-blue.json';
import loaderAnimationWhite from './ru-loader-white.json';
import loaderAnimationMediumBlue from './ru-loader-medium-blue.json';
import loaderAnimationDarkBlue from './ru-loader-dark-blue.json';
import styles from './Loader.module.scss';

const animations = {
  black: loaderAnimation,
  white: loaderAnimationWhite,
  neon: loaderAnimationNeon,
  'dark-blue': loaderAnimationDarkBlue,
  'medium-blue': loaderAnimationMediumBlue
};

interface Loader {
  height?: number;
  width?: number;
  color?: 'black' | 'white' | 'neon' | 'dark-blue' | 'medium-blue';
  fullSize?: boolean;
}

const Loader = ({
  color = 'black',
  height = 200,
  width = 200,
  fullSize = false
}: Loader) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animations[color],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const loader = (
    <Lottie options={defaultOptions} height={height} width={width} />
  );

  if (fullSize) return <div className={styles.loader}>{loader}</div>;

  return loader;
};

export default Loader;
