import api from '../../config/api.config';
import {
  ISubscriptionConfirmation
} from './subscription-confirmation.types';

export const updateSubscriptionConfirmation = async (
  token: string,
  controller?: AbortController
): Promise<ISubscriptionConfirmation> => {
  try {
    const response = await api.post(
      '/registrations/confirm_subscription',
      {
        token
      },
      {
        signal: controller && controller.signal
      }
    );

    return response.data.meta;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
}
