import React from 'react';
import styles from './ThankYouPage.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import { ReactComponent as ArrowIcon } from '../../../../images/arrow-current-color.svg';

const ThankYouPage: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className={styles.page}>
      <div className={styles.page__content}>
        <h3 className={styles.page__title}>
          {t('booking.steps.thankYou.title')}
        </h3>
        <p className={styles.page__text}>{t('booking.steps.thankYou.text')}</p>
        <Button
          className={styles.page__button}
          label={t('booking.steps.thankYou.button')}
          icon={<ArrowIcon />}
          onClick={() =>
            window.open(process.env.REACT_APP_WEBSITE_URL, '_self')
          }
        />
      </div>
    </div>
  );
};

export default ThankYouPage;
