import React from 'react';
import Select from '../../components/FormField/Select';
import {
  trackSelector,
  monthSelector,
  carSelector
} from '../../store/track/track.selectors';
import {
  TrackSelector,
  MonthSelector,
  CarSelector
} from '../../store/track/track.types';
import useWithSelection from '../../hooks/useWithSelection';
import { SetStateType } from '../../types/utils.types';

interface RankingSelect {
  selected: string;
  type: string;
  setValue: SetStateType<string>;
}

interface SelectType {
  [key: string]: {
    key: string;
    selector: TrackSelector | MonthSelector | CarSelector;
  };
}

const selectType: SelectType = {
  track: {
    key: 'field.trackSelector',
    selector: trackSelector
  },
  month: {
    key: 'field.monthSelector',
    selector: monthSelector
  },
  car: {
    key: 'field.carSelector',
    selector: carSelector
  }
};

const RankingSelect = React.memo(
  ({ selected, type, setValue }: RankingSelect) => {
    const rankingSelects = useWithSelection(selectType[type].selector);
    const setFieldValue = (name: string, value: string) => {
      setValue(value);
    };

    return (
      <div className="RankingSelect">
        <Select
          i18nKey={selectType[type].key}
          name={`ranking-select-${type}`}
          value={selected}
          list={rankingSelects}
          setFieldValue={setFieldValue}
          disabled={!rankingSelects.length}
        />
      </div>
    );
  }
);

export default RankingSelect;
