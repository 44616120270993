import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toastUtil } from '../../utils/toast.utils';
import { format, addMinutes, endOfMonth, startOfMonth } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';
import { BookingContext } from '../../providers/booking.provider';
import { TimeSlot, AvailableDate } from '../../store/booking/booking.types';
import useWithSelection from '../../hooks/useWithSelection';
import useWithDispatch from '../../hooks/useWithDispatch';
import {
  customerSelector,
  paymentInfoSelector
} from '../../store/customer/customer.selectors';
import {
  createReservation,
  getAvailableDates,
  fetchSummary,
  getTimeSlots
} from '../../store/booking/booking.actions';
import { getPersonalQuotas } from '../../store/customer/customer.actions';
import { SummaryType } from '../../store/booking/booking.types';
import { loungeSelector } from '../../store/lounge/lounge.selectors';
import { Lounge } from '../../store/lounge/lounge.types';
import DialogSuccess from '../DialogSuccess';
import PaymentDetails from '../PaymentDetails';
import CardForm from '../CardForm';
import Summary from '../Summary';
import Calendar from '../Calendar';
import LoungeSelect from './LoungeSelect';
import TimeSlots from '../TimeSlots/TimeSlots';
import Simulators from './Simulators';
import RidesSharing from '../RidesSharing/RidesSharing';
import BookingTime from './BookingTime';
import SummaryDiscounts from './SummaryDiscounts';
import CouponForm from './CouponForm';

import './BookingForm.scss';
import { publish } from '../../events';

const langs: any = {
  de,
  en,
  es
};

const adjustedDate = (date: string): Date => {
  const time = date.split('T')[1].split('+')[0].split(':');
  return new Date(new Date(date).setHours(parseInt(time[0])));
};

const dateToTime = (date: string | Date): string => {
  let dateObj = new Date(date);
  if (typeof date == 'string') {
    dateObj = adjustedDate(date);
  }

  return format(dateObj, 'HH:mm');
};

const BookingForm = () => {
  const personalQuotasWithDispatch = useWithDispatch(getPersonalQuotas);
  const { closeBookingForm, selectedTimeSlot, groupRide } =
    useContext(BookingContext);
  const isGroupRide = useRef(groupRide);
  const [t, i18n] = useTranslation();
  const lounges = useWithSelection(loungeSelector);
  const { default_lounge_id } = useWithSelection(customerSelector);
  const { membership_plan } = useWithSelection(customerSelector);
  const paymentInfo = useWithSelection(paymentInfoSelector);
  const [lounge, setLounge] = useState<string>(default_lounge_id);
  const [timeSlot, setTimeSlot] = useState<TimeSlot | null>(
    selectedTimeSlot ? selectedTimeSlot : null
  );
  const [sims, setSims] = useState<number>(groupRide ? 2 : 1);
  const [ridesSharing, setRidesSharing] = useState<boolean>(false);
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [time, setTime] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>(
    undefined
  );
  const [availableDates, setAvailableDates] = useState<AvailableDate[] | null>(
    null
  );
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [loadingData, setloadingData] = useState<boolean>(false);
  const [summary, setSummary] = useState<SummaryType | null>(null);
  const [step, setStep] = useState<number>(0);
  const [coupon, setCoupon] = useState<string | null>(null);
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [currency, setCurrency] = useState<string>('');
  const [vat, setVat] = useState<string>('');
  const [timeSlotError, setTimeSlotError] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await getTimeSlots(isGroupRide.current, lounge);
        setTimeSlots(response);
        if (response.length) {
          setCurrency(response[0].currency);
          setVat(response[0].vat);
        }
      } catch (err) {}
    })();
    // eslint-disable-next-line
  }, [lounge]);

  useEffect(() => {
    if (!sims || !time || !date || !lounge || !timeSlot) {
      setSummary(null);
      return;
    }

    (async (sims, lounge, timeSlot, time, date, coupon, ridesSharing) => {
      try {
        setSummary(null);
        setloadingData(true);
        setHasError(false);
        const res = await fetchSummary({
          start_time: `${format(new Date(date!), 'yyyy-MM-dd')}T${
            time.split('T')[1]
          }`,
          time_slot_id: timeSlot.id,
          simulators: sims,
          lounge_id: lounge,
          coupon_code: coupon,
          rides_sharing: ridesSharing
        });
        setSummary(res.data);
        setloadingData(false);
      } catch (err) {
        setSummary(null);
        setloadingData(false);
        const msg = err && err.meta ? err.meta.message : err.message;
        toastUtil('error', msg);
        setHasError(true);
      }
    })(sims, lounge, timeSlot, time, date, coupon, ridesSharing);
  }, [sims, lounge, timeSlot, time, date, coupon, ridesSharing]);

  useEffect(() => {
    if (!timeSlot || !time) return;

    (async (sims, lounge, timeSlot, time) => {
      try {
        const dateFrom = startOfMonth(
          selectedMonth ? selectedMonth : new Date()
        );
        const dateTo = endOfMonth(dateFrom);

        const dates = await getAvailableDates(
          sims,
          timeSlot.id,
          lounge,
          format(dateFrom, 'yyyy-MM-dd'),
          format(dateTo, 'yyyy-MM-dd'),
          time
        );
        setAvailableDates(dates);
      } catch (err) {
        const msg = err && err.meta ? err.meta.message : err.message;
        toastUtil('error', msg);
      }
    })(sims, lounge, timeSlot, time);
  }, [sims, lounge, timeSlot, selectedMonth, time]);

  //paynow button validator
  const nextStep = () => {
    if (!timeSlot || !time) {
      if (!timeSlot) setTimeSlotError(true);
      if (!time) setTimeError(true);
      return;
    }

    if (lounges.find((b: Lounge) => b.id === lounge).accepts_cash_only) {
      book({
        card_token: '',
        save_card: false
      });
    } else {
      paymentInfo && !paymentInfo.card_number_fragment
        ? setStep(2)
        : setStep(1);
    }
  };

  useEffect(() => {
    if (timeSlot) setTimeSlotError(false);
    if (time) setTimeError(false);
  }, [timeSlot, time]);

  const showCardForm = () => {
    setStep(2);
  };

  const book = async ({
    card_token,
    save_card
  }: {
    card_token: string;
    save_card: boolean;
  }) => {
    try {
      setSubmitting(true);
      const response = await createReservation(
        {
          start_time: `${format(new Date(date!), 'yyyy-MM-dd')}T${
            time.split('T')[1]
          }`,
          time_slot_id: timeSlot ? timeSlot.id : null,
          simulators: sims,
          lounge_id: lounge,
          coupon_code: coupon,
          rides_sharing: ridesSharing,
          card_token,
          save_card
        },
        true
      );
      setSuccessMsg(response.meta.message);
      setStep(3);
      await personalQuotasWithDispatch();
      if (timeSlot && response.data.invoice) {
        publish('Purchase', {
          content_category: 'Time Slot',
          content_ids: [timeSlot.id],
          contents: [
            {
              id: timeSlot.id,
              quantity: response.data.number_of_simulators,
              name: timeSlot.description
            }
          ],
          num_items: 1,
          currency: response.data.invoice.currency,
          value: response.data.invoice.total
        });
      }
    } catch (err) {
      const msg = err && err.meta ? err.meta.message : err.message;
      toastUtil('error', msg);
      setSubmitting(false);
      setStep(0);
    }
  };

  let price = 0;
  if (!summary && timeSlot) price = sims * +timeSlot.price;
  if (summary) price = summary.final_price_sum;

  let buttonLabel = '';
  if (summary == null) {
    if (time && !hasError) {
      buttonLabel = t('booking.loadingPrice');
    } else if (!time) {
      buttonLabel = t('booking.invalidTime');
    } else {
      buttonLabel = t('booking.fullyBooked');
    }
  } else {
    buttonLabel =
      summary.final_price_sum === 0
        ? t('booking.pageBtnLabel')
        : t('booking.payNow');
  }

  return (
    <div className={`BookingForm ${step !== 0 ? 'on-success' : ''}`}>
      {step === 0 && (
        <>
          <div>
            <h2>
              {t(
                isGroupRide.current
                  ? 'booking.groupRideFormTitle'
                  : 'booking.singleRideFormTitle'
              )}
            </h2>
            <LoungeSelect selected={lounge} setCenter={setLounge} />
            <TimeSlots
              timeSlots={timeSlots}
              selected={timeSlot}
              selectSlot={setTimeSlot}
              showWarning={timeSlotError}
            />
            {groupRide && (
              <Simulators
                sims={sims}
                setSims={setSims}
                available={
                  lounges.find((b: Lounge) => b.id === lounge).max_group_size
                }
              />
            )}
            <BookingTime
              lounge={lounge}
              time={time}
              setTime={setTime}
              showWarning={timeError}
              date={date}
              loadingData={loadingData}
            />
            <Calendar
              selectDay={setDate}
              selectedDay={date}
              onMonthChange={setSelectedMonth}
              availableDates={availableDates}
            />
          </div>
          <Summary
            onClick={summary && summary.final_price_sum === 0 ? book : nextStep}
            buttonLabel={buttonLabel}
            currency={currency}
            totalPrice={price}
            hasSummary={summary ? true : false}
            vat={vat}
            isLoading={submitting}
          >
            {timeSlot && (
              <div>
                <span>{t('booking.booking')}</span>
                <span>{timeSlot.description}</span>
              </div>
            )}
            {lounge && lounges && (
              <div>
                <span>{t('booking.lounge')}</span>
                <span>
                  {lounges.find((base: any) => base.id === lounge).name}
                </span>
              </div>
            )}
            {time && timeSlot && (
              <div>
                <span>{t('booking.time')}</span>
                <span>
                  {dateToTime(time)} -{' '}
                  {dateToTime(
                    addMinutes(adjustedDate(time), timeSlot.duration_in_minutes)
                  )}
                </span>
              </div>
            )}
            {timeSlot && (
              <div>
                <span>{t('booking.duration')}</span>
                <span>{`${timeSlot.duration_in_minutes} min`}</span>
              </div>
            )}
            {groupRide && (
              <div>
                <span>{t('booking.simulators')}</span>
                <span>x{sims}</span>
              </div>
            )}
            {groupRide && membership_plan.shareable && (
              <div>
                <span>{t('booking.ridesSharing')}</span>
                <span>
                  {' '}
                  <RidesSharing
                    ridesSharing={ridesSharing}
                    setRidesSharing={setRidesSharing}
                    groupRide={groupRide}
                  />
                </span>
              </div>
            )}
            {date && (
              <div>
                <span>{t('booking.date')}</span>
                <span>
                  {date &&
                    format(date, 'EEEE, do MMMM', {
                      locale: langs[i18n.language]
                    })}
                </span>
              </div>
            )}
            <CouponForm applyCoupon={setCoupon} coupon={coupon} />
            {summary && <SummaryDiscounts summary={summary} />}
          </Summary>
        </>
      )}
      {step === 1 && (
        <PaymentDetails
          showCardForm={showCardForm}
          onPay={book}
          isSubmitting={submitting}
        />
      )}
      {step === 2 && <CardForm newDetails onSubmitCallback={book} />}
      {step === 3 && (
        <DialogSuccess
          onClick={closeBookingForm}
          title={t('booking.bookingSuccessTitle')}
          body={successMsg!}
          label={t('booking.close')}
        />
      )}
    </div>
  );
};

export default BookingForm;
