import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import CustomerMenu from '../CustomerMenu';

import Lang from './Lang';
import links from './links';
import './Header.scss';
import classNames from 'classnames';
import { setUserFromLocaStorage } from '../../store/customer/customer.actions';
import useWithDispatch from '../../hooks/useWithDispatch';
import { customerSelector } from '../../store/customer/customer.selectors';
import useWithSelection from '../../hooks/useWithSelection';

interface Props {
  data: any;
  /**
   * @default false
   */
  hideNavigation?: boolean;
  /**
   * @default false
   */
  hideTop?: boolean;
  /**
   * For redirection to an external page
   */
  logoLink?: string;
}

const Header = ({ logoLink, data, hideNavigation, hideTop }: Props) => {
  const [t, i18next] = useTranslation();
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [activeNav, setActiveNav] = useState<boolean>(false);
  const setUser = useWithDispatch(setUserFromLocaStorage);
  const customer = useWithSelection(customerSelector);

  const closeMobileNav = (): void => {
    setActiveNav(false);
    document.querySelector('body')!.classList.remove('js-active');
  };
  const handleScroll = (): void => {
    const scrollPos = window.pageYOffset;
    const startPos = window.innerWidth > 767 ? 40 : 0;
    scrollPos <= startPos ? setIsFixed(false) : setIsFixed(true);
  };
  const handleResize = (): void => {
    if (window.innerWidth > 767) {
      closeMobileNav();
    }
  };

  const handleMobileNavClick = (): void => {
    setActiveNav(!activeNav);
    document.querySelector('body')!.classList.toggle('js-active');
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    const channel = window.BroadcastChannel
      ? new BroadcastChannel('onAuth')
      : null;

    channel && channel.addEventListener('message', () => setUser());

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      channel && channel.close();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <header
      className={classNames('Header', { Header_withoutNavbar: hideNavigation })}
    >
      {hideTop ? (
        ''
      ) : (
        <MediaQuery query={'(min-width: 768px)'}>
          <div className="Header-top">
            <div className="container">
              <ul>
                {data[i18next.language] && (
                  <>
                    {data[i18next.language].headerServiceNavigation
                      .filter((navItem: any) => !navItem.text.includes('Login'))
                      .map((navItem: any) => (
                        <li key={navItem.text}>
                          <a
                            href={navItem.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {navItem.text}
                          </a>
                        </li>
                      ))}
                  </>
                )}
              </ul>
              <CustomerMenu onNavClick={closeMobileNav} />
              <Lang isAuthorized={!!customer} />
            </div>
          </div>
        </MediaQuery>
      )}
      <div className={`Header-bottom ${isFixed ? 'is-fixed' : ''}`}>
        <div className="Header-bottom-content">
          <div className="container">
            {logoLink ? (
              <a
                className="Header-brand"
                href={logoLink}
                target="_self"
                rel="noopener noreferrer"
              >
                Racing Unleashed
              </a>
            ) : (
              <Link className="Header-brand" to="/">
                Racing Unleashed
              </Link>
            )}
            {customer && !hideTop ? (
              <>
                <div
                  className={`Header-hamburger ${activeNav ? 'is-active' : ''}`}
                  onClick={handleMobileNavClick}
                />
                <MediaQuery query={'(min-width: 768px)'}>
                  <div
                    className={`Header-nav ${activeNav ? 'is-visible' : ''}`}
                  >
                    <ul>
                      {data[i18next.language] &&
                        data[i18next.language].headerMainNavigation.map(
                          (link: any) => (
                            <li key={link.text}>
                              <a
                                href={link.href}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {link.text}
                              </a>
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                </MediaQuery>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        {hideNavigation ? (
          ''
        ) : (
          <div className="Header-app-nav-container">
            <div className="container">
              <nav className="Header-app-nav">
                {links.bottom.map((link) => (
                  <NavLink
                    key={link.i18nKey}
                    to={link.to}
                    onClick={closeMobileNav}
                    activeClassName="is-active"
                    exact
                  >
                    {t(link.i18nKey)}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        )}
        <MediaQuery query={'(max-width: 767px)'}>
          <div className={`Header-nav ${activeNav ? 'is-visible' : ''}`}>
            {hideNavigation ? (
              ''
            ) : (
              <>
                <ul className="main-nav">
                  {data[i18next.language] &&
                    data[i18next.language].headerMainNavigation.map(
                      (link: any) => (
                        <li key={link.text}>
                          <a
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.text}
                          </a>
                        </li>
                      )
                    )}
                </ul>
                <ul className="secondary-nav">
                  {data[i18next.language] && (
                    <>
                      {data[i18next.language].headerServiceNavigation
                        .filter(
                          (navItem: any) => !navItem.text.includes('Login')
                        )
                        .map((navItem: any) => (
                          <li key={navItem.text}>
                            <a
                              href={navItem.href}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {navItem.text}
                            </a>
                          </li>
                        ))}
                    </>
                  )}
                </ul>
              </>
            )}
            <CustomerMenu onNavClick={closeMobileNav} />
            <Lang isAuthorized={!!customer} />
          </div>
        </MediaQuery>
      </div>
    </header>
  );
};

export default Header;
