export enum EEventProductType {
  event = 'event',
  eventCatering = 'event_catering',
  eventStructure = 'event_structure',
  eventTrophies = 'event_trophies',
  eventCarBranding = 'event_car_branding',
  eventTrackBranding = 'event_track_branding'
}

export interface EventProduct {
  id: string;
  price: string;
  currency: string;
  vat: string;
  label: string;
  description: string;
  available: string;
  product_id: string;
  product_type: EEventProductType;
  product_image_url: string | null;
  event_duration_multiplier: number;
}

export type EventProducts = Record<EEventProductType, EventProduct[]>;
