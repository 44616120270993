import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicField } from './FormField';

const TextInput = ({
  value,
  onChange = () => {},
  i18nKey,
  errors = {},
  touched = {},
  name,
  type,
  required = false,
  onBlur = () => {},
  disabled
}: BasicField) => {
  const [t] = useTranslation();
  const errorTrans = t(`${i18nKey}.${errors[name]}`);
  const errorMsg =
    errorTrans === `${i18nKey}.${errors[name]}` ? errors[name] : errorTrans;

  return (
    <div className={`FormField ${value ? 'has-value' : ''} ${
      disabled ? 'is-disabled' : ''
    } ${name}`}>
      <div className="FormField-input">
        {i18nKey && <label>{t(`${i18nKey}.label`)}</label>}
        <input
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          type={type}
          disabled={disabled}
          required={required}
        />
        <span className="FormField-focusIndicator" />
      </div>
      {touched[name] && errors[name] && (
        <p className="FormField-error">{errorMsg}</p>
      )}
    </div>
  );
};

export default TextInput;
