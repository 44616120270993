import { LoungeState, LoungeActionTypes, GET_LOUNGE } from './lounge.types';

const initialState: LoungeState = {
  lounge: []
};

export default (state = initialState, action: LoungeActionTypes) => {
  switch (action.type) {
    case GET_LOUNGE:
      return {
        ...state,
        lounge: [...action.payload]
      };

    default:
      return state;
  }
};
