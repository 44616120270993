const auth = {
  sideInfo: {
    title: 'Als Mitglied kannst Du:',
    p1:
      'Deine Leistungen in der Rangliste mit anderen Mitgliedern vergleichen.',
    p2: 'Statistiken zu Deinen Experiences und deiner Fahrleistung sehen.',
    p3: 'Bei unserer monatlichen Championship mitmachen.',
    p4: 'Und vieles mehr!',
    p5: '',
    copyright: 'Racing Unleashed AG. Alle Rechte vorbehalten.'
  },
  links: {
    tos: 'AGB',
    privacyPolicy: 'Datenschutz',
    security: 'Sicherheit'
  },
  authAction: {
    signUp: 'Noch kein Mitglied?',
    signIn: 'Bist du schon Mitglied?',
    btnSignIn: 'Login',
    btnSignUp: 'Registrieren'
  },
  signUp: {
    title: 'Registrieren',
    titleSilentMember: 'Formular ausfüllen zur Aktivierung',
    subtitle:
      'Jetzt Mitglied werden und die Racing Unleashed Experience erleben.',
    confirmMembership: 'Bestätigen',
    confirmText: ' <1> {{customerEmail}} </1> email.'
  },
  signIn: {
    title: 'Login zum Members Portal',
    subtitle: 'Geben Sie Ihre Zugangsdaten ein.'
  },
  forgotPassword: {
    title: 'Passwort vergessen?',
    subtitle: 'Hier schnell und einfach zurücksetzen.',
    linkToPage: 'Passwort vergessen?'
  },
  resetPassword: {
    title: 'Neues Passwort eingeben',
    subtitle: 'Bitte wähle ein Passwort mit mindestens 8 Zeichen.'
  },
  backToSignIn: 'Zurück zum Login',
  unleashNow: 'Unleash now',
  welcomeText: 'Wilkommen zur ultimativen Experience'
};

export default auth;
