import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, getDay } from 'date-fns';
import useWithSelection from '../../../hooks/useWithSelection';
import {
  customerSelector,
  customerQuotasSelector
} from '../../../store/customer/customer.selectors';
import { notificationSelector } from '../../../store/notification/notification.selectors';
import AvatarImage from '../../../components/AvatarImage';
import {
  getUpcomingRides,
  getCustomerInfo
} from '../../../store/dashboard/dashboard.actions';
import deDates from '../../../i18n/de/dates';
import enDates from '../../../i18n/en/dates';
import esDates from '../../../i18n/sp/dates';

import './CustomerWidget.scss';

interface Days {
  [key: string]: String[];
}

const days: Days = {
  en: enDates.weekdaysShort,
  de: deDates.weekdaysShort,
  es: esDates.weekdaysShort
};

const unlimitedArray = ['unlimited', 'unbegrenzt', 'ilimitado'];

const CustomerWidget = () => {
  const [t, i18n] = useTranslation();
  const {
    first_name,
    last_name,
    registered_at,
    membership_plan
  } = useWithSelection(customerSelector);
  const quotas = useWithSelection(customerQuotasSelector);
  const notfications = useWithSelection(notificationSelector);
  const [upcomingRides, setUpcomingRides] = useState([]);
  const [info, setInfo] = useState<any>();

  useEffect(() => {
    (async () => {
      const customerData = await Promise.all([getUpcomingRides(), getCustomerInfo()]);
      setUpcomingRides(customerData[0].reverse().slice(0, 3));
      setInfo(customerData[1]);
    })();
    // eslint-disable-next-line
  }, [notfications]);

  return (
    <div className="dashboard-widget CustomerWidget">
      <div className="CustomerWidget-profile">
        <AvatarImage />
        <span className="CustomerWidget-profile-name">
          {first_name} {last_name}
        </span>
        {membership_plan && (
          <span className="CustomerWidget-profile-package">
            {membership_plan.name}
          </span>
        )}
        <div className="CustomerWidget-profile-date">
          <span>{t('dashboard.memberSince')}</span>
          {format(new Date(registered_at), 'dd/MM/yyyy')}
        </div>
      </div>
      <div className="CustomerWidget-rides">
        <div className="dashboard-widget-header">
          <h3>{t('dashboard.upcomingRides')}</h3>
          <Link to="/notifications">{t('dashboard.viewAll')}</Link>
        </div>
        <div className="CustomerWidget-row row-header">
          <div>{t('dashboard.dateTime')}</div>
          <div>{t('dashboard.experience')}</div>
          <div>{t('dashboard.lounge')}</div>
        </div>
        {upcomingRides.length > 0 &&
          upcomingRides.map((ride: any) => (
            <div className="CustomerWidget-row" key={ride.id}>
              <div>
                {days[i18n.language][getDay(new Date(ride.start_time))]}{' '}
                {format(new Date(ride.start_time), ', dd.MM. @ HH:mm')}
              </div>
              <div>{ride.time_slot.name}</div>
              <div>{ride.lounge.name}</div>
            </div>
          ))}
      </div>
      {info && (
        <div className="CustomerWidget-data">
          {quotas && quotas[0] && quotas[0].limit.customer_limit.show && (
            <div>
              {!unlimitedArray.includes(quotas[0].limit.customer_limit.total)
                ? `${quotas[0].limit.customer_limit.used}
                              /${quotas[0].limit.customer_limit.total}`
                : quotas[0].limit.customer_limit.total}
              <span>{quotas[0].limit.customer_limit.description}</span>
            </div>
          )}
          <div>
            {info.experiences}
            <span>
              {t(
                info.experiences > 1
                  ? 'dashboard.experiences'
                  : 'dashboard.experience'
              )}
            </span>
          </div>
          <div>
            {info.tracks}
            <span>{t('dashboard.tracks')}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerWidget;
