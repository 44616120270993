import { IAppStep } from '../../../components/AppStepper/AppStepper';
import DateStep from '../../../components/BookingSteps/DateStep';
import { IDateStepProps } from '../../../components/BookingSteps/DateStep/DateStep';
import DurationStep from '../../../components/BookingSteps/DurationStep';
import { IDurationStepProps } from '../../../components/BookingSteps/DurationStep/DurationStep';
import ExtraStep from '../../../components/BookingSteps/ExtraStep';
import { IExtraStepProps } from '../../../components/BookingSteps/ExtraStep/ExtraStep';
import FoodStep from '../../../components/BookingSteps/FoodStep';
import { IFoodStepProps } from '../../../components/BookingSteps/FoodStep/FoodStep';
import LoungeStep from '../../../components/BookingSteps/LoungeStep';
import { ILoungeStepProps } from '../../../components/BookingSteps/LoungeStep/LoungeStep';
import OrganiserStep from '../../../components/BookingSteps/OrganiserStep';
import { IOrganiserStepProps } from '../../../components/BookingSteps/OrganiserStep/OrganiserStep';
import ParticipantsStep from '../../../components/BookingSteps/ParticipantsStep';
import { IParticipantsStepProps } from '../../../components/BookingSteps/ParticipantsStep/ParticipantsStep';
import ProgramStep from '../../../components/BookingSteps/ProgramStep';
import { IProgramStepProps } from '../../../components/BookingSteps/ProgramStep/ProgramStep';
import { EEventProductType } from '../../../store/eventProducts/eventProducts.types';
import { IEventBookForm } from '../EventBooking';

enum EStep {
  participants = 'participants',
  lounge = 'lounge',
  duration = 'duration',
  date = 'date',
  food = 'food',
  program = 'program',
  extra = 'extra',
  organiser = 'organiser'
}

export const STEPS: IAppStep[] = [
  { label: 'booking.participants', key: EStep.participants },
  { label: 'booking.lounge', key: EStep.lounge },
  { label: 'booking.duration', key: EStep.duration },
  { label: 'booking.date', key: EStep.date },
  { label: 'booking.food', key: EStep.food },
  { label: 'booking.program', key: EStep.program },
  { label: 'booking.extra', key: EStep.extra },
  { label: 'booking.organiser', key: EStep.organiser }
];

export const PARTICIPANTS_REQUIRED_FIELDS: (keyof IEventBookForm)[] = [
  'participantsAmount'
];

export const DURATION_REQUIRED_FIELDS: (keyof IEventBookForm)[] = [
  'durationId'
];

export const DATE_REQUIRED_FIELDS: (keyof IEventBookForm)[] = ['date', 'time'];

export const FOOD_REQUIRED_FIELDS: (keyof IEventBookForm)[] = [
  'foodId',
  'specialRequest'
];

export const PROGRAM_REQUIRED_FIELDS: (keyof IEventBookForm)[] = ['programId'];

export const EXTRA_REQUIRED_FIELDS: (keyof IEventBookForm)[] = [
  'firstLine',
  'secondLine',
  'carBranding',
  'trackBranding'
];

export const ORGANISER_REQUIRED_FIELDS: (keyof IEventBookForm)[] = [
  'organiserFirstName',
  'organiserLastName',
  'organiserEmail',
  'companyName',
  'companyAddress1',
  'companyCity',
  'companyCountryId',
  'processignPersonalData',
  'processingBillingData'
];

export type TEventLoading = {
  darftEvent: boolean;
  saving: boolean;
  summary: boolean;
  products: boolean;
  timeSlots: boolean;
};

export const STEP_COMPONENTS: Array<{
  key: EStep;
  component:
    | React.FC<IParticipantsStepProps>
    | React.FC<ILoungeStepProps>
    | React.FC<IDurationStepProps>
    | React.FC<IDateStepProps>
    | React.FC<IFoodStepProps>
    | React.FC<IProgramStepProps>
    | React.FC<IExtraStepProps>
    | React.FC<IOrganiserStepProps>;
  eventOption?: EEventProductType;
  requiredFields?: (keyof IEventBookForm)[];
  trophies?: EEventProductType;
  carBranding?: EEventProductType;
  trackBranding?: EEventProductType;
  loadingFields?: (keyof TEventLoading)[];
}> = [
  {
    key: EStep.participants,
    component: ParticipantsStep,
    requiredFields: PARTICIPANTS_REQUIRED_FIELDS
  },
  { key: EStep.lounge, component: LoungeStep },
  {
    key: EStep.duration,
    component: DurationStep,
    eventOption: EEventProductType.event,
    loadingFields: ['products', 'timeSlots'],
    requiredFields: DURATION_REQUIRED_FIELDS
  },
  {
    key: EStep.date,
    component: DateStep,
    requiredFields: DATE_REQUIRED_FIELDS
  },
  {
    key: EStep.food,
    component: FoodStep,
    eventOption: EEventProductType.eventCatering,
    requiredFields: FOOD_REQUIRED_FIELDS,
    loadingFields: ['products']
  },
  {
    key: EStep.program,
    component: ProgramStep,
    eventOption: EEventProductType.eventStructure,
    loadingFields: ['products'],
    requiredFields: PROGRAM_REQUIRED_FIELDS
  },
  {
    key: EStep.extra,
    component: ExtraStep,
    trophies: EEventProductType.eventTrophies,
    carBranding: EEventProductType.eventCarBranding,
    trackBranding: EEventProductType.eventTrackBranding,
    requiredFields: EXTRA_REQUIRED_FIELDS,
    loadingFields: ['products']
  },
  {
    key: EStep.organiser,
    component: OrganiserStep,
    requiredFields: ORGANISER_REQUIRED_FIELDS
  }
];
