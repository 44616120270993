import api from '../../config/api.config';
import i18n from '../../i18n';
import {
  EEventProductType,
  EventProduct,
  EventProducts
} from './eventProducts.types';

export const getEventProducts = async (
  participants_count: number,
  lounge_id: string,
  controller?: AbortController
): Promise<EventProducts> => {
  const languageParams = {
    headers: {
      'x-locale': i18n.language
    }
  };

  try {
    const response = await api.get('/event_products', {
      ...languageParams,
      params: {
        participants_count,
        lounge_id
      },
      signal: controller && controller.signal
    });

    const products: EventProducts = response.data.data.reduce(
      (acc: EventProducts, val: EventProduct) => {
        if (
          val.product_type !== EEventProductType.event ||
          val.event_duration_multiplier > 0
        )
          acc[val.product_type] = [...(acc[val.product_type] || []), val];

        return acc;
      },
      {} as EventProducts
    );

    return products;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};
