const ranking = {
  ranking: 'Ranking',
  rank: 'Rango',
  racer: 'Racers',
  lapTime: 'Hora',
  noItems: 'Ninguna entrada.',
  selectTrack: 'Eligir circuito.',
  viewAll: 'Ver todo'
};

export default ranking;
