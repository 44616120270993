import {
  CustomerState,
  CustomerActionTypes,
  SET_MEMBER,
  SIGN_OUT,
  SET_QUOTAS,
  UPDATE_PAYMENT_INFO
} from './customer.types';

let customer = null;
if (window.localStorage) {
  const savedState = JSON.parse(localStorage.getItem('ruState')!);
  customer = savedState ? savedState.customer : null;
}

const initialState: CustomerState = {
  customer,
  paymentInfo: null,
  quotas: null
};

export default (state = initialState, action: CustomerActionTypes) => {
  switch (action.type) {
    case SIGN_OUT:
      return {
        customer: null,
        paymentInfo: null,
        quotas: null
      };
    case SET_MEMBER:
      return {
        ...state,
        customer: { ...action.payload }
      };
    case SET_QUOTAS:
      return {
        ...state,
        quotas: action.payload
      };
    case UPDATE_PAYMENT_INFO:
      return {
        ...state,
        paymentInfo: {
          ...action.payload
        }
      };

    default:
      return state;
  }
};
