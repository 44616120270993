import React, { useMemo } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import styles from './OrganiserStep.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import AppFormField from '../../AppFormField/AppFormField';
import AppInput from '../../AppInput/AppInput';
import CheckboxRadio from '../../FormField/CheckboxRadio';
import AppPhoneNumberField from '../../AppPhoneNumberField';
import { Controller, useFormContext } from 'react-hook-form';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';
import { requiredTextValidator } from '../../../validators/requiredText.validator';
import { emailValidator } from '../../../validators/email.validator';
import { phoneNumberValidator } from '../../../validators/phone-number.validator';
import { getFormError } from '../../../utils/get-form-error.utils';
import { Country } from '../../../store/country/country.types';
import useWithSelection from '../../../hooks/useWithSelection';
import { countrySelector } from '../../../store/country/country.selectors';
import AppSelectWithSearchAndIcon, {
  AppSelectWithIconItem
} from '../../AppSelectWithSearchAndIcon/AppSelectWithSearchAndIcon';
import { Lounge } from '../../../store/lounge/lounge.types';
import { loungeSelector } from '../../../store/lounge/lounge.selectors';
import { ECustomerType } from '../../../views/EventBooking/untils/generate-event-form';
import { emojisValidator } from '../../../validators/emojis.validator';

export interface IOrganiserStepProps
  extends Pick<
    IBaseCardProps,
    'collapsed' | 'onForward' | 'onBack' | 'onClick' | 'revalidateStep'
  > {
  requiredFields: (keyof IEventBookForm)[];
}

const OrganiserStep: React.FC<IOrganiserStepProps> = ({
  requiredFields,
  onForward,
  revalidateStep,
  ...props
}) => {
  const [t] = useTranslation();
  const {
    control,
    getValues,
    watch,
    formState: { errors }
  } = useFormContext<IEventBookForm>();
  const countries: Country[] = useWithSelection(countrySelector);
  const lounges: Lounge[] = useWithSelection(loungeSelector);

  const customerType = watch('customerType');
  const isCompany = customerType === ECustomerType.company;

  const onCustomerTypeChange = (
    type: ECustomerType,
    onChange: (type: string) => void
  ) => {
    onChange(type);
    revalidateStep && revalidateStep();
  };

  const isValid = () =>
    !requiredFields.some((field) => errors[field] || !getValues(field));

  const countryOptions: AppSelectWithIconItem[] = useMemo(
    () =>
      countries.map(({ id, label, flag_picture_url }) => ({
        id,
        label,
        image: flag_picture_url
      })),
    [countries]
  );
  const loungeId = watch('loungeId');
  const countryId = watch('companyCountryId');
  const selectedCountry: AppSelectWithIconItem | null = useMemo(
    () =>
      (countryId &&
        countryOptions.length &&
        countryOptions.find(({ id }) => countryId === id)) ||
      null,
    [countryId, countryOptions]
  );
  const loungeCountryId: string = useMemo(() => {
    const lounge =
      (lounges && loungeId && lounges.find(({ id }) => id === loungeId)) ||
      null;

    if (!lounge || !lounge.lounge_owner || !lounge.lounge_owner.country)
      return '';

    return lounge.lounge_owner.country.id;
  }, [loungeId, lounges]);

  return (
    <BaseCard
      title="booking.steps.organiser.title"
      subtitle="booking.steps.organiser.subtitle"
      forwardTitle="booking.steps.organiser.forwardButton"
      {...props}
      onForward={isValid() ? () => onForward && onForward() : undefined}
    >
      <div className={styles.organiserStep}>
        <div className={styles.organiserStep__content}>
          <h4 className={styles.organiserStep__title}>
            {t('booking.steps.organiser.organiserDataTitle')}
          </h4>
          <Controller
            control={control}
            name="organiserFirstName"
            rules={{
              maxLength: {
                value: 64,
                message: t('field.validations.maxLength', { length: 64 })
              },
              ...requiredTextValidator({
                required: t('field.firstName.required'),
                emojis: t('field.validations.emojisNotAllowed')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label="field.firstName.label"
                error={getFormError('organiserFirstName', errors)}
              >
                <AppInput
                  placeholder="field.firstName.organiserPlaceholder"
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="organiserLastName"
            rules={{
              maxLength: {
                value: 64,
                message: t('field.validations.maxLength', { length: 64 })
              },
              ...requiredTextValidator({
                required: t('field.lastName.required'),
                emojis: t('field.validations.emojisNotAllowed')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label="field.lastName.label"
                error={getFormError('organiserLastName', errors)}
              >
                <AppInput
                  placeholder="field.lastName.organiserPlaceholder"
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="organiserEmail"
            rules={{
              ...emailValidator({
                required: t('field.organiserEmail.required'),
                minLength: t('field.validations.minLength', { length: 6 }),
                maxLength: t('field.validations.maxLength', { length: 64 }),
                invalid: t('field.organiserEmail.invalid')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label="field.organiserEmail.label"
                error={getFormError('organiserEmail', errors)}
              >
                <AppInput
                  placeholder="field.organiserEmail.placeholder"
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="organizerPhoneNumber"
            rules={{
              maxLength: {
                value: 64,
                message: t('field.validations.maxLength', { length: 64 })
              },
              ...phoneNumberValidator({
                maxLength: t('field.validations.maxLength', { length: 20 }),
                invalid: t('field.phone_number.invalid')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                label="field.phone_number.label"
                error={getFormError('organizerPhoneNumber', errors)}
              >
                <AppPhoneNumberField
                  value={value}
                  defaultCountryId={loungeCountryId}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="processignPersonalData"
            rules={{
              validate: (value) => {
                if (!value) {
                  return 'Required';
                }
              }
            }}
            render={({ value, onChange }) => (
              <CheckboxRadio
                primary
                required
                checked={value}
                name="processignPersonalData"
                value="processignPersonalData"
                i18nKey="field.processignPersonalData.label"
                onChange={() => {
                  onChange(!value);
                  revalidateStep && revalidateStep();
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="getUpdates"
            render={({ value, onChange }) => (
              <CheckboxRadio
                primary
                checked={value}
                name="getUpdates"
                value="getUpdates"
                i18nTrans={
                  <Trans i18nKey="field.getUpdates.label">
                    I would like to subscribe to updates from Racing Unleashed
                    on upcoming events and promotions. I confirm that I have
                    reviewed and agree to the
                    <a
                      className={styles.organiserStep__link}
                      href={t('field.termsOfUse.link')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                    <a
                      className={styles.organiserStep__link}
                      href={t('field.privacyPolicy.link')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    .
                  </Trans>
                }
                onChange={() => onChange(!value)}
              />
            )}
          />
        </div>
        <div className={styles.organiserStep__content}>
          <h4 className={styles.organiserStep__title}>
            {t('booking.steps.organiser.billingDataTitle')}
          </h4>
          <Controller
            control={control}
            name="customerType"
            render={({ value, onChange }) => (
              <div className={styles.radioGroup}>
                <label className={styles.radioGroup__label}>
                  <input
                    type="radio"
                    name="customerType"
                    value={ECustomerType.company}
                    checked={value === ECustomerType.company}
                    onChange={() =>
                      onCustomerTypeChange(ECustomerType.company, onChange)
                    }
                  />
                  {t('booking.steps.organiser.company')}
                </label>
                <label className={styles.radioGroup__label}>
                  <input
                    type="radio"
                    name="customerType"
                    value={ECustomerType.private}
                    checked={value === ECustomerType.private}
                    onChange={() =>
                      onCustomerTypeChange(ECustomerType.private, onChange)
                    }
                  />
                  {t('booking.steps.organiser.privateCustomer')}
                </label>
              </div>
            )}
          />
          <Controller
            control={control}
            name="companyName"
            rules={{
              maxLength: {
                value: 64,
                message: t('field.validations.maxLength', { length: 64 })
              },
              ...requiredTextValidator({
                required: t(
                  isCompany
                    ? 'field.company.required'
                    : 'field.name_and_surname.required'
                ),
                emojis: t('field.validations.emojisNotAllowed')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label={
                  isCompany
                    ? 'field.company.label'
                    : 'field.name_and_surname.label'
                }
                error={getFormError('companyName', errors)}
              >
                <AppInput
                  placeholder={
                    isCompany
                      ? 'field.company.placeholder'
                      : 'field.name_and_surname.placeholder'
                  }
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="companyAddress1"
            rules={{
              maxLength: {
                value: 64,
                message: t('field.validations.maxLength', { length: 64 })
              },
              ...requiredTextValidator({
                required: t(
                  isCompany
                    ? 'field.companyAddressLine.companyRequired'
                    : 'field.companyAddressLine.required'
                ),
                emojis: t('field.validations.emojisNotAllowed')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label={t(
                  isCompany
                    ? 'field.companyAddressLine.companyLabel'
                    : 'field.companyAddressLine.label',
                  { number: 1 }
                )}
                error={getFormError('companyAddress1', errors)}
              >
                <AppInput
                  placeholder={
                    isCompany
                      ? 'field.companyAddressLine.companyPlaceholder'
                      : 'field.companyAddressLine.placeholder'
                  }
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="companyAddress2"
            rules={{
              maxLength: {
                value: 64,
                message: t('field.validations.maxLength', { length: 64 })
              },
              ...emojisValidator({
                invalid: t('field.validations.emojisNotAllowed')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                label={t(
                  isCompany
                    ? 'field.companyAddressLine.companyLabel'
                    : 'field.companyAddressLine.label',
                  { number: 2 }
                )}
                error={getFormError('companyAddress2', errors)}
              >
                <AppInput
                  placeholder={
                    isCompany
                      ? 'field.companyAddressLine.companyPlaceholder'
                      : 'field.companyAddressLine.placeholder'
                  }
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="companyCity"
            rules={{
              maxLength: {
                value: 32,
                message: t('field.validations.maxLength', { length: 32 })
              },
              ...requiredTextValidator({
                required: t('field.city.required'),
                emojis: t('field.validations.emojisNotAllowed')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                required
                label="field.city.label"
                error={getFormError('companyCity', errors)}
              >
                <AppInput
                  placeholder="field.city.companyPlaceholder"
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="companyCountryId"
            rules={{
              required: t('field.country_id.required') as string
            }}
            render={({ onChange }) => (
              <AppFormField
                required
                label="field.country_id.label"
                error={getFormError('companyCountryId', errors)}
              >
                <AppSelectWithSearchAndIcon
                  placeholder={t('field.country_id.companyPlaceholder')}
                  values={selectedCountry ? [selectedCountry] : []}
                  options={countryOptions}
                  onChange={(values) => {
                    onChange((values[0] && values[0].id) || '');
                    revalidateStep && revalidateStep();
                  }}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="companyTax"
            rules={{
              maxLength: {
                value: 32,
                message: t('field.validations.maxLength', { length: 32 })
              },
              ...emojisValidator({
                invalid: t('field.validations.emojisNotAllowed')
              })
            }}
            render={({ value, onChange, onBlur }) => (
              <AppFormField
                label="field.taxNumber.label"
                error={getFormError('companyTax', errors)}
              >
                <AppInput
                  placeholder="field.taxNumber.placeholder"
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    revalidateStep && revalidateStep();
                  }}
                  onBlur={onBlur}
                />
              </AppFormField>
            )}
          />
          <Controller
            control={control}
            name="processingBillingData"
            rules={{
              validate: (value) => {
                if (!value) {
                  return 'Required';
                }
              }
            }}
            render={({ value, onChange }) => (
              <CheckboxRadio
                primary
                required
                checked={value}
                name="processingBillingData"
                value="processingBillingData"
                i18nKey="field.processingBillingData.label"
                onChange={() => {
                  onChange(!value);
                  revalidateStep && revalidateStep();
                }}
              />
            )}
          />
        </div>
      </div>
    </BaseCard>
  );
};

export default OrganiserStep;
