import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BookingWidget from '../../components/BookingWidget';
//@ts-ignore
import ItemsCarousel from 'react-items-carousel';
import { membershipsSelector } from '../../store/membership/membership.selectors';
import { MembershipPlan } from '../../store/membership/membership.types';
import useWithSelection from '../../hooks/useWithSelection';
import Subscription from './Subscription';
import SliderBtn from './SliderBtn';

import './Booking.scss';

const Booking = () => {
  const [t] = useTranslation();
  const plans = useWithSelection(membershipsSelector);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [numberOfCards, setNumberOfCards] = useState(getNumberOfCards());

  function getNumberOfCards() {
    let slides = 1;
    if (window.innerWidth >= 992) slides = 4;
    if (window.innerWidth > 575 && window.innerWidth < 992) slides = 2;

    return slides;
  }

  useEffect(() => {
    setActiveItemIndex(0);
  }, [numberOfCards]);

  useEffect(() => {
    const resizeHandler = () => {
      setNumberOfCards(getNumberOfCards);
    };

    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="Booking">
      <div className="row widget-wrapper">
        <div className="col-12">
          <h2>{t('booking.pageTitle')}</h2>
        </div>
        <div className="col-12 col-sm-6 col-lg-3">
          <BookingWidget />
        </div>
        <div className="col-12 col-sm-6 col-lg-3">
          <BookingWidget isGroupRide />
        </div>
      </div>
      {plans && plans.length > 0 && (
        <div className="widget-wrapper slider">
          <div>
            <h2>{t('booking.memberships')}</h2>
          </div>
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={numberOfCards}
            gutter={30}
            leftChevron={<SliderBtn back disabled={activeItemIndex === 0} />}
            rightChevron={
              <SliderBtn
                disabled={
                  plans.length < numberOfCards ||
                  activeItemIndex + 1 + numberOfCards >= plans.length
                }
              />
            }
            outsideChevron
            alwaysShowChevrons
            chevronWidth={64}
          >
            {plans.map((sub: MembershipPlan) => (
              <Subscription key={sub.id} plan={sub} />
            ))}
          </ItemsCarousel>
        </div>
      )}
    </div>
  );
};

export default Booking;
