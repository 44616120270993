import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { getChampionship } from '../../store/championships/championships.actions';
import './MobileChampionshipDetails.scss';

const MobileChampionshipDetails = () => {
  const { id, lang } = useParams();
  const [championship, setChampionship] = useState({
    title: '',
    featured_image_medium: '',
    description: ''
  });
  const _isMounted = useRef(true);

  const fetchChallanges = async () => {
    try {
      const response = await getChampionship(id, lang);
      if (_isMounted.current) setChampionship(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchChallanges();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="ru-championships ru-championship-mobile">
      {championship && (
        <div className="ru-championship">
          <h3>{championship.title!}</h3>
          <img src={championship.featured_image_medium} alt="" />
          <div className="ru-championship-content">
            <ReactMarkdown source={championship.description} escapeHtml={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileChampionshipDetails;
