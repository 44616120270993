import * as Yup from 'yup';

export default Yup.object().shape({
  password: Yup.string()
    .required('required')
    .min(8, 'minLength'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'matching')
    .required('required')
});
