import {
  GET_TRACKS,
  GET_MONTHS,
  GET_CARS,
  TrackActionTypes,
  TracksState
} from './track.types';

const initialState: TracksState = {
  tracks: [],
  months: [],
  cars: []
};

export default (state = initialState, action: TrackActionTypes) => {
  switch (action.type) {
    case GET_TRACKS:
      return {
        ...state,
        tracks: action.payload
      };
    case GET_MONTHS:
      return {
        ...state,
        months: action.payload
      };
    case GET_CARS:
      return {
        ...state,
        cars: action.payload
      };
    default:
      return state;
  }
};
