import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingContext } from '../../providers/booking.provider';
import { SetStateType } from '../../types/utils.types';

interface Simulators {
  available: number;
  sims: number;
  setSims: SetStateType<number>;
}

const Simulators = React.memo(({ available, sims, setSims }: Simulators) => {
  const [t] = useTranslation();
  const { groupRide } = useContext(BookingContext);
  const minSims = groupRide ? 2 : 1;

  const addSim = () => {
    if (sims < available) setSims(sims + 1);
  };

  const removeSim = () => {
    if (sims > minSims) setSims(sims - 1);
  };

  useEffect(() => {
    if (sims > available) setSims(minSims);
    // eslint-disable-next-line
  }, [sims, available]);

  return (
    <div
      className={`Simulators booking-content ${
        available === 0 ? 'is-disabled' : ''
      }`}
    >
      <div>
        <span onClick={removeSim} />
        <span>{sims}</span>
        <span onClick={addSim} />
      </div>
      {t('booking.simulators')}
    </div>
  );
});

export default Simulators;
