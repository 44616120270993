import { Dispatch } from 'react';
import api from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';
import { EventActionType, GET_EVENTS } from './events.types';

export const getEvents = async (
  dispatch: Dispatch<EventActionType>,
  lang?: string
): Promise<any> => {
  try {
    const response = await api.get('/events', {
      headers: {
        'x-locale': lang || 'en'
      }
    });
    const events = response.data.data;

    dispatch({
      type: GET_EVENTS,
      payload: events
    });

    return events;
  } catch (err) {
    if (err.data && err.data.meta) toastUtil('error', err.data.meta.message);
  }
};

export const getEvent = async (
  id?: number | string,
  lang?: string
): Promise<any> => {
  try {
    const event = await api.get(`/events/${id}`, {
      headers: {
        'x-locale': lang || 'en'
      }
    });
    return event.data.data;
  } catch (err) {
    if (err.data && err.data.meta) toastUtil('error', err.data.meta.message);
  }
};
