const events = {
  readMore: 'Read More',
  events: 'Events',
  back: 'Back',
  view: 'View',
  close: 'Close',
  starts: 'Starts',
  ends: 'Ends',
  lounge: 'Lounge'
};

export default events;
