import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import EditParticipantDialog, {
  IEditParticipantDialogProps,
  IEditParticipantDialogRef
} from './EditParticipantDialog';
import { IEventParticipantDriverForm } from '../../../../components/ParticipantForm/ParticipantForm';
import { IParticipant } from '../../../../store/participant-drivers/participant-drivers.types';

type TUseConfirmationDialogResult = {
  openDialog: (
    reservation_data: string,
    participant: IParticipant
  ) => Promise<IEventParticipantDriverForm | null>;
  dialog: React.ReactNode;
};

export function useEditParticipantDialog(): TUseConfirmationDialogResult {
  const dialogRef = useRef<IEditParticipantDialogRef | null>(null);
  const [props, setProps] = useState<IEditParticipantDialogProps>({
    participant: {} as IParticipant,
    reservation_data: '',
    onConfirm: () => closeDialog(),
    onCancel: () => closeDialog()
  });

  const closeDialog = () => dialogRef.current && dialogRef.current.close();

  const openDialog = useCallback(
    (reservation_data: string, participant: IParticipant) => {
      dialogRef.current && dialogRef.current.resetForm(participant);

      return new Promise<IEventParticipantDriverForm | null>((resolve) => {
        setProps(() => ({
          participant,
          reservation_data,
          onConfirm: (participant: IEventParticipantDriverForm) => {
            resolve(participant);
            closeDialog();
          },
          onCancel: () => {
            resolve(null);
            closeDialog();
          }
        }));

        dialogRef.current && dialogRef.current.open();
      });
    },
    []
  );

  return {
    openDialog,
    dialog: <EditParticipantDialog ref={dialogRef} {...props} />
  };
}
