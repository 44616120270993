import React from 'react';
import AuthLinks from '../../components/AuthLinks';
import AuthAction from '../../components/AuthAction';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => (
  <>
    <AuthAction isSignUp />
    <ForgotPasswordForm />
    <AuthLinks />
  </>
);

export default ForgotPassword;
