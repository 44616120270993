import { toast } from 'react-toastify';

export const toastUtil = (
  type: string = 'info',
  message: string,
  position: string = 'bottom-right',
  autoClose: number = 5000,
  hideProgressBar: boolean = true,
  closeOnClick: boolean = true,
  pauseOnHover: boolean = true,
  draggable: boolean = true,
  closeButton: boolean = false
) => {
  var toastId = slugify(message);
  // @ts-ignore
  toast[type](message, {
    position,
    autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    closeButton,
    toastId
  });
};

function slugify(str: String) {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}
