const auth = {
  sideInfo: {
    title: 'Como miembro puedes:',
    p1: 'Comparar tu rendimiento con el de los otros miembros del ranking.',
    p2:
      'Ver las estadísticas sobre tus experiencias y tu rendimiento al volante.',
    p3: 'Participar en nuestro desafío mensual.',
    p4: 'Y mucho más!',
    p5: '',
    copyright: 'Racing Unleashed AG. Todos los derechos reservados.'
  },
  links: {
    tos: 'CCG',
    privacyPolicy: 'Protección de datos',
    security: 'Seguridad'
  },
  authAction: {
    signUp: '¿Aún no eres miembro?',
    signIn: '¿Ya eres miembro?',
    btnSignIn: 'Login',
    btnSignUp: 'Registrar'
  },
  signUp: {
    title: 'Registrar',
    titleSilentMember: 'Complete el formulario para activar',
    subtitle: 'Únete ahora y experimenta la Racing Unleashed..',
    confirmMembership: 'Confirmar',
    confirmText: ' <1> {{customerEmail}} </1> email.'
  },
  signIn: {
    title: 'Login al portal de miembros',
    subtitle: 'Ingresa tus datos de acceso.'
  },
  forgotPassword: {
    title: 'Olvidado contraseña',
    subtitle: 'Aquí puedes restablecer tu contraseña rápida y fácilmente.',
    linkToPage: 'Olvidado contraseña'
  },
  resetPassword: {
    title: 'Ingresa tu nueva contraseña',
    subtitle: 'Por favor, elige una contraseña con al menos 8 caracteres.'
  },
  backToSignIn: 'Volver al Login',
  unleashNow: 'Unleash now',
  welcomeText: 'Te da la bienvenida a la mejor experiencia'
};

export default auth;
