import api from '../../config/api.config';
import {
  IParticipant,
  IOrganiserEvent,
  IParticipantEvent
} from './participant-drivers.types';

export const getOrganiserBookingData = async (
  reservation_id: string,
  controller?: AbortController
): Promise<IOrganiserEvent> => {
  try {
    const response = await api.post(
      '/participant_drivers/event_info_organiser',
      {
        reservation_id
      },
      {
        signal: controller && controller.signal
      }
    );

    return response.data.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const getParticipantBookingData = async (
  reservation_id: string,
  controller?: AbortController
): Promise<IParticipantEvent> => {
  try {
    const response = await api.get(
      `/participant_drivers/event_info_participant/${reservation_id}`,
      {
        signal: controller && controller.signal
      }
    );

    return response.data.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const addOrganiserParticipant = async (
  reservation_id: string,
  participant: Omit<IParticipant, 'participant_driver_id'>
): Promise<IParticipant> => {
  try {
    const response = await api.post('participant_drivers/add_by_organiser', {
      reservation_id,
      participant_driver: {
        customer: participant
      }
    });

    return response.data.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const addCustomerParticipant = async (
  reservation_id: string,
  participant: Omit<IParticipant, 'participant_driver_id'>
): Promise<IParticipant> => {
  try {
    const response = await api.post('/participant_drivers/add_by_customer', {
      reservation_id,
      ...participant
    });

    return response.data.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const updateOrganiserParticipant = async (
  reservation_id: string,
  participant: IParticipant
): Promise<IParticipant> => {
  const { participant_driver_id, ...rest } = participant;

  try {
    const response = await api.post('participant_drivers/update_by_organiser', {
      reservation_id,
      participant_driver: {
        customer: rest
      },
      id: participant_driver_id
    });

    return response.data.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const deleteOrganiserParticipant = async (
  reservation_id: string,
  participant_id: string
): Promise<IParticipant> => {
  try {
    const response = await api.post('participant_drivers/delete_by_organiser', {
      reservation_id,
      id: participant_id
    });

    return response.data.data;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};
