import React, { useEffect, useState } from 'react';
import styles from './SignIntoEvent.module.scss';
import SurveyWelcomeStep from '../../components/SurveyWelcomeStep/SurveyWelcomeStep';
import Button from '../../components/Button';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as ArrowIcon } from '../../images/arrow-current-color.svg';
import ParticipantForm, {
  IEventParticipantDriverForm
} from '../../components/ParticipantForm/ParticipantForm';
import ThankYouStep from './ThankYouStep/ThankYouStep';
import {
  addCustomerParticipant,
  getParticipantBookingData
} from '../../store/participant-drivers/participant-drivers.actions';
import { useParams } from 'react-router-dom';
import { IParticipantEvent } from '../../store/participant-drivers/participant-drivers.types';
import { toastUtil } from '../../utils/toast.utils';
import { format } from 'date-fns';
import Loader from '../../components/Loader';
import { dateLocales } from '../../constants/dateLocales';
import i18n from '../../i18n';

const PartcipantSurvey: React.FC = () => {
  const [t] = useTranslation();
  const { id } = useParams<{ id: string }>();
  const form = useForm<IEventParticipantDriverForm>({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      newsletter: false
    }
  });
  const [status, setStatus] = useState<'started' | 'completed' | null>(null);
  const [isLoading, setIsLoading] = useState<{
    eventData: boolean;
    sending: boolean;
  }>({ eventData: false, sending: false });
  const [eventData, setEventData] = useState<IParticipantEvent | null>(null);
  const {
    formState: { isValid },
    trigger,
    getValues
  } = form;

  useEffect(() => {
    if (!id) return;

    setTimeout(() =>
      setIsLoading((loading) => ({ ...loading, eventData: true }))
    );

    const controller = new AbortController();

    getParticipantBookingData(id, controller)
      .then(setEventData)
      .catch((err) => {
        if (err && err.message === 'canceled') return;

        const msg = err && err.meta ? err.meta.message : err && err.message;
        toastUtil('error', msg);
      })
      .finally(() =>
        setIsLoading((loading) => ({ ...loading, eventData: false }))
      );

    return () => controller.abort();
  }, [id]);

  const onSend = async () => {
    if (!(await trigger())) return;

    setIsLoading((loading) => ({ ...loading, sending: true }));

    try {
      await addCustomerParticipant(id, getValues());
      setStatus('completed');
    } catch (e) {
      const err = e as any;
      if (!err) return;

      let msg: string = '';

      if (err.meta && typeof err.meta.message === 'object') {
        const error = Object.entries(
          err.meta.message as { [key: string]: string }
        )[0];
        msg = `${error[0]} ${error[1]}` || '';
      } else {
        msg = err && err.meta ? err.meta.message : err.message;
      }

      if (msg) {
        toastUtil('error', msg);
      }
    }

    setIsLoading((loading) => ({ ...loading, sending: false }));
  };

  const loader = <Loader fullSize color="black" width={80} height={80} />;

  return (
    <div className={styles.eventParticipantDriver}>
      {eventData ? (
        <div className={styles.eventParticipantDriver__content}>
          <FormProvider {...form}>
            {!status ? (
              <SurveyWelcomeStep
                title={t('eventParticipantDriver.welcome.title')}
                description={[
                  <Trans i18nKey="eventParticipantDriver.welcome.details.firstLine">
                    You're going to take part in the event happening on
                    <b>
                      {{
                        dateTime: format(
                          new Date(eventData.start_time),
                          'MMMM dd, yyyy',
                          {
                            locale: dateLocales[i18n.language as string]
                          }
                        )
                      }}{' '}
                      in the{' '}
                      {{
                        location: eventData.lounge.name
                      }}{' '}
                      Racing Unleashed Lounge
                    </b>
                  </Trans>,
                  <Trans i18nKey="eventParticipantDriver.welcome.details.secondLine">
                    But first we'll need some information about you. Fill the
                    form to make your lounge experience seamless. It won't take
                    you longer than
                    <b>1 minute.</b>
                  </Trans>
                ]}
              />
            ) : (
              ''
            )}
            {status === 'started' ? <ParticipantForm /> : ''}
            {status === 'completed' ? <ThankYouStep /> : ''}
          </FormProvider>
          {!status ? (
            <Button
              primaryDark
              label={t('eventParticipantDriver.startSurvey')}
              onClick={() => {
                setStatus('started');
              }}
            />
          ) : (
            ''
          )}
          {status === 'started' ? (
            <Button
              primaryDark
              label={t('eventParticipantDriver.send')}
              disabled={!isValid}
              icon={<ArrowIcon />}
              onClick={onSend}
            />
          ) : (
            ''
          )}
          {isLoading.sending ? loader : ''}
        </div>
      ) : (
        ''
      )}
      {isLoading.eventData ? loader : ''}
    </div>
  );
};

export default PartcipantSurvey;
