import { withRouter } from 'react-router-dom';
import { customerSelector } from '../../store/customer/customer.selectors';
import useWithSelection from '../../hooks/useWithSelection';
import { useEffect } from 'react';
import { subscribe, unsubscribe } from '../../events';
import { postMembershipConvert } from '../../store/membership/membership.actions';

const PIXEL_ID_1 = 4997252593682784;
const PIXEL_ID_2 = 1535302823623494;

const Fb = () => {
    // Runs on every page load/change
    const customer = useWithSelection(customerSelector);

    let user_data = null;
    if (customer && customer.email) {
        user_data = {
            em: customer.email,
            external_id: customer.id,
            fn: customer.first_name,
            ln: customer.last_name
        }
    }

    const handleInitiateCheckout = (data: any) => {
        window.fbq('track', 'InitiateCheckout', data.detail);
    }
    const handlePurchase = (data: any) => {
        data.detail.event_id = new Date().getTime();
        data.detail.source_url = window.location.href;
        window.fbq('track', 'Purchase', data.detail, { eventID: data.detail.event_id });
        postMembershipConvert(data.detail);
    }
    const handleLogin = (data: any) => {
        window.fbq('init', PIXEL_ID_1, {});
        window.fbq('addPixelId', PIXEL_ID_2, {});
        window.fbq('init', PIXEL_ID_1, data.detail);
        window.fbq('addPixelId', PIXEL_ID_2, data.detail);
        window.fbq('trackCustom', 'SignIn');
    }
    const handleRegistration = (data: any) => {
        window.fbq('track', 'CompleteRegistration', data.detail);
    }

    useEffect(() => {
        subscribe("InitiateCheckout", handleInitiateCheckout);
        subscribe("Purchase", handlePurchase);
        subscribe("Login", handleLogin);
        subscribe("Registration", handleRegistration);

        return () => {
            unsubscribe("InitiateCheckout", handleInitiateCheckout);
            unsubscribe("Purchase", handlePurchase);
            unsubscribe("Login", handleLogin);
            unsubscribe("Registration", handleRegistration);
        }
    }, []);

    if (window.fbq && window.fbq.getState && window.fbq.getState().pixels.length === 0) {
        console.log("Customer is accessing site: ", user_data);
        window.fbq('init', PIXEL_ID_1, user_data);
        window.fbq('addPixelId', PIXEL_ID_2, user_data);
    }

    window.fbq('track', "PageView");
    return (null);
}

export default withRouter(Fb);