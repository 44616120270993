import React, { useCallback, useEffect, useState } from 'react';
import styles from './DraftEventWidget.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button from '../../../components/Button';
import { ReactComponent as ArrowIcon } from '../../../images/arrow-current-color.svg';
import { ReactComponent as TrashIcon } from '../../../images/trash.svg';
import { Link } from 'react-router-dom';
import { Booking } from '../../../store/booking/booking.types';
import { getDraftEvents } from '../../../store/dashboard/dashboard.actions';
import { format } from 'date-fns';
import useWithDispatch from '../../../hooks/useWithDispatch';
import { deleteReservation } from '../../../store/booking/booking.actions';
import Loader from '../../../components/Loader';
import { useConfirmationDialog } from '../../../components/ConfirmationDialog/useConfirmationDialog';
import { ReactComponent as CloseIcon } from '../../../images/cancel-current-color.svg';

const MAX_EVENTS_LENGTH = 3;

const DraftEventWidget: React.FC = () => {
  const [t] = useTranslation();
  const { openDialog, dialog } = useConfirmationDialog();
  const draftEventsDispatch = useWithDispatch(getDraftEvents);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [events, setEvents] = useState<Booking[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchDraftEvents();
    // eslint-disable-next-line
  }, []);

  const fetchDraftEvents = useCallback(
    () => {
      setIsLoading(true);
      draftEventsDispatch()
        .then(setEvents)
        .finally(() => setIsLoading(false));
    },
    // eslint-disable-next-line
    [setEvents]
  );

  const onDelete = async (id: string) => {
    openDialog({
      title: 'booking.deleteDraftEvent',
      text: 'booking.deleteDraftEventInfo',
      okText: 'booking.deleteDraftEventOk',
      okIcon: <CloseIcon />,
      type: 'delete'
    }).then(async (isConfirm) => {
      if (isConfirm) {
        setIsLoading(true);
        await deleteReservation(id);
        fetchDraftEvents();
      }
    });
  };

  const displayedDrafts = showAll ? events : events.slice(0, MAX_EVENTS_LENGTH);

  return (
    <div className={classNames('dashboard-widget', styles.draftEventWidget)}>
      <div
        className={classNames(
          'dashboard-widget-header',
          styles.draftEventWidget__title
        )}
      >
        <h3>{t('draftEvents.title')}</h3>
      </div>
      <div className={styles.draftEventWidget__grid}>
        {displayedDrafts.map(
          ({ id, lounge, start_time, event_information, time_slot }) => (
            <div key={id} className={styles.card}>
              <h4 className={styles.card__title}>
                {[
                  start_time && format(new Date(start_time), 'do MMMM'),
                  lounge && lounge.name
                ]
                  .filter(Boolean)
                  .join(', ')}
              </h4>
              <p className={styles.card__info}>{`${
                event_information && event_information.ParticipantsCount
              } ${t('booking.participants').toLowerCase()}, ${
                time_slot.name
              }`}</p>
              <div className={styles.card__buttons}>
                <Button
                  small
                  deleteClass
                  outline
                  label={t('draftEvents.delete')}
                  icon={<TrashIcon />}
                  onClick={() => onDelete(id)}
                />
                <Link
                  to={`/public/event-booking/${id}`}
                  className={styles.card__resume}
                >
                  <Button
                    small
                    outline
                    className={styles.card__resume}
                    label={t('draftEvents.resume')}
                    icon={<ArrowIcon />}
                  />
                </Link>
              </div>
            </div>
          )
        )}
      </div>
      {!showAll && events.length > MAX_EVENTS_LENGTH ? (
        <Button
          small
          outline
          className={styles.card__viewAll}
          label={t('draftEvents.viewAll')}
          onClick={() => setShowAll(true)}
        />
      ) : (
        ''
      )}
      {isLoading ? (
        <Loader fullSize color="dark-blue" width={80} height={80} />
      ) : (
        ''
      )}
      {dialog}
    </div>
  );
};

export default DraftEventWidget;
