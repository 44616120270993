import {
  TrackActionTypes,
  GET_TRACKS,
  GET_MONTHS,
  GET_CARS
} from './track.types';
import { Dispatch } from 'react';
import api from '../../config/api.config';

export const getTracks = async (
  dispatch: Dispatch<TrackActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`/rankings/tracks`);
    if (typeof res === "undefined" || !res) return;
    const tracksData = res.data.data;

    dispatch({
      type: GET_TRACKS,
      payload: tracksData
    });
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};

export const getMonths = async (
  dispatch: Dispatch<TrackActionTypes>,
  track: string
): Promise<any> => {
  try {
    const res = await api.get(`/rankings/tracks/${track}/months`);
    const months = res.data.data;

    dispatch({
      type: GET_MONTHS,
      payload: months
    });

    return months;
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};

export const getCars = async (
  dispatch: Dispatch<TrackActionTypes>,
  track: string
): Promise<any> => {
  try {
    const res = await api.get(`/rankings/tracks/${track}/cars`);
    const cars = res.data.data;

    dispatch({
      type: GET_CARS,
      payload: cars
    });
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};

export const getRankings = async (
  track: string | number,
  month: string,
  car: string | number
): Promise<any> => {
  try {
    const res = await api.get(
      `/rankings/${track}${(month || car) && '?'}${
        month ? `date=${month}` : ''
      }${month && car && '&'}${car ? `car_id=${car}` : ''}`
    );
    return res.data.data;
  } catch (err) {
    Promise.reject(err);
  }
};

export const getLapTime = (lapTime: number): string => {
  let minutes: number = Math.floor(lapTime / 60000);
  let seconds: any = Math.floor((lapTime % 60000) / 1000).toFixed(0);
  let miliseconds: number = lapTime % 1000;
  return `${minutes}:${seconds.toString().padStart(2, '0')}.${
    miliseconds.toString().padStart(3, '0')
  }`;
};
