import React, { useEffect, useState } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Header from '../../components/Header';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';
import {
  getCustomer,
  getPaymentInfo,
  getPersonalQuotas
} from '../../store/customer/customer.actions';
import { getMembershipPlans } from '../../store/membership/membership.actions';
import { getLounge } from '../../store/lounge/lounge.actions';
import { getGenderCodes } from '../../store/code/code.actions';
import { getCountries } from '../../store/country/country.actions';
import { getNotifications } from '../../store/notification/notification.actions';
import { getChampionshipsWithDispatch } from '../../store/championships/championships.actions';
import { getEvents } from '../../store/events/events.actions';
import { getTracks } from '../../store/track/track.actions';
import useWithDispatch from '../../hooks/useWithDispatch';
import BookingProvider from '../../providers/booking.provider';
import AuthLinks from '../../components/AuthLinks';
import Copy from '../../components/Copy';
import Profile from '../Profile';
import Billing from '../Billing';
import Booking from '../Booking';
import Notifications from '../Notifications';
import ChangePassword from '../ChangePassword';
import Ranking from '../Ranking';
import Dashboard from '../Dashboard';
import Events from '../Events';
import EventDetails from '../Events/EventDetails';
import Championships from '../Championships';
import Membership from '../Membership';

const Protected = () => {
  const customer = useWithSelection(customerSelector);
  const customerDispatch = useWithDispatch(getCustomer);
  const paymentInfoDispatch = useWithDispatch(getPaymentInfo);
  const personalQuotasWithDispatch = useWithDispatch(getPersonalQuotas);
  const membershipsDispatch = useWithDispatch(getMembershipPlans);
  const loungeDispatch = useWithDispatch(getLounge);
  const genderCodeDispatch = useWithDispatch(getGenderCodes);
  const countriesDispatch = useWithDispatch(getCountries);
  const membershipsWithDispatch = useWithDispatch(getMembershipPlans);
  const notificationsWithDispatch = useWithDispatch(getNotifications);
  const tracksDispatch = useWithDispatch(getTracks);
  const championshipsDispatch = useWithDispatch(getChampionshipsWithDispatch);
  const eventsDispatchs = useWithDispatch(getEvents);
  const [, i18n] = useTranslation();
  const [data, setData] = useState<any>({});
  const location = useLocation();

  useEffect(() => {
    membershipsWithDispatch();
    // eslint-disable-next-line
  }, [customer]);

  useEffect(() => {
    eventsDispatchs(i18n.language);
    championshipsDispatch(i18n.language);

    setTimeout(() => {
      personalQuotasWithDispatch();
    }, 300);

    (async () => {
      if (data[i18n.language]) return;
      try {
        const res = await axios(
          `https://www.racing-unleashed.com/header.json?lang=${i18n.language}`
        );
        setData({ ...data, [i18n.language]: res.data.data[0].attributes });
      } catch (e) {
        console.warn('fetching header data failed');
      }
    })();
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    customerDispatch();
    paymentInfoDispatch();
    membershipsDispatch();
    loungeDispatch();
    genderCodeDispatch();
    countriesDispatch();
    membershipsWithDispatch();
    notificationsWithDispatch();
    tracksDispatch();
    championshipsDispatch();
    personalQuotasWithDispatch();

    const notificationsInterval = setInterval(() => {
      notificationsWithDispatch();
    }, 30000);

    return () => {
      clearInterval(notificationsInterval);
    };
    // eslint-disable-next-line
  }, []);

  if (!customer) return <Redirect to="/auth" />;

  if (customer.profile_data_incomplete) {
    if (
      location.pathname !== '/profile' &&
      sessionStorage.getItem('isReloaded') == null
    ) {
      return (
        <Redirect
          to={{
            pathname: '/profile',
            state: { data_incomplete: true }
          }}
        />
      );
    } else {
      sessionStorage.setItem('isReloaded', 'true');
    }
  }

  return (
    <BookingProvider>
      <Header data={data} />
      <div className="container">
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/notifications" component={Notifications} />
          <Route exact path="/booking" component={Booking} />
          <Route exact path="/ranking" component={Ranking} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/events/:id" component={EventDetails} />
          <Route exact path="/championships" component={Championships} />
          <Route exact path="/membership" component={Membership} />
        </Switch>
      </div>
      <footer>
        <Copy />
        <AuthLinks />
      </footer>
    </BookingProvider>
  );
};

export default Protected;
