import { Tooltip, withStyles } from '@material-ui/core';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#000047',
    color: '#fff',
    boxShadow: 'undet',
    fontSize: 12,
    maxWidth: '500px'
  },
  arrow: {
    color: '#000047'
  }
}))(Tooltip);

export default StyledTooltip;
