import axios, { AxiosInstance } from 'axios';
import store from '../store';
import { signOut } from '../store/customer/customer.actions';
let stripeKey: any = process.env.REACT_APP_STRIPE_KEY;

export const API_ROOT = process.env.REACT_APP_API;
export { stripeKey };

const api: AxiosInstance = axios.create({
  baseURL: API_ROOT
});

api.interceptors.request.use(
  async (config) => {
    // set access_token
    // add access_token to the request headers
    const token = localStorage.getItem('ruToken');
    if (token && config.headers) config.headers['x-auth-token'] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      signOut(store.dispatch);
      return false;
    } else {
      return Promise.reject(error);
    }
  }
);

api.prototype.fixedEncodeURIComponent = function (str: string | null) {
  if (str === null) {
    return '';
  }
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase();
  });
};

export default api;
