import React, { useEffect, useMemo, useState } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import styles from './LoungeStep.module.scss';
import useWithSelection from '../../../hooks/useWithSelection';
import { loungeSelector } from '../../../store/lounge/lounge.selectors';
import { Lounge } from '../../../store/lounge/lounge.types';
import classNames from 'classnames';
import SearchBox from '../../SearchBox';
import { isSubstring } from '../../../utils/is-substring.utils';
import L from 'leaflet';
import { distanceTo } from 'geolocation-utils';
import RUPin from '../../../images/ru-pin.svg';
import { useFormContext } from 'react-hook-form';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';
import Loader from '../../Loader';

export interface ILoungeStepProps
  extends Pick<
    IBaseCardProps,
    'collapsed' | 'onForward' | 'onBack' | 'invalid'
  > {}

const marketIcon = L.icon({
  iconUrl: RUPin,
  iconSize: [32, 38], // size of the icon
  iconAnchor: [16, 38], // point of the icon which will correspond to marker's location
  popupAnchor: [-1, -36] // point from which the popup should open relative to the iconAnchor
});

const LoungeStep: React.FC<ILoungeStepProps> = (props) => {
  const [distanceToLounge, setDistanceToLounge] = useState<{
    [key: string]: { distance: number; measureUnit: 'm' | 'km' };
  }>({});
  const [currentLocation, setCurrentLocation] = useState<
    [number, number] | null
  >(null);
  const [map, setMap] = useState<L.Map | null>(null);
  const lounges: Lounge[] = useWithSelection(loungeSelector);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<{
    map: boolean;
    markers: boolean;
    distance: boolean;
  }>({ map: true, markers: true, distance: true });
  const { watch, setValue } = useFormContext<IEventBookForm>();

  const selectedLoungeId = watch('loungeId');

  const filteredLounges = useMemo(
    () =>
      !search
        ? lounges
        : lounges.filter(({ name }) => isSubstring(name, search)),
    [lounges, search]
  );

  const selectedLounge = useMemo(
    () => lounges.find(({ id }) => selectedLoungeId === id),
    [selectedLoungeId, lounges]
  );

  useEffect(() => {
    setIsLoading((loading) => ({ ...loading, map: true }));

    const newMap = L.map('map').setView([51.505, -0.09], 13);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 17,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(newMap);

    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        const location: [number, number] = [latitude, longitude];
        setCurrentLocation(location);
        setIsLoading((loading) => ({ ...loading, map: false }));
      },
      () => setIsLoading((loading) => ({ ...loading, map: false }))
    );

    setMap(newMap);
  }, []);

  useEffect(() => {
    if (selectedLounge) {
      selectLounge(selectedLounge);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLounge]);

  useEffect(() => {
    if (map && currentLocation && !selectedLoungeId) {
      map.setView(currentLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, map]);

  useEffect(() => {
    setIsLoading((loading) => ({ ...loading, markers: true }));

    if (!lounges.length || !map) return;

    lounges.forEach((lounge) => {
      const { name, lat, lng } = lounge;

      if (!(lat && lng)) return;

      L.marker([+lat, +lng], { icon: marketIcon })
        .addTo(map)
        .bindPopup(
          `<div style="width: 100%; text-align: center; font-size: 14px; color: #000047">${name}</div>`
        )
        .on('click', () => selectLounge(lounge));
    });

    setIsLoading((loading) => ({ ...loading, markers: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lounges, map]);

  useEffect(() => {
    if (!lounges.length) return;

    setIsLoading((loading) => ({ ...loading, distance: true }));

    if (currentLocation && lounges) {
      setDistanceToLounge(
        lounges.reduce((acc, value) => {
          let distance = Math.round(
            distanceTo([+value.lat, +value.lng], currentLocation)
          );
          let measureUnit = 'm';

          if (distance >= 1000) {
            distance = Math.round(distance / 1000);
            measureUnit = 'km';
          }

          return {
            ...acc,
            [value.id]: {
              distance,
              measureUnit
            }
          };
        }, {})
      );
    }

    setIsLoading((loading) => ({ ...loading, distance: false }));
  }, [lounges, currentLocation]);

  const selectLounge = (lounge: Lounge) => {
    const { id, lat, lng, available_simulators } = lounge;

    setValue('loungeId', id);
    setValue('simulatorsAmount', available_simulators);

    if (map) {
      map.setView([+lat, +lng]);
    }
  };

  const selectedLoungeName = selectedLounge ? selectedLounge.name : '';

  const isDataLoading = Object.values(isLoading).some(Boolean);

  return (
    <BaseCard
      title="booking.steps.lounge.title"
      forwardTitle="booking.steps.lounge.forwardButton"
      collapseData={selectedLoungeName}
      {...props}
      onForward={selectedLoungeId ? props.onForward : undefined}
      isLoading={props.collapsed && isDataLoading}
    >
      <div className={styles.loungeStep}>
        <div className={styles.loungeStep__lounges}>
          <SearchBox value={search} onChange={setSearch} />
          <div className={styles.loungesList}>
            {filteredLounges.map((lounge) => {
              const { id, name, zip_code, country, city, address1 } = lounge;
              const loungeDistance = distanceToLounge[id];

              return (
                <div
                  key={id}
                  className={classNames(styles.loungesList__lounge, {
                    [styles.loungesList__lounge_selected]:
                      selectedLoungeId === id
                  })}
                  onClick={() => selectLounge(lounge)}
                >
                  <div className={styles.loungesList__name}>
                    <h3>{name}</h3>
                    {loungeDistance ? (
                      <p>
                        (
                        {`${loungeDistance.distance} ${loungeDistance.measureUnit}`}
                        )
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  <p className={styles.loungesList__address}>
                    {[address1, [zip_code, city].filter(Boolean).join(' '),country]
                      .filter(Boolean)
                      .join(', ')}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div id="map" className={styles.loungeStep__map} />
        {isDataLoading ? (
          <Loader fullSize color="black" width={80} height={80} />
        ) : (
          ''
        )}
      </div>
    </BaseCard>
  );
};

export default LoungeStep;
