import { MembershipPlan } from '../membership/membership.types';
export const CHANGE_LANG = 'CHANGE_LANG';
export const SET_MEMBER = 'SET_MEMBER';
export const SET_QUOTAS = 'SET_QUOTAS';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO';
export const DELETE_PAYMENT_INFO = 'DELETE_PAYMENT_INFO';

export interface BaseCustomer {
  email: string;
  nickname: string;
  first_name: string;
  last_name: string;
  password: string;
  profile_data_incomplete: boolean;
}

export interface Customer extends BaseCustomer {
  profile_pic_url?: string;
  membership_plan?: MembershipPlan;
  social_networks?: SocialNetwork[];
}

export interface PaymentInfo {
  card_brand: string;
  card_number_fragment: string;
  card_expired: boolean;
  card_expires_on: string;
  [key: string]: any;
}

export interface AccountActivation {
  customer_id: string;
  token: string;
}

export interface CustomerActivation {
  customer_id: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export interface SocialNetwork {
  id: string;
  social_network: string;
  url: string | null;
  username: string | null;
}

export interface CustomerState {
  customer: Customer | null;
  paymentInfo: PaymentInfo | null;
  quotas: any | null;
}

interface SignOutAction {
  type: typeof SIGN_OUT;
}

export interface SetCustomerAction {
  type: typeof SET_MEMBER;
  payload: Customer;
}

export interface SetQuotasAction {
  type: typeof SET_QUOTAS;
  payload: any;
}

interface UpdatePaymentInfoAction {
  type: typeof UPDATE_PAYMENT_INFO;
  payload: PaymentInfo;
}

interface ChangeLangAction {
  type: typeof CHANGE_LANG;
}

export type CustomerActionTypes =
  | SignOutAction
  | SetCustomerAction
  | UpdatePaymentInfoAction
  | ChangeLangAction
  | SetQuotasAction;
