import React from 'react';
import MembershipWidget from '../../components/MembershipWidget';
import BookingWidget from '../../components/BookingWidget';
import RankingWidget from './RankingWidget';
import CustomerWidget from './CustomerWidget';
import EventsWidget from './EventsWidget';
import DraftEventWidget from './DraftEventWidget';

import './Dashboard.scss';

const Dashboard = () => (
  <div className="Dashboard">
    <div className="row">
      <div className="col-lg-6">
        <CustomerWidget />
      </div>
      <div className="col-md-6 col-lg-3">
        <BookingWidget />
      </div>
      <div className="col-md-6 col-lg-3">
        <MembershipWidget />
      </div>
      <div className="col-lg-12">
        <DraftEventWidget />
      </div>
      <div className="col-md-6 col-lg-6">
        <RankingWidget />
      </div>
      <div className="col-md-6 col-lg-6">
        <EventsWidget />
      </div>
    </div>
  </div>
);

export default Dashboard;
