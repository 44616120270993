import React, { useState } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import SideInfo from './SideInfo';
import ActivationMessage from './ActivationMessage';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import AccountActivation from '../AccountActivation';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';

import './Auth.scss';

const Auth = () => {
  const history = useHistory();
  const customer = useWithSelection(customerSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');

  const closeDialog = () => {
    history.push('/auth/sign-in');
    setIsOpen(false);
  };

  const openDialog = (msg: string) => {
    setIsOpen(true);
    setSuccessMsg(msg);
  };

  if (customer) {
    if (customer.profile_data_incomplete) {
      return <Redirect
        to={{
          pathname: '/profile',
          state: { data_incomplete: true }
        }} />;
    } else {
      return <Redirect to="/" />;
    }
  }

  return (
    <div className="Auth">
      <Dialog onClose={closeDialog} open={isOpen} className="ru-dialog">
        <ActivationMessage msg={successMsg} onClose={closeDialog} />
      </Dialog>
      <SideInfo />
      <div className="Auth-content">
        <Switch>
          <Route exact path="/auth/sign-in" component={SignIn} />
          <Route exact path="/auth/sign-up" component={SignUp} />
          <Route
            exact
            path="/auth/forgot-password"
            component={ForgotPassword}
          />
          <Route
            exact
            path="/auth/reset-password/:customer_id/:token"
            component={ResetPassword}
          />
          <Route
            exact
            path="/auth/activation/:customer_id/:token"
            render={() => <AccountActivation openDialog={openDialog} />}
          />
          <Route exact
            path="/auth/silent-member-activation/:customer_id/:token"
            component={SignUp} />
          <Route component={SignIn} />
        </Switch>
      </div>
    </div>
  );
};

export default Auth;
