import { LoungeActionTypes, GET_LOUNGE, Supervisor } from './lounge.types';
import i18n from '../../i18n/index';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';

const options = {
  headers: {
    'x-locale': i18n.language
  }
};

export const getLounge = async (
  dispatch: Dispatch<LoungeActionTypes>,
  events_only: boolean = false
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/lounges`, {
      ...options,
      params: { events_only }
    });
    const loungeData = res.data.data;

    dispatch({
      type: GET_LOUNGE,
      payload: loungeData
    });
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const getSupervisorByLoungeId = async (
  id: string,
  controller?: AbortController
): Promise<Supervisor | null> => {
  try {
    const res = await api.get(`${API_ROOT}/lounges/${id}/supervisor_info`, {
      ...options,
      signal: controller && controller.signal
    });
    const supervisor = res.data.data;

    return supervisor;
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};
