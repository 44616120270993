const replacements = {
  ä: 'a',
  ö: 'o',
  ü: 'u',
  ç: 'c',
  ô: 'o'
};
const replacementsMap: Map<string, string> = new Map(
  Object.entries(replacements)
);

const keys = Object.keys(replacements).join('');

export function isSubstring(source: string, value: string): boolean {
  const lowerName = (source || '').toLowerCase();
  const replacedName = lowerName.replace(
    new RegExp(`[${keys}]`, 'g'),
    (m) => replacementsMap.get(m) || ''
  );
  const searchLower = value.toLowerCase();

  return lowerName.includes(searchLower) || replacedName.includes(searchLower);
}
