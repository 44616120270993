import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';
import { Event } from '../../../store/events/events.types';
import { eventsSelector } from '../../../store/events/events.selector';
import useWithSelection from '../../../hooks/useWithSelection';

import './EventsWidget.scss';

const langs: any = {
  de,
  en,
  es
};

const EventsWidget = () => {
  const events = useWithSelection(eventsSelector);
  const [t, i18n] = useTranslation();

  const slicedEvents = events.slice(0, 3);

  return (
    <div className="dashboard-widget fixed-header EventsWidget">
      <div className="dashboard-widget-header">
        <h3>{t('events.events')}</h3>
        <Link to="/events">{t('dashboard.viewAll')}</Link>
      </div>
      <div className="dashboard-widget-body">
        {slicedEvents.map((e: Event) => (
          <div className="EventsWidget-event" key={e.id}>
            <h3>{e.title}</h3>
            <span>
              {format(new Date(e.start_time), 'MMMM dd.yyyy', {
                locale: langs[i18n.language]
              })}{' '}
              -{' '}
              {format(new Date(e.end_time), 'MMMM dd.yyyy', {
                locale: langs[i18n.language]
              })}
            </span>
            <p>{e.description_excerpt}</p>
            <Link to={`/events/${e.id}`}>{t('events.readMore')}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventsWidget;
