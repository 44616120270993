const booking = {
  pageTitle: 'Book a Ride',
  pageSubtitle: 'Book a single ride or upgrade plan',
  pageBtnLabel: 'Book Now',
  loadingPrice: 'Loading...',
  invalidTime: 'Select Time',
  title: 'Book a Single Ride',
  groupTitle: 'Book a Group Ride',
  singleRideFormTitle: 'Book a Single Ride',
  groupRideFormTitle: 'Book a Group Ride',
  subtitle: 'Choose your ride sesion',
  minutes: 'min',
  simulators: 'Simulators',
  ridesSharing: 'Package Sharing',
  freeRide: 'free ride',
  payNow: 'Pay Now',
  summary: 'Summary',
  priceSummary: 'Price Summary',
  booking: 'Booking',
  participants: 'Participants',
  lounge: 'Lounge',
  duration: 'Duration',
  program: 'Program',
  extra: 'Extra',
  organiser: 'Organiser',
  food: 'Food',
  summaryAndPayment: 'Summary & Payment',
  date: 'Date',
  time: 'Time',
  vatInfo: 'Prices are in {{currency}} including {{vat}}% Vat.',
  total: 'Total',
  raceDay: 'Race Day',
  currentDay: 'Current Day',
  fullyBooked: 'Unavailable',
  nonBookable: 'Closed',
  getNow: 'Get Now',
  choseMembershipPackageTitle: 'Get a Starter Pack',
  choseMembershipPackageSubtitle: 'Choose your membership package',
  package: 'Package',
  description: 'Description',
  planUpgradeSuccess: 'Membership plan updateded!!',
  bookingSuccessTitle: 'Thank you for booking a ride',
  subscriptionSuccessTitle: 'Thank you for updating subscription',
  close: 'Close',
  widgetText: 'Book a single ride, choose amongst our experiences.',
  widgetTextGroup: 'Book a group ride, choose amongst our experiences.',
  memberships: 'Memberships',
  price: 'Price',
  enterCouponCode: 'Enter Discount Code',
  couponApply: 'Apply',
  couponApplied: 'Applied!',
  timeSlotError: 'Please select a timeslot.',
  timeError: 'Please select the reservation time.',
  saveEventAsDraft: 'Save Event as Draft',
  exit: 'Exit',
  createAnAccount: 'Sign in / Create account',
  createAnAccountInfo:
    'To save an event as a draft, you have to sign in or create an account first.',
  savingEventAsADraft: 'Saving Event as a Draft',
  savingEventAsADraftInfo:
    'You are saving all event details <1>besides the time slot</1>.<br />The time slot will be blocked for you only after confirming the event.',
  deleteDraftEvent: 'Delete the event draft',
  deleteDraftEventOk: 'Delete draft event',
  deleteDraftEventInfo:
    "Are you sure you want to cancel your event?\nThis action can't be undone.",
  dateAndTime: 'Date & Time',
  simulatorsAndDuration: 'Simulators & Duration',
  location: 'Location',
  draftEventHasBeenSaved: 'The event has been saved as draft',
  eventHasBeenCreated: 'The event has been created',
  noEventDraftFound: 'No event draft found',
  summaryEmptyState: 'Price unavailable. Please configure your event',
  saveAsDraftDisabledTooltip:
    'Save as draft is not available until the lounge, duration and date steps are completed',
  eventBookingTitle: 'Event Booking',

  steps: {
    back: 'Back',
    caption:
      "If our standard options don't fit your needs you may <1>contact us</1> for a tailor-made event.",
    participants: {
      title: 'How many participants will take part in the event?',
      subtitle:
        'This will help us estimate your event duration and suggest appropriate lounges.',
      counterLabel: 'Number of Participants',
      error:
        'The selected lounge only allows up-to {{participantsCount}} participants by default. In order to fulfill your requirement, please <1>contact us</1> directly',
      forwardButton: 'Select Location'
    },
    mobileIsNotAllowed:
      'Oops, this part is still under construction. If you wish to use it, please enable <1>"Desktop Mode"</1> in your mobile browser or use <1>your desktop</1>',
    simulators: {
      title: 'How many simulators would you like to book?',
      subtitle: 'The best is to reserve one simulator per each racer.',
      counterLabel: 'Number of Simulators',
      forwardButton: 'Select Location',
      durationTitle: 'How long would you like to race?'
    },
    lounge: {
      title: 'Where would you like to organise the event?',
      forwardButton: 'Select Duration'
    },
    location: {
      title: 'Where would you like to race?',
      forwardButton: 'Select Date and Time'
    },
    duration: {
      title: 'How long would you like your event to be?',
      subtitle:
        'Based on our experience we prepared two versions for the event duration.',
      forwardButton: 'Select Date',
      bestseller: 'Our Bestseller',
      counterSuffix: 'hours',
      from: 'from',
      emptyState:
        'Your request needs a special format, <1>please contact our team.</1>'
    },
    date: {
      title: 'When would you like to have your event?',
      subtitle:
        'Here are the possible dates for the chosen number of participants, location and event duration.',
      forwardButton: 'Select food and beverages',
      emptySlots:
        "There are no available slots on this day.\nIf you can't find a suitable date, contact us:"
    },
    food: {
      title: 'What would you like to eat?',
      subtitle:
        'Choose one of our defined sets or describe your special wishes.',
      forwardButton: 'Select Race Program',
      person: 'person'
    },
    program: {
      title: 'Which event structure do you prefer?',
      subtitle:
        'We offer different types of race days. Choose your preference or decide on-site.',
      forwardButton: 'Select Extras',
      readMore: 'Read More'
    },
    extra: {
      title: 'Would you like any additional options?',
      subtitle: 'Choose one or multiple extra options.',
      forwardButton: 'Enter Event Organiser Data',
      informationPanel: 'Make sure there are no typos or mistakes in the text.',
      uploadLabel: 'Upload Logo',
      viewExamplePhoto: 'View example photo'
    },
    organiser: {
      title: 'Who is organising the event?',
      subtitle: 'Let us know who we should contact about this event.',
      forwardButton: 'View Summary',
      organiserDataTitle: 'Event Organiser',
      billingDataTitle: 'Billing Data',
      company: 'Company',
      privateCustomer: 'Private Customer'
    },
    summary: {
      eventSummary: 'Event Summary',
      bookingSummary: 'Booking Summary',
      youAreOneStepAway: "You're One Step Away from Starting Your Engines!",
      where: 'Where',
      when: 'When',
      raceProgram: 'Race Program',
      editEventDetails: 'Edit Event Details',
      editBookingDetails: 'Edit Booking Details',
      yourEventSupervisor: 'Your Event Supervisor',
      supervisorInfo:
        'If you have any questions or requests, contact your event supervisor {{name}}.',
      confirmButton: 'Confirm Booking',
      confirmAndProceedButton: 'Confirm and Proceed',
      packageSubtitle: 'Decide how you want to use your package.',
      emptyPackage: "You don't have sharable packages.",
      billingDetails: 'Billing Details',
      payment: 'Payment',
      payInLounge: 'Pay in the Lounge',
      payByCard: 'Credit Card',
      specialRequest: 'Special Request',
      specialRequestTooltip:
        'Your event supervisor will contact you to discuss details.'
    },
    thankYou: {
      title: 'Thank you!',
      text: 'You will get the e-mail confirmation soon.\nIn case of any additional wishes or changes, please contact your event supervisor.\nSee you in the lounge!',
      button: 'Racing Unleashed Homepage'
    }
  }
};

export default booking;
