const header = {
  about: 'Quiénes somos',
  connect: 'Connect',
  signin: 'Ingresar',
  experience: 'Experiencia',
  join: 'Unirse',
  compete: 'Competir',
  followUs: 'Seguir',
  doBusiness: 'Asociación',
  becomeACustomer: 'Ser miembro',
  myProfile: 'Mi perfil',
  myAccount: 'Mi Account',
  changePassword: 'Cambiar contraseña',
  billing: 'Compras',
  membership: 'Membresia',
  notifications: 'Notificaciones',
  dashboard: 'Dashboard',
  rides: 'Reservar',
  ranking: 'Ranking',
  championships: 'Desafíos',
  events: 'Eventos',
  signOut: 'Desconectar'
};

export default header;
