import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';
import useWithDispatch from '../../hooks/useWithDispatch';
import { updateCustomer } from '../../store/customer/customer.actions';
import avatarPlaceholder from '../../images/avatar.svg';

import './AvatarImage.scss';

const AvatarImage = () => {
  const [t] = useTranslation();
  const [newAvatar, setNewAvatar] = useState<string>('');
  const customer = useWithSelection(customerSelector);
  const { profile_pic_url, nickname } = customer;
  const updateAvatarAction = useWithDispatch(updateCustomer);
  const fileTypes = ['png', 'jpg', 'jpeg'];

  const changeHandler = (evt: ChangeEvent<any>) => {
    const { files } = evt.target;

    if (!files.length) return;
    if (
      !fileTypes.includes(
        files[0].name
          .toLowerCase()
          .split('.')
          .pop()
      )
    ) {
      console.log('error file type');
      return;
    }
    if (files[0].size / Math.pow(1024, 2) > 5) {
      console.log('error size of img');
      return;
    }

    let reader = new FileReader();
    reader.addEventListener('load', e => {
      if (e.target) {
        setNewAvatar(e.target.result ? e.target.result.toString() : '');
        updateAvatarAction({ ...customer, profile_pic: e.target.result });
      }
    });
    reader.readAsDataURL(files[0]);
  };
  return (
    <div
      className={`AvatarImage  ${
        !newAvatar && !profile_pic_url ? 'has-overflow' : ''
      }`}
    >
      <label>
        <input type="file" onChange={changeHandler} />
        {(profile_pic_url || newAvatar) && <div>{t('field.change')}</div>}
      </label>
      {/* <img src={newAvatar || profile_pic_url || avatarPlaceholder} alt={nickname} /> */}
      <img src={profile_pic_url || newAvatar || avatarPlaceholder} alt={nickname} />
    </div>
  );
};

export default AvatarImage;
