import React, { useEffect, useState } from 'react';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toastUtil } from '../../../utils/toast.utils';
import { signUp } from '../../../store/customer/customer.actions';
import { formModel, initialValues } from './formModel';
import FormField from '../../../components/FormField';
import CheckboxRadio from '../../../components/FormField/CheckboxRadio';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import validationSchema from './validationSchema';
import mailbox from '../../../images/mailbox.svg';
import './SignUpForm.scss';
import { publish } from '../../../events';
import validationSchemaSilentMember from './validationSchemaSilentMember';
import { loungeSelector } from '../../../store/lounge/lounge.selectors';
import useWithSelection from '../../../hooks/useWithSelection';
import { getLounge } from '../../../store/lounge/lounge.actions';
import useWithDispatch from '../../../hooks/useWithDispatch';
import { FormModelField } from '../../../types/form.types';

interface ParamTypes {
  customer_id: string;
  token: string;
}
const SignUpForm = () => {
  const { customer_id, token } = useParams<ParamTypes>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = useState<string | null>(null);
  const [confirmText, setConfirmText] = useState<string>('');
  const loungeDispatch = useWithDispatch(getLounge);
  const lounges = useWithSelection(loungeSelector);
  // eslint-disable-next-line
  const [refresh, setRefresh] = useState(false); 
  const [t] = useTranslation();
  var silentMemberRegistration = false;
  var schema;
  if (customer_id && token) {
    silentMemberRegistration = true;
    initialValues.customer_id = customer_id;
    initialValues.token = token;
    schema = validationSchemaSilentMember;
  } else {
    schema = validationSchema;
  }

  useEffect(() => {
    loungeDispatch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (lounges.length === 0) {
      return;
    }

    formModel.forEach((field: FormModelField) => {
      if (field.name === "default_lounge_id") {
        field.list = [];
        lounges.forEach((lounge: any) => {
          if (field.list) {
            field.list.push({ id: lounge.id, label: lounge.name });
          }
        });
      }
    });
     
    // Trigger re-render
    setRefresh(prev => !prev);
  }, [lounges]);

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        let response = await signUp(values);
        setConfirmText(response.message);
        setCustomerEmail(response.email);
        if (values) {
          publish('Registration', {
            em: values.email,
            fn: values.first_name,
            ln: values.last_name
          });
        }
      } catch (err) {
        setSubmitting(false);
        if (err.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (err && err.meta) toastUtil('error', err.meta.message);
      }
    }
  });
  const isValidForm = schema.isValidSync({ ...formik.values });

  return (
    <div className="SignUpForm Auth-form">
      {customerEmail ? (
        <div className="SignUpForm-success">
          <img src={mailbox} alt="" />
          <p>
            {confirmText} <Link to="/auth/sign-in">{t('auth.backToSignIn')}</Link>
          </p>
        </div>
      ) : (
        <>
          <h2>{!silentMemberRegistration ? t('auth.signUp.title') : t('auth.signUp.titleSilentMember')}</h2>
          <h4>{t('auth.signUp.subtitle')}</h4>
          <form onSubmit={formik.handleSubmit}>
            {formModel.map(field => (
              (!['first_name', 'last_name', 'email', 'customer_id', 'token'].includes(field.name) && silentMemberRegistration)
                || (!['customer_id', 'token'].includes(field.name) && !silentMemberRegistration) ?
                (
                  <FormField
                    key={field.name}
                    i18nKey={field.i18nKey}
                    type={field.type}
                    errors={formik.errors}
                    touched={formik.touched}
                    list={field.list}
                    setFieldValue={formik.setFieldValue}
                    {...formik.getFieldProps(field.name)}
                  />
                ) : false))}
            <CheckboxRadio
              onChange={formik.handleChange}
              name="age"
              value={formik.values.age}
              i18nKey="field.signUpAge"
            />
            <CheckboxRadio
              onChange={formik.handleChange}
              name="tos_accepted"
              value={formik.values.tos_accepted}
            >
              <Trans i18nKey="field.tos.label">
                I accept the
                <Link to="/terms-of-service/" target='_blank'> Terms of Service </Link> and
                <Link to="/privacy-policy" target='_blank'>
                  Privacy policy and use of cookies
                </Link>
              </Trans>
            </CheckboxRadio>
            <Button
              type="submit"
              disabled={submitting || !isValidForm}
              submitting={submitting}
              label={!silentMemberRegistration ? t('field.signUp'): t('field.signUpSilentMember')}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default SignUpForm;
