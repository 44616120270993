const billing = {
  enterDetailsTitle: 'Kreditkarte hinzufügen',
  updateDetailsTitle: 'Zahlungsmethode ändern ',
  detailsTitle: 'Zahlungsmethode',
  updateDetailsSubtitle: 'Ändere oder lösche deine Kreditkartendaten',
  enterDetailsSubtitle: 'Gib deine Kreditkartendetails ein.',
  cardHolderName: {
    label: 'Name auf Kreditkarte',
    required: 'Dieses Feld wird benötigt.'
  },
  card_number: {
    label: 'Kartennummer',
    incomplete_number: 'Deine Kreditkartennummer ist unvollständig',
    invalid_number: 'Deine Kreditkartennummer ist ungültig'
  },
  card_expiration: {
    label: 'Ablaufdatum',
    incomplete_expiry: 'Das Ablaufdatum ist unvollständig',
    invalid_expiry_year_past: 'Das Ablaufdatum ist ungültig'
  },
  card_cvc: {
    label: 'CVC',
    incomplete_cvc: 'Der CVC ist unvollständig.'
  },
  card_cvv: {
    label: 'CVV',
    incomplete_cvc: 'Der CVV ist unvollständig.'
  },
  expiresOn: 'Ablaufdatum',
  saveCardForLater: 'Kreditkarte für spätere Bestellungen speichern',
  updateSubmit: 'Kreditkarte hinzufügen',
  removeCard: 'Löschen',
  enterSubmit: 'Bestellvorgang fortsetzen'
};

export default billing;
