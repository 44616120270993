import React, { ChangeEvent, FocusEvent } from 'react';
import TextInput from './TextInput';
import Select from './Select';

import './FormField.scss';
import Datepicker from './Datepicker';

export interface IListField {
  label?: string;
  name?: string;
  id: string;
  value?: string;
}

export interface BasicField {
  name: string;
  i18nKey: string;
  type: string;
  value: string;
  touched?: any;
  errors?: any;
  errorMessage?: string;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (evt: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  selectedDate?: Date;
  required?: boolean;
}

interface IFormField extends BasicField {
  list?: IListField[];
  setFieldValue: (name: string, value: string) => void;
}

const FormField = ({
  name,
  onChange,
  value,
  type,
  i18nKey,
  errors,
  errorMessage,
  list,
  setFieldValue,
  touched,
  disabled,
  required,
  selectedDate
}: IFormField) => {
  switch (type) {
    case 'select':
      return (
        <Select
          name={name}
          i18nKey={i18nKey}
          list={list}
          value={value}
          disabled={disabled}
          errors={errors}
          touched={touched}
          errorMessage={errorMessage}
          onChange={onChange}
          setFieldValue={setFieldValue}
        />
      );
      case 'datepicker':
        return (
          <Datepicker
            name={name}
            value={value}
            i18nKey={i18nKey}
            errors={errors}
            touched={touched}
            onChange={onChange}
            selectedDate={selectedDate}
          />
        );
    default:
      return (
        <TextInput
          name={name}
          type={type}
          value={value}
          i18nKey={i18nKey}
          errors={errors}
          touched={touched}
          onChange={onChange}
          disabled={disabled}
          required={required}
        />
      );
  }
};

export default FormField;
