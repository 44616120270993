const field = {
  identifier: {
    label: 'E-Mail',
    required: 'Dieses Feld wird benötigt.'
  },
  email: {
    label: 'E-Mail Adresse*',
    placeholder: 'Geben Sie Ihre E-Mail ein...',
    required: 'Dieses Feld wird benötigt',
    invalidEmail: 'Ungültige E-Mail Adresse'
  },
  first_name: {
    label: 'Vorname*',
    required: 'Dieses Feld wird benötigt.'
  },
  last_name: {
    label: 'Nachname*',
    required: 'Dieses Feld wird benötigt.'
  },
  name_and_surname: {
    label: 'Name und Nachname',
    required: 'Name und Nachname sind erforderlich',
    placeholder: 'Name und Nachname eingeben...'
  },
  nickname: {
    label: 'Spitzname*',
    required: 'Dieses Feld wird benötigt.'
  },
  password: {
    label: 'Passwort*',
    required: 'Dieses Feld wird benötigt.',
    minLength: 'Das Passwort muss mindestens 8 Zeichen lang sein.'
  },
  password_confirmation: {
    label: 'Passwort bestätigen*',
    required: 'Dieses Feld wird benötigt.',
    minLength: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    matching: 'Die Passwörter stimmen nicht überein.'
  },
  default_lounge_id: {
    label: 'Lounge*',
    required: 'Dieses Feld wird benötigt.'
  },
  remember_me: {
    label: 'Eingeloggt bleiben.'
  },
  tos: {
    label:
      'Ich akzeptiere die <1> AGB </1>, <3>Datenschutzrichtlinie und die Verwendung von Cookies</3>'
  },
  preferred_language: {
    label: 'Sprache',
    required: 'Dieses Feld wird benötigt.'
  },
  phone_number: {
    label: 'Telefonnummer',
    required: 'Telefonnummer ist erforderlich',
    invalid: 'Rufnummer ist ungültig'
  },
  address1: {
    Label: 'Adresse',
    required: 'Dieses Feld ist erforderlich'
  },
  address2: {
    label: 'Adresszeile 2',
    required: 'Dieses Feld ist erforderlich'
  },
  country_id: {
    label: 'Land',
    required: 'Landkreis ist erforderlich',
    companyPlaceholder: 'Land des Unternehmens auswählen...'
  },
  city: {
    label: 'Stadt',
    required: 'Stadt ist erforderlich',
    companyPlaceholder: 'Stadt des Unternehmens eingeben...'
  },
  zip_code: {
    label: 'Postleitzahl',
    companyPlaceholder: 'Postleitzahl des Unternehmens eingeben...'
  },
  region: {
    label: 'Region',
    companyPlaceholder: 'Region des Unternehmens eingeben...'
  },
  pob_country_id: {
    Label: 'Geburtsland',
    required: 'Dieses Feld ist erforderlich'
  },
  fav_lounge_id: {
    label: 'Lieblingslounge'
  },
  fav_simulator_id: {
    Label: 'Lieblingssimulator'
  },
  body_weight: {
    label: 'Körpergewicht (kg)'
  },
  body_height: {
    Label: 'Körpergröße (cm)'
  },
  company: {
    Label: 'Unternehmen',
    placeholder: 'Unternehmensname eingeben...',
    required: 'Firmenname ist erforderlich'
  },
  lounge_id: {
    label: 'Lounge'
  },
  dob: {
    label: 'Geburtsdatum'
  },
  bookingTime: {
    label: 'Zeit'
  },
  monthSelector: {
    label: 'Datum'
  },
  carSelector: {
    label: 'Rennwagen'
  },
  trackSelector: {
    label: 'Rennstrecken'
  },
  search: {
    placeholder: 'Suche...'
  },
  change: 'ändern',
  update: 'Aktualisieren',
  signUpAge: 'Ich bin älter als 13 Jahre.',
  submit: 'Übernehmen',
  submiting: 'In Bearbeitung...',
  signIn: 'Anmelden',
  signUp: 'Registrieren',
  signUpSilentMember: 'Aktivieren',
  updateProfile: 'Profil akzeptieren',
  forgotPassword: 'Passwort zurücksetzen',
  cresetPassword: 'Neues Passwort eingeben',
  signingIn: 'Signing In...',
  signingUp: 'Signing Up...',
  callToSignUp: 'Noch kein Konto? <1>Jetzt anmelden!<1>',
  callToSignIn: 'Du hast schon ein Konto? <1>Jetzt einloggen!<1>',
  firstLine: {
    label: 'Erste Zeile',
    placeholder: 'Text hier eingeben...',
    required: 'Erste Zeile ist erforderlich'
  },
  secondLine: {
    label: 'Zweite Zeile',
    placeholder: 'Text hier eingeben...',
    required: 'Zweite Zeile ist erforderlich'
  },
  fileUpload: {
    caption: 'Wir akzeptieren {{formats}} Dateien',
    uploaded: 'Datei Hochgeladen',
    delete: 'Datei Löschen',
    required: 'Datei ist erforderlich'
  },
  organiserName: {
    label: 'Name',
    placeholder: 'Name des Veranstalters eingeben...',
    required: 'Name des Veranstalters ist erforderlich'
  },
  organiserEmail: {
    label: 'E-mail',
    placeholder: 'E-Mail des Veranstalters eingeben...',
    required: 'E-Mail des Veranstalters ist erforderlich',
    invalid: 'E-Mail des Veranstalters ist ungültig'
  },
  companyAddressLine: {
    label: 'Adresse Zeile {{number}}',
    placeholder: 'Adresse eingeben...',
    required: 'Adresse ist erforderlich',
    companyLabel: 'Adresse des Unternehmens Zeile {{number}}',
    companyPlaceholder: 'Adresse des Unternehmens eingeben...',
    companyRequired: 'Firmenadresse ist erforderlich'
  },
  taxNumber: {
    label: 'Steuernummer',
    placeholder: 'Steuernummer des Unternehmens eingeben...',
    required: 'Steuernummer ist erforderlich'
  },
  processignPersonalData: {
    label:
      'Ich bin mit der Erhebung und Verarbeitung meiner personenbezogenen Daten zum Zwecke der Veranstaltungsorganisation einverstanden.'
  },
  getUpdates: {
    label:
      'Ich möchte mich für Updates von Racing Unleashed über kommende Veranstaltungen und Aktionen anmelden. Ich bestätige, dass ich die <1>Nutzungsbedingungen</1> und <2>Datenschutzrichtlinien</2> gelesen habe und damit einverstanden bin.'
  },
  processingBillingData: {
    label:
      'Ich bin mit der Sammlung und Verarbeitung meiner Rechnungsdaten für Zahlungszwecke einverstanden.'
  },
  validations: {
    minLength: 'Mindestlänge {{length}} zeichen',
    maxLength: 'Maximale länge {{length}} `eichen',
    invalidFileType: 'Ungültiger Dateityp',
    maxSize: 'Maximale Dateigröße {{size}}MB',
    emojisNotAllowed: 'Emojis sind nicht erlaubt',
    max: 'Es können nicht mehr als {{max}} sein'
  },
  additionalRequest: {
    label: 'Zusätzliche Anfrage',
    placeholder:
      'Geben Sie hier Ihre zusätzlichen Wünsche ein und wir werden uns mit Ihnen in Verbindung setzen.'
  },
  coupon: {
    label: 'Gutschein',
    placeholder: 'Gutscheincode eingeben...'
  },
  privacyPolicy: {
    label:
      'Ich habe die <1>Datenschutzrichtlinien</1> gelesen und stimme ihnen zu.',
    link: 'https://www.racing-unleashed.com/files/publicimages/Datenschutzrichtlinie_V03.08.09.2023_DE.pdf'
  },
  termsOfUse: {
    label:
      'Ich habe die <1>Nutzungsbedingungen</1> gelesen und stimme ihnen zu.',
    link: 'https://www.racing-unleashed.com/files/publicimages/Ordnerstruktur/04_Dateien/20200124_Allgemeine_Gesch%C3%A4ftsbedingungen_v2.pdf'
  },
  cancelationPolicy: {
    label: 'Ich habe die <1>Widerrufsbelehrung</1> gelesen und stimme ihnen zu.'
  },
  additionalSpecialRequest: {
    label: 'Ich habe zusätzliche Sonderwünsche zu den Speisen und Getränken'
  },
  specialRequest: {
    label: 'Besondere Anfrage',
    placeholder: 'Geben Sie hier Ihre Sonderwünsche ein',
    required: 'Sonderwunsch ist erforderlich'
  },
  firstName: {
    label: 'Vorname',
    placeholder: 'Geben Sie Ihren Vornamen ein...',
    organiserPlaceholder: 'Vorname des Veranstalters eingeben...',
    required: 'Vorname ist erforderlich'
  },
  lastName: {
    label: 'Nachname',
    placeholder: 'Geben Sie Ihren Nachnamen ein...',
    organiserPlaceholder: 'Nachname des Veranstalters eingeben...',
    required: 'Nachname ist erforderlich'
  }
};

export default field;
