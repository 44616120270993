import { format } from 'date-fns';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AvatarImage from '../../../components/AvatarImage';
import Button from '../../../components/Button';
import FormField from '../../../components/FormField';
import useWithDispatch from '../../../hooks/useWithDispatch';
import useWithSelection from '../../../hooks/useWithSelection';
import { genderCodeSelector } from '../../../store/code/code.selectors';
import { countrySelector } from '../../../store/country/country.selectors';
import { deleteCustomer, signOut, updateCustomer } from '../../../store/customer/customer.actions';
import { customerSelector } from '../../../store/customer/customer.selectors';
import { loungeSelector } from '../../../store/lounge/lounge.selectors';
import { getLoungeSimulators } from '../../../store/simulator/simulator.actions';
import { toastUtil } from '../../../utils/toast.utils';
import Dialog from '@material-ui/core/Dialog';
import CloseDialog from '../../../components/CloseDialog';
import './ProfileForm.scss';
import { formModel } from './formModel';
import validationSchema from './validationSchema';
import Delete from './Delete';

interface Props {
  data_incomplete: boolean;
}

const ProfileForm = ({ data_incomplete }: Props) => {

  const customer = useWithSelection(customerSelector);
  const lounges = useWithSelection(loungeSelector);
  const genders = useWithSelection(genderCodeSelector);
  const countries = useWithSelection(countrySelector);
  const [selectedDob, setSelectedDob] = useState<Date>();

  const {
    nickname,
    first_name,
    last_name,
    email,
    default_lounge_id,
    address1,
    address2,
    city,
    zip_code,
    gender_id,
    country_id,
    pob_country_id,
    company,
    body_weight,
    body_height,
    dob,
    fav_lounge_id,
    fav_simulator_id,
    phone_number
  } = customer;

  const [simulators, setSimulators] = useState<any>();
  const fetchSimulators = async (lounge_id: string) => {
    setSimulators([]);
    formik.setFieldValue('fav_simulator_id', "");
    if (!lounge_id) {
      return;
    }
    try {
      const res = await getLoungeSimulators(lounge_id);
      setSimulators(res);
      formik.setFieldValue('fav_simulator_id', fav_simulator_id);
    } catch (err) { }
  };

  useEffect(() => {
    if (dob) {
      setSelectedDob(new Date(dob));
    }
    fetchSimulators(fav_lounge_id);
    // eslint-disable-next-line
  }, [fav_lounge_id]);

  const [loadUpdateInfo, setloadUpdateInfo] = useState<boolean>(true);
  const history = useHistory();
  // @ts-ignore
  if (history.location.state && history.location.state.data_incomplete) {
    let state = history.location.state;

    // @ts-ignore  
    delete state.data_incomplete;
    history.replace({ ...history.location, state: state });

    setloadUpdateInfo(data_incomplete);
  }

  formModel[formModel.findIndex(x => x.name === 'country_id')]['list'] = countries;
  formModel[formModel.findIndex(x => x.name === 'pob_country_id')]['list'] = countries;
  formModel[formModel.findIndex(x => x.name === 'default_lounge_id')]['list'] = lounges;
  formModel[formModel.findIndex(x => x.name === 'default_lounge_id')]['disabled'] = true;
  formModel[formModel.findIndex(x => x.name === 'email')]['disabled'] = true;
  formModel[formModel.findIndex(x => x.name === 'gender_id')]['list'] = genders;
  formModel[formModel.findIndex(x => x.name === 'fav_simulator_id')]['list'] = simulators;
  formModel[formModel.findIndex(x => x.name === 'fav_lounge_id')]['list'] = lounges;
  formModel[formModel.findIndex(x => x.name === 'fav_lounge_id')]['onChange'] = (evt: ChangeEvent<HTMLInputElement>) => {
    console.log('onCheckboxChange');
    formik.setFieldValue(evt.target.name, evt.target.value);
    fetchSimulators(evt.target.value);
  };
  formModel[formModel.findIndex(x => x.name === 'dob')]['selectedDate'] = selectedDob;
  formModel[formModel.findIndex(x => x.name === 'dob')]['onChange'] = (name: string, date: Date) => {
    formik.setFieldValue(name, date ? format(date, 'yyyy-MM-dd') : '');
    setSelectedDob(date);
  };

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [t] = useTranslation();

  const updateCustomerAction = useWithDispatch(updateCustomer);
  const deleteCustomerAction = useWithDispatch(deleteCustomer);
  const signOutAction = useWithDispatch(signOut);
  const [openCancel, setOpenCancel] = useState<boolean>(false);

  const closeCancelDialog = () => {
    setOpenCancel(false);
  };
  const onDeleteSuccess = async () => {
    await deleteCustomerAction();
    closeCancelDialog();
    await signOutAction();
  };

  const formik = useFormik({
    initialValues: {
      nickname,
      first_name,
      last_name,
      email,
      default_lounge_id,
      address1,
      address2,
      city,
      zip_code,
      gender_id,
      country_id,
      pob_country_id,
      company,
      body_weight,
      body_height,
      dob,
      fav_lounge_id,
      fav_simulator_id,
      phone_number
    },
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        const response = await updateCustomerAction(values);
        setSubmitting(false);
        toastUtil('info', response.meta.message);
      } catch (err) {
        setSubmitting(false);
        if (err.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (err && err.meta) toastUtil('error', err.meta.message);
      }
    }
  });

  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <div className="ProfileForm">
      {loadUpdateInfo ? (
        <div className='profile-update-info'>
          <h2>{t('profile.profileUpdateDetails')}</h2>
          <p>{t('profile.profileUpdateInfo')}</p>
          <div className='spacer-75'></div>
        </div>
      ) :
        <div>
          <h2>{t('profile.profileDetails')}</h2>
        </div>
      }

      <form onSubmit={formik.handleSubmit}>
        <AvatarImage />
        <div>
          {formModel.map((field) => (
            <FormField
              key={field.name}
              i18nKey={field.i18nKey}
              type={field.type}
              errors={formik.errors}
              touched={formik.touched}
              setFieldValue={formik.setFieldValue}
              list={field.list}
              disabled={field.disabled}
              required={field.required}
              {...formik.getFieldProps(field.name)}
              onChange={field.onChange || formik.getFieldProps(field.name).onChange}
              selectedDate={field.selectedDate}
            />
          ))}

          <div className="Profile-form-footer">
            <Button
              type='button'
              small={true}
              deleteClass={true}
              label={t('profile.deleteCustomer')}
              onClick={() => setOpenCancel(true)}
            />
            <Button
              type="submit"
              label={t('field.updateProfile')}
              disabled={submitting || !isValidForm}
              submitting={submitting}
            />
          </div>
        </div>
      </form>

      <Dialog onClose={closeCancelDialog} open={openCancel}>
        <CloseDialog onClick={closeCancelDialog} />
        <Delete onSuccess={onDeleteSuccess} />
      </Dialog>
    </div>
  );
};

export default ProfileForm;

