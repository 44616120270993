import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './AppStepper.module.scss';
import { ReactComponent as CheckIcon } from '../../images/check.svg';
import { ReactComponent as ErrorSvg } from '../../images/exclamation-circle.svg';

export interface IAppStep {
  key: string;
  label: string;
}

interface IAppStepperProps {
  /**
   * Steps array must contain translation keys
   */
  steps: IAppStep[];
  errorSteps?: string[];
  currentStep: number;
  className?: string;
}

const AppStepper: React.FC<IAppStepperProps> = ({
  steps,
  errorSteps,
  currentStep,
  className
}) => {
  const [t] = useTranslation();

  return (
    <div className={classNames(styles.stepper, className)}>
      {steps.map(({ key, label }, index) => {
        const isError = errorSteps && errorSteps.includes(key);
        const isCompleted = currentStep > index;
        return (
          <div
            key={label}
            className={classNames(styles.step, {
              [styles.step_active]: currentStep === index,
              [styles.step_completed]: isCompleted,
              [styles.step_error]: isError
            })}
          >
            <div className={styles.step__circle}>
              {isError ? (
                <ErrorSvg
                  className={classNames(
                    styles.step__icon,
                    styles.step__icon_error
                  )}
                />
              ) : isCompleted ? (
                <CheckIcon className={styles.step__icon} />
              ) : (
                index + 1
              )}
            </div>
            <div className={styles.step__label}>{t(label)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default AppStepper;
