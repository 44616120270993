import React, { useMemo } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import Counter from '../../Counter';
import styles from './ParticipantsStep.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';
import { Trans, useTranslation } from 'react-i18next';
import useWithSelection from '../../../hooks/useWithSelection';
import { loungeSelector } from '../../../store/lounge/lounge.selectors';
import { Lounge } from '../../../store/lounge/lounge.types';
import AppFormField from '../../AppFormField/AppFormField';
import { getFormError } from '../../../utils/get-form-error.utils';

export interface IParticipantsStepProps
  extends Pick<
    IBaseCardProps,
    'collapsed' | 'onForward' | 'onBack' | 'onClick'
  > {
  withDuration?: boolean;
}

const DEFAULT_MIN_PARTICIPANTS_AMOUNT = 5;
const DEFAULT_MAX_PARTICIPANTS_AMOUNT = 400;

const ParticipantsStep: React.FC<IParticipantsStepProps> = ({
  withDuration,
  ...props
}) => {
  const [t] = useTranslation();
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext<IEventBookForm>();
  const lounges: Lounge[] = useWithSelection(loungeSelector);

  const participantsAmount = watch('participantsAmount');
  const loungeId = watch('loungeId');

  const maxParticipants = useMemo(() => {
    const lounge =
      loungeId && lounges.length
        ? lounges.find(({ id }) => loungeId === id)
        : null;

    return lounge
      ? lounge.available_simulators * 5
      : DEFAULT_MAX_PARTICIPANTS_AMOUNT;
  }, [loungeId, lounges]);

  return (
    <BaseCard
      title="booking.steps.participants.title"
      subtitle="booking.steps.participants.subtitle"
      forwardTitle="booking.steps.participants.forwardButton"
      collapseData={participantsAmount}
      displayBack={false}
      {...props}
    >
      <Controller
        control={control}
        name="participantsAmount"
        rules={{
          max: {
            value: maxParticipants,
            message: t('field.validations.max', { max: maxParticipants })
          }
        }}
        render={({ value, onChange, onBlur }) => (
          <AppFormField>
            <Counter
              className={styles.counter}
              label="booking.steps.participants.counterLabel"
              value={value}
              min={DEFAULT_MIN_PARTICIPANTS_AMOUNT}
              max={DEFAULT_MAX_PARTICIPANTS_AMOUNT}
              onChange={onChange}
              onBlur={onBlur}
            />
          </AppFormField>
        )}
      />
      {getFormError('participantsAmount', errors) &&
      maxParticipants !== DEFAULT_MAX_PARTICIPANTS_AMOUNT ? (
        <p className={styles.error}>
          <Trans
            i18nKey="booking.steps.participants.error"
            values={{ participantsCount: maxParticipants }}
          >
            The selected lounge only allows participants by default. In order to
            fulfill your requirement, please
            <a href="mailto:events@racing-unleashed.com">contact us</a>
          </Trans>
        </p>
      ) : (
        ''
      )}
    </BaseCard>
  );
};

export default ParticipantsStep;
