const dashboard = {
  title: 'Dashboard',
  memberSince: 'Member since',
  upcomingRides: 'Upcoming Experiences',
  dateTime: 'Date / Time',
  experience: 'Experience',
  experiences: 'Experiences',
  lounge: 'Lounge',
  viewAll: 'View All',
  laps: 'Laps',
  tracks: 'Tracks'
};

export default dashboard;
