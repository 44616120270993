import React from 'react';
import Loader from '../Loader';
import classNames from 'classnames';

interface Button {
  type?: 'button' | 'submit' | 'reset';
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  submitting?: boolean;
  secondary?: boolean;
  primaryDark?: boolean;
  inverted?: boolean;
  white?: boolean;
  outline?: boolean;
  noShadow?: boolean;
  small?: boolean;
  deleteClass?: boolean;
  className?: string;
  onClick?: any;
}

const Button = ({
  type = 'button',
  label,
  icon,
  disabled,
  submitting,
  secondary,
  primaryDark,
  inverted,
  outline,
  noShadow,
  white,
  small,
  deleteClass,
  className,
  onClick = () => {}
}: Button) => (
  <button
    type={type}
    className={classNames(
      'btn',
      {
        primary: !secondary,
        primary_outline: outline,
        primary_noShadow: noShadow,
        primary_dark: primaryDark,
        secondary: secondary,
        inverted: inverted,
        white: white,
        s: small,
        lg: !small,
        delete: deleteClass,
        delete_outline: deleteClass && outline
      },
      className
    )}
    disabled={submitting || disabled}
    onClick={onClick}
  >
    {submitting ? (
      <Loader color={secondary ? 'dark-blue' : 'neon'} width={20} height={20} />
    ) : (
      label
    )}
    {icon ? (
      <span
        style={{ display: 'flex', minWidth: '24px', height: 'min-content' }}
      >
        {icon}
      </span>
    ) : (
      ''
    )}
  </button>
);

export default Button;
