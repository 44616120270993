import React from 'react';
import ProfileForm from './ProfileForm';
import { useHistory } from 'react-router-dom';

const Profile = () => {
  var data_incomplete = false;

  const history = useHistory();
  // @ts-ignore
  if (history.location.state && history.location.state.data_incomplete) {
    // @ts-ignore
    data_incomplete = history.location.state.data_incomplete;
  }

  return (
    <div className="Profile">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <ProfileForm data_incomplete={data_incomplete} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
