import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AuthAction.scss';
import logo from '../../images/ru-logo-blue-medium.svg';

interface AuthAction {
  isSignUp?: boolean;
  onlyBtn?: boolean;
}

const AuthAction = ({ isSignUp, onlyBtn }: AuthAction) => {
  const [t] = useTranslation();

  return (
    <div className="AuthAction">
      <img src={logo} alt="Racing Unleashed" />
      {!onlyBtn && (
        <span>
          {t(isSignUp ? 'auth.authAction.signIn' : 'auth.authAction.signUp')}
        </span>
      )}
      <Link
        className="btn secondary s alt-shadow"
        to={`/auth/${isSignUp ? 'sign-in' : 'sign-up'}`}
      >
        {t(
          isSignUp ? 'auth.authAction.btnSignIn' : 'auth.authAction.btnSignUp'
        )}
      </Link>
    </div>
  );
};

export default AuthAction;
