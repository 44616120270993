import axios from 'axios';

import {
  SET_MEMBER,
  SIGN_OUT,
  UPDATE_PAYMENT_INFO,
  CHANGE_LANG,
  AccountActivation,
  CustomerActivation,
  CustomerActionTypes,
  Customer,
  SET_QUOTAS
} from './customer.types';
import i18n from '../../i18n/index';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';
import { FormikValues } from 'formik';
import { publish } from '../../events';

const options = {
  headers: {
    'x-locale': i18n.language
  }
};

export const changeLang = (lang: string) => ({
  type: CHANGE_LANG,
  payload: lang
});

export const signOut = (dispatch: Dispatch<CustomerActionTypes>): void => {
  localStorage.removeItem('ruToken');
  localStorage.removeItem('ruState');
  i18n.changeLanguage(
    navigator.language.toLowerCase().match('en') ? 'en' : 'de'
  );

  dispatch({
    type: SIGN_OUT
  });
};

export const signIn = async (
  dispatch: Dispatch<CustomerActionTypes>,
  customer: Customer
): Promise<any> => {
  try {
    const res = await axios.post(
      `${API_ROOT}/authentication`,
      customer,
      options
    );
    if (typeof res === 'undefined' || !res) return;

    const customerData = res.data.data;

    localStorage.setItem('ruToken', customerData.token);
    publish('Login', {
      em: customerData.customer.email,
      external_id: customerData.customer.id,
      fn: customerData.customer.first_name,
      ln: customerData.customer.last_name
    });
    dispatch({
      type: SET_MEMBER,
      payload: customerData.customer
    });
    i18n.changeLanguage(customerData.customer.preferred_language);
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const signUp = async (data: FormikValues): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_ROOT}/registrations`,
      data,
      options
    );
    return response.data.meta;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const getCustomer = async (
  dispatch: Dispatch<CustomerActionTypes>
): Promise<any> => {
  try {
    const res = await api.get(`${API_ROOT}/customers/me`);
    if (typeof res === 'undefined' || !res) return;

    const customerData = res.data.data;

    dispatch({
      type: SET_MEMBER,
      payload: customerData
    });
    i18n.changeLanguage(customerData.preferred_language);
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const accountActivation = async (
  data: AccountActivation
): Promise<any> => {
  try {
    const response = await axios.post(`${API_ROOT}/activation`, data, options);

    return response.data;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const resetPasswordRequest = async (email: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_ROOT}/reset_password?email=${encodeURIComponent(email)}`,
      options
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const resetPassword = async (
  values: { [key: string]: string },
  token?: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_ROOT}/reset_password`,
      {
        token,
        ...values
      },
      options
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error
    );
  }
};

export const updateCustomer = async (
  dispatch: Dispatch<CustomerActionTypes>,
  data: FormikValues | any
): Promise<any> => {
  try {
    const res = await api.patch(`${API_ROOT}/customers`, data);
    if (typeof res === 'undefined' || !res) return;

    const customerData = res.data;

    dispatch({
      type: SET_MEMBER,
      payload: customerData.data
    });
    i18n.changeLanguage(customerData.preferred_language);
    return customerData;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const deleteCustomer = async (
  dispatch: Dispatch<CustomerActionTypes>,
  data: FormikValues | any
): Promise<any> => {
  try {
    const res = await api.post(`${API_ROOT}/activation/delete`, data);
    if (typeof res === 'undefined' || !res) return;

    const customerData = res.data;

    return customerData;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const getPaymentInfo = async (
  dispatch: Dispatch<CustomerActionTypes>
): Promise<any> => {
  try {
    const response = await api.get('/payment_info');
    if (typeof response === 'undefined' || !response) return;

    dispatch({
      type: UPDATE_PAYMENT_INFO,
      payload: response.data.data
    });
    return response.data;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const updatePaymentInfo = async (
  dispatch: Dispatch<CustomerActionTypes>,
  stripeToken: string
): Promise<any> => {
  try {
    const response = await api.patch('/payment_info', { stripeToken });
    dispatch({
      type: UPDATE_PAYMENT_INFO,
      payload: response.data.data
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const deletePaymentInfo = async (
  dispatch: Dispatch<CustomerActionTypes>
): Promise<any> => {
  try {
    const response = await api.delete('/payment_info');
    if (typeof response === 'undefined' || !response) return;

    dispatch({
      type: UPDATE_PAYMENT_INFO,
      payload: response.data.data
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const getPersonalQuotas = async (
  dispatch: Dispatch<CustomerActionTypes>
): Promise<any> => {
  try {
    const response = await api('/customers/quotas?plan_type=membership');
    if (typeof response === 'undefined' || !response) return;

    dispatch({
      type: SET_QUOTAS,
      payload: response.data.data
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const customerActivation = async (
  data: CustomerActivation
): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_ROOT}/activation/silent_member`,
      data,
      options
    );

    return response.data;
  } catch (err) {
    return Promise.reject(
      typeof err.response !== 'undefined' ? err.response.data : err
    );
  }
};

export const setUserFromLocaStorage = async (
  dispatch: Dispatch<CustomerActionTypes>
): Promise<void> => {
  if (!window.localStorage) return;

  const savedState = JSON.parse(localStorage.getItem('ruState')!);

  if (savedState && savedState.customer) {
    dispatch({
      type: SET_MEMBER,
      payload: savedState.customer
    });
  }
};
