import React from 'react';
import SignInForm from './SignInForm';
import AuthLinks from '../../components/AuthLinks';
import AuthAction from '../../components/AuthAction';

const SignIn = () => {
  return (
    <>
      <AuthAction />
      <SignInForm />
      <AuthLinks />
    </>
  );
};

export default SignIn;
