const header = {
  about: 'Über uns',
  connect: 'Connect',
  signin: 'Anmelden',
  experience: 'Experience',
  join: 'Beitreten',
  compete: 'Compete',
  followUs: 'Folgen',
  doBusiness: 'Partner',
  becomeACustomer: 'Mitglied werden',
  myProfile: 'Mein Profil',
  myAccount: 'Mein Account',
  changePassword: 'Passwort ändern',
  billing: 'Zahlungsmethoden',
  membership: 'Mitgliedschaft',
  notifications: 'Mitteilungen',
  dashboard: 'Dashboard',
  rides: 'Buchen',
  ranking: 'Rangliste',
  events: 'Events',
  championships: 'Championships',
  signOut: 'Abmelden'
};

export default header;
