import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import ConfirmationDialog, {
  IConfirmationDialogProps,
  IConfirmationDialogRef
} from './ConfirmationDialog';
import { ReactComponent as OkIcon } from '../../images/check-white.svg';

type TUseConfirmationDialogResult = {
  openDialog: (
    props?: Omit<IConfirmationDialogProps, 'onConfirm' | 'onCancel'>
  ) => Promise<boolean | null>;
  dialog: React.ReactNode;
};

const DEFAULT_PROPS: Omit<IConfirmationDialogProps, 'onConfirm' | 'onCancel'> =
  {
    title: 'confirmation.title',
    text: 'confirmation.text',
    okText: 'confirmation.ok',
    cancelText: 'confirmation.cancel',
    okIcon: <OkIcon />,
    type: 'default'
  };

export function useConfirmationDialog(): TUseConfirmationDialogResult {
  const dialogRef = useRef<IConfirmationDialogRef | null>(null);
  const [props, setProps] = useState<IConfirmationDialogProps>({
    ...DEFAULT_PROPS,
    onConfirm: () => closeDialog(),
    onCancel: () => closeDialog()
  });

  const closeDialog = () => dialogRef.current && dialogRef.current.close();

  const openDialog = useCallback(
    (props?: Omit<IConfirmationDialogProps, 'onConfirm' | 'onCancel'>) =>
      new Promise<boolean>((resolve) => {
        setProps(() => ({
          ...DEFAULT_PROPS,
          ...props,
          onConfirm: () => {
            resolve(true);
            closeDialog();
          },
          onCancel: () => {
            resolve(false);
            closeDialog();
          }
        }));

        dialogRef.current && dialogRef.current.open();
      }),
    []
  );

  return {
    openDialog,
    dialog: <ConfirmationDialog ref={dialogRef} {...props} />
  };
}
