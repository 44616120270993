import { RegisterOptions } from 'react-hook-form';
import { containsEmojis } from '../utils/contains-emoji.utils';

type TErrors = { invalid?: string };

export const emojisValidator: (error: TErrors) => RegisterOptions = ({
  invalid
}: TErrors) => ({
  validate: (value: string | undefined) => {
    if (invalid && value && containsEmojis(value)) {
      return invalid || 'Emojis are not allowed';
    }
  }
});
