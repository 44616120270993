import React from 'react';
import SignUpForm from './SignUpForm';
import AuthLinks from '../../components/AuthLinks';
import AuthAction from '../../components/AuthAction';

const SignUp = () => {
  return (
    <>
      <AuthAction isSignUp />
      <SignUpForm />
      <AuthLinks />
    </>
  );
};

export default SignUp;
