import React, { useEffect, useRef } from 'react';
import { getLapTime } from '../../store/track/track.actions';

interface RankingItem {
  index: number;
  current_customer: boolean;
  customer_profile_pic: string;
  best_lap: number;
  customer_fullname: string;
}

const RankingItem = ({
  index,
  current_customer,
  customer_profile_pic,
  customer_fullname,
  best_lap
}: RankingItem) => {
  const element = useRef(null);

  useEffect(() => {
    //@ts-ignore
    if (current_customer) element.current.scrollIntoView({ block: 'center' });
  }, [current_customer]);

  return (
    <li
      className={`Ranking-cells ${current_customer ? 'Current-customer' : ''}`}
      key={index}
      ref={element}
    >
      <span>{index + 1}</span>
      <span>
        <img src={customer_profile_pic} alt="" />
        {customer_fullname}
      </span>
      <span>{getLapTime(best_lap)}</span>
    </li>
  );
};

export default RankingItem;
