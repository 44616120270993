import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../images/ru-symbol-32px.svg';
import logoWhite from '../../images/racing-unleashed-mark-white.svg';

import './Copy.scss';

interface Copy {
  white?: boolean;
}

const Copy = ({ white }: Copy) => {
  const [t] = useTranslation();

  return (
    <div className={`Copy ${white ? 'alt' : ''}`}>
      <img src={white ? logoWhite : logo} alt="RU" /> &copy;
      {t('auth.sideInfo.copyright')}
    </div>
  );
};

export default Copy;
