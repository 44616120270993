import React from 'react';
import arrow from '../../../images/arrow-right.svg';

import './SliderBtn.scss';

interface Props {
  back?: boolean;
  disabled?: boolean;
}

const SliderBtn = ({ back, disabled }: Props) => {
  return (
    <span
      className={`SliderBtn ${back ? '__back' : ''} ${
        disabled ? '__disabled' : ''
      }`}
    >
      <img src={arrow} alt="" />
    </span>
  );
};

export default SliderBtn;
