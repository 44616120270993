import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';
import { Event } from '../../../store/events/events.types';
import { eventsSelector } from '../../../store/events/events.selector';
import useWithSelection from '../../../hooks/useWithSelection';

import './EventList.scss';

interface EventList {
  showDetails: boolean;
}

const langs: any = {
  de,
  en,
  es
};

const EventsList = ({ showDetails }: EventList) => {
  const events = useWithSelection(eventsSelector);
  const [t, i18n] = useTranslation();

  return (
    <div className={`EventList ${showDetails ? 'is-detailed' : ''}`}>
      {events.map((e: Event) => (
        <div className="EventList-item" key={e.id}>
          <div className="row">
            <div className="col-sm-8">
              <div className="EventsList-item-body">
                <span>
                  {format(new Date(e.start_time), 'MMM dd.yyyy', {
                    locale: langs[i18n.language]
                  })}{' '}
                  -{' '}
                  {format(new Date(e.end_time), 'MMM dd.yyyy @HH:mm', {
                    locale: langs[i18n.language]
                  })}
                </span>
                <h3>{e.title}</h3>
                <p>{e.description_excerpt}</p>
                <Link to={`/events/${e.id}`} className="arrow-link">
                  {t('events.readMore')}
                </Link>
              </div>
            </div>
            <div className="col-sm-4">
              <img src={e.featured_image_medium} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventsList;
