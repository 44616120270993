import React from 'react';
import { useTranslation } from 'react-i18next';
import DayPicker, { NavbarElementProps } from 'react-day-picker';
import { format, isBefore, startOfDay } from 'date-fns';
import { SetStateType } from '../../types/utils.types';
import { AvailableDate } from '../../store/booking/booking.types';
import de from '../../i18n/de/dates';
import en from '../../i18n/en/dates';
import es from '../../i18n/sp/dates';
import { ReactComponent as ArrowRight } from '../../images/arrow-current-color.svg';
import 'react-day-picker/lib/style.css';
import './Calendar.scss';
import { dateLocales } from '../../constants/dateLocales';

const langs: any = {
  de,
  en,
  es
};

interface Calendar {
  selectDay: (date: Date) => void;
  selectedDay: Date | undefined;
  selectedMonth?: Date;
  onMonthChange?: SetStateType<Date | undefined>;
  availableDates: AvailableDate[] | null;
}

const Calendar = ({
  selectDay,
  selectedDay,
  selectedMonth,
  onMonthChange,
  availableDates
}: Calendar) => {
  const [, i18n] = useTranslation();

  const handleDayClick = (day: any, { selected }: any) => {
    if (isBefore(startOfDay(day), startOfDay(new Date()))) return;
    selectDay(selected ? undefined : day);
  };

  const renderDay = (day: Date) => {
    let filteredDay = null;
    const formatedDay = format(day, 'yyyy-MM-dd');
    if (availableDates)
      filteredDay = availableDates.find((d) => d.date === formatedDay);

    return (
      <>
        <span className="Calendar-day">{day.getDate()}</span>
        <span
          className={`Calendar-day-status`}
          style={{
            background:
              (filteredDay && filteredDay.usage_color) || 'transparent'
          }}
        />
      </>
    );
  };

  return (
    <div className="Calendar">
      <DayPicker
        month={selectedMonth}
        selectedDays={selectedDay}
        showOutsideDays
        locale={i18n.language}
        weekdaysShort={langs[i18n.language].weekdaysShort}
        months={langs[i18n.language].months}
        onDayClick={handleDayClick}
        navbarElement={({
          month,
          onPreviousClick,
          onNextClick
        }: NavbarElementProps) => {
          return (
            <div className="navbar">
              <ArrowRight onClick={() => onPreviousClick()} />
              {format(month, 'MMMM yyyy', {
                locale: dateLocales[i18n.language as string]
              })}
              <ArrowRight onClick={() => onNextClick()} />
            </div>
          );
        }}
        renderDay={renderDay}
        onMonthChange={onMonthChange}
        disabledDays={{ before: new Date() }}
        firstDayOfWeek={1}
      />
    </div>
  );
};

export default Calendar;
