import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import { toastUtil } from '../../utils/toast.utils';
import useWithDispatch from '../../hooks/useWithDispatch';
import { updateCustomer } from '../../store/customer/customer.actions';
import { formModel, initialValues } from './formModel';
import FormField from '../../components/FormField';
import Button from '../../components/Button';
import validationSchema from './validationSchema';

const ChangePassword = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [t] = useTranslation();
  const updatePasswordAction = useWithDispatch(updateCustomer);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        const response = await updatePasswordAction(values);
        setSubmitting(false);
        toastUtil('info', response.meta.message);
      } catch (err) {
        setSubmitting(false);
        if (err.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (err && err.meta) toastUtil('error', err.meta.message);
      }
    }
  });

  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <div className="ChangePassword">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h2>{t('profile.updatePasswordTitle')}</h2>
          <form onSubmit={formik.handleSubmit}>
            {formModel.map(field => (
              <FormField
                key={field.name}
                i18nKey={field.i18nKey}
                type={field.type}
                errors={formik.errors}
                touched={formik.touched}
                setFieldValue={formik.setFieldValue}
                {...formik.getFieldProps(field.name)}
              />
            ))}
            <Button
              type="submit"
              disabled={submitting || !isValidForm}
              submitting={submitting}
              label={t('field.update')}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
