import React, { useRef } from 'react';
import styles from './Counter.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface ICounterProps {
  value: string | number | null;
  /**
   * @default 1
   */
  step?: number;
  label?: string;
  min?: number;
  max?: number;
  required?: boolean;
  className?: string;
  /**
   * @default false
   */
  allowFractional?: boolean;
  /**
   * @default 2
   */
  fractionDifigts?: number;
  disabled?: boolean;
  onChange: (value: string | number) => void;
  onBlur?: () => void;
}

const Counter: React.FC<ICounterProps> = ({
  label,
  value,
  min,
  max,
  required,
  className,
  disabled,
  allowFractional = false,
  fractionDifigts = 2,
  step = 1,
  onChange,
  onBlur
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [t] = useTranslation();

  const handleValue = (value: number | string | null | undefined) => {
    let val = +(value || '');
    val = allowFractional ? val : Math.floor(val);

    if (min !== undefined && val <= min) {
      return onChange(min);
    }

    if (max !== undefined && val >= max) {
      return onChange(max);
    }

    if (allowFractional && val.toString().includes('.')) {
      return onChange(+val.toFixed(fractionDifigts));
    }

    onChange(val);
  };

  const onButtonClick = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    value: number
  ) => {
    event.stopPropagation();

    if (disabled) return;

    handleValue(value);
  };

  return (
    <div
      className={classNames(
        styles.counterWrapper,
        { [styles.counterWrapper_disabled]: disabled },
        className
      )}
    >
      {label ? (
        <div
          className={classNames(styles.counterWrapper__label, {
            [styles.counterWrapper__label_required]: required
          })}
        >
          {t(label)}
        </div>
      ) : (
        ''
      )}
      <div
        className={styles.counter}
        onClick={() => inputRef && inputRef.current && inputRef.current.focus()}
      >
        <span
          className={styles.counter__minus}
          aria-disabled={disabled}
          onClick={(event) => onButtonClick(event, +(value || '') - step)}
        />
        <input
          ref={inputRef}
          disabled={disabled}
          className={styles.counter__input}
          type="text"
          value={value === null ? '' : value}
          onKeyDown={(event) => {
            const strValue = value !== null ? value.toString() : '';
            if (
              /[0-9]/.test(event.key) ||
              (event.key === '.' &&
                allowFractional &&
                strValue.length &&
                !strValue.includes('.')) ||
              ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(
                event.key
              )
            )
              return;

            event.preventDefault();
          }}
          onChange={({ target: { value } }) => onChange(value)}
          onBlur={() => {
            handleValue(value);
            onBlur && onBlur();
          }}
        />
        <span
          className={styles.counter__plus}
          aria-disabled={disabled}
          onClick={(event) => onButtonClick(event, +(value || '') + step)}
        />
      </div>
    </div>
  );
};

export default Counter;
