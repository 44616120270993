import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { throttle } from 'lodash';
import customerReducer from './customer/customer.reducer';
import membershipReducer from './membership/membership.reducer';
import loungeReducer from './lounge/lounge.reducer';
import codeReducer from './code/code.reducer';
import countryReducer from './country/country.reducer';
import notificationReducer from './notification/notification.reducer';
import trackReducer from './track/track.reducer';
import championshipsReducer from './championships/championships.reducer';
import eventsReducer from './events/events.reducer';
import { CustomerState } from './customer/customer.types';
import { LoungeState } from './lounge/lounge.types';
import { CodeState } from './code/code.types';
import { CountryState } from './country/country.types';
import { MembershipsState } from './membership/membership.types';
import { NotificationsState } from './notification/notification.types';
import { TracksState } from './track/track.types';
import { ChampionshipState } from './championships/championships.types';
import { EventState } from './events/events.types';
import { SimulatorState } from './simulator/simulator.types';
import simulatorReducer from './simulator/simulator.reducer';
import { DashboardState } from './dashboard/dashboard.types';
import dashboardReducer from './dashboard/dashboard.reducer';

export interface AppState {
  customer: CustomerState;
  memberships: MembershipsState;
  lounge: LoungeState;
  genderCodes: CodeState;
  countries: CountryState;
  loungeSimulators: SimulatorState;
  notifications: NotificationsState;
  tracks: TracksState;
  championships: ChampionshipState;
  events: EventState;
  dashboard: DashboardState;
}

const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('ruState', serializedState);
  } catch (error) {
    console.warn(error);
  }
};

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
  customer: customerReducer,
  memberships: membershipReducer,
  lounge: loungeReducer,
  genderCodes: codeReducer,
  countries: countryReducer,
  loungeSimulators: simulatorReducer,
  notifications: notificationReducer,
  tracks: trackReducer,
  championships: championshipsReducer,
  events: eventsReducer,
  dashboard: dashboardReducer
});

let middleware = null;

const apiEnv = process.env.REACT_APP_ENV;

apiEnv === 'production'
  ? (middleware = applyMiddleware())
  : (middleware = composeEnhancers());

const store = createStore(reducers, middleware);

store.subscribe(
  throttle(() => {
    saveState({
      customer: store.getState().customer.customer
    });
  }, 1000)
);

export default store;
