import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';
import { membershipsSelector } from '../../store/membership/membership.selectors';
import { BookingContext } from '../../providers/booking.provider';
import Button from '../Button';

import './MembershipWidget.scss';

const MembershipWidget = () => {
  const [t] = useTranslation();
  const { openSubscriptions } = useContext(BookingContext);
  const { membership_plan } = useWithSelection(customerSelector);
  const plans = useWithSelection(membershipsSelector);

  const clickHandler = () => {
    openSubscriptions(plans[0]);
  };

  return (
    <div className="dashboard-widget flex MembershipWidget ">
      {!plans.length && membership_plan && (
        <>
          <h3>{membership_plan.name}</h3>
          <p>{membership_plan.description}</p>
        </>
      )}
      {plans.length > 0 && (
        <>
          <h3>{plans[0].name}</h3>
          <p>{plans[0].description.substring(0, 60)}...</p>
          <p className="dashboard-widget-price">
            {plans[0].currency} {plans[0].price}
          </p>
          {plans[0].vat && plans[0].currency && (
            <p className="dashboard-widget-vat">
              <Trans
                i18nKey="booking.vatInfo"
                values={{ vat: plans[0].vat, currency: plans[0].currency }}
              >
                Prices are in {plans[0].currency} including {plans[0].vat}% Vat.
              </Trans>
            </p>
          )}
          <Button label={t('booking.getNow')} onClick={clickHandler} white />
        </>
      )}
    </div>
  );
};

export default MembershipWidget;
