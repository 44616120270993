import React, { InputHTMLAttributes, useRef } from 'react';
import styles from './AppInput.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactComponent as ClearIcon } from '../../images/cancel-current-color.svg';

interface IAppInputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputSize?: 'normal' | 'big';
  withClear?: boolean;
  onClear?: () => void;
}

const AppInput: React.FC<IAppInputProps> = ({
  placeholder,
  inputSize,
  withClear,
  onClear,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [t] = useTranslation();

  return (
    <div
      className={classNames(styles.appInput, {
        [styles.appInput_big]: inputSize === 'big'
      })}
      onClick={() => inputRef.current && inputRef.current.focus()}
    >
      <input
        {...props}
        ref={inputRef}
        className={styles.appInput__input}
        placeholder={placeholder ? t(placeholder) : undefined}
      />
      {withClear && props.value ? (
        <ClearIcon className={styles.appInput__clearIcon} onClick={onClear} />
      ) : (
        ''
      )}
    </div>
  );
};

export default AppInput;
