const membership = {
  title: 'Mi membresia',
  subtitle: 'Información de membresía actual. Actualizar, renovar y cancelar membresías.',
  current: {
    label: 'Membresía actual'
  },
  expiry: {
    label: 'Fecha de caducidad'
  },
  upgrade: 'Potenciar',
  selectPlan: 'Seleccionar',
  label: 'Elegir suscripción',
  details: 'Detalles de membresía',
  status: 'Estado actual de la membresía',
  ridesAvailable: 'Viajes disponibles',
  customerLimitLabel: 'Viajes mensuales utilizados',
  pricingLabel: 'Precio por viaje',
  renew: 'Renovar',
  formTitle: 'Renovar membresía',
  membershipExpiresAt: 'Tu membresía expira el ',
  renewC2a: 'Para renovar, por favor haz clic en el botón Renovar Membresía abajo',
  membershipExpiredAt: 'Tu membresía ha expirado:'
};

export default membership;