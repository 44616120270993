import { Dialog, DialogContent } from '@material-ui/core';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EditParticipantDialog.module.scss';
import React from 'react';
import ParticipantForm, {
  IEventParticipantDriverForm
} from '../../../../components/ParticipantForm/ParticipantForm';
import Button from '../../../../components/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as ArrowIcon } from '../../../../images/arrow-current-color.svg';
import { ReactComponent as CloseSvg } from '../../../../images/cancel-current-color.svg';
import { updateOrganiserParticipant } from '../../../../store/participant-drivers/participant-drivers.actions';
import { IParticipant } from '../../../../store/participant-drivers/participant-drivers.types';
import { toastUtil } from '../../../../utils/toast.utils';
import Loader from '../../../../components/Loader';

export interface IEditParticipantDialogRef {
  open: () => void;
  close: () => void;
  resetForm: (participant: IEventParticipantDriverForm) => void;
}

export interface IEditParticipantDialogProps {
  participant: IParticipant;
  reservation_data: string;
  onConfirm: (participant: IEventParticipantDriverForm) => void;
  onCancel: () => void;
}

const EditParticipantDialog = forwardRef<
  IEditParticipantDialogRef,
  IEditParticipantDialogProps
>(({ participant, reservation_data, onConfirm, onCancel }, ref) => {
  const [t] = useTranslation();
  const form = useForm<IEventParticipantDriverForm>({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      newsletter: false
    }
  });
  const {
    formState: { isValid },
    trigger,
    getValues
  } = form;
  const [open, setOpen] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
      resetForm: (participant: IEventParticipantDriverForm) =>
        form.reset(participant)
    }),
    [form]
  );

  const onSend = async () => {
    if (!(await trigger())) return;

    setSending(true);

    try {
      const newParticipant = await updateOrganiserParticipant(
        reservation_data,
        { ...participant, ...getValues() }
      );
      onConfirm(newParticipant);
      toastUtil('success', t('eventParticipantDriver.participantUpdated'));
    } catch (e) {
      const err = e as any;
      if (!err) return;

      let msg: string = '';

      if (err.meta && typeof err.meta.message === 'object') {
        const error = Object.entries(
          err.meta.message as { [key: string]: string }
        )[0];
        msg = `${error[0]} ${error[1]}` || '';
      } else {
        msg = err && err.meta ? err.meta.message : err.message;
      }

      if (msg) {
        toastUtil('error', msg);
      }
    }

    setSending(false);
  };

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      onBackdropClick={() => {
        setOpen(false);
        onCancel();
      }}
    >
      <DialogContent>
        <button className={styles.dialog__close} onClick={() => setOpen(false)}>
          <CloseSvg />
        </button>
        <div className={styles.content}>
          <FormProvider {...form}>
            <ParticipantForm organiserView />
          </FormProvider>

          <Button
            primaryDark
            label={t('eventParticipantDriver.send')}
            disabled={!isValid || sending}
            icon={<ArrowIcon />}
            onClick={onSend}
          />
          {sending ? (
            <Loader fullSize color="black" width={80} height={80} />
          ) : (
            ''
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
});

export default EditParticipantDialog;
