const profile = {
  connections: 'Social Networks',
  connectionsSubtitles:
    'Conéctate con nosotros en tus redes sociales, y comparte tus clasificaciones, experiencias y más.',
  profileDetails: 'Detalles perfil',
  deleteCustomer: 'Eliminar perfil',
  deleteTitle: '¿Estás seguro?',
  deleteSubtitle: '¿Estás seguro de que quieres eliminar tu cuenta? Esta acción es irreversible y perderás todos tus datos y el contenido relacionado con esta cuenta.',
  yesConfirm: 'Eliminar',
  profileUpdateDetails: 'Actualización de perfil',
  profileUpdateInfo: 'Recientemente cambiamos nuestro sistema de plataforma, en este sentido le rogamos que actualice su perfil con los detalles que faltan a continuación.',
  updatePasswordTitle: 'Cambiar contraseña'
};

export default profile;
