import React, { useState, useMemo, FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import BookingForm from '../components/BookingForm';
import SubscriptionForm from '../components/SubscriptionForm';
import { MembershipPlan } from '../store/membership/membership.types';
import { TimeSlot } from '../store/booking/booking.types';
import CloseDialog from '../components/CloseDialog';
import { publish } from '../events';

interface BookingCtxValues {
  openBookingForm: (timeSlot?: TimeSlot | null, groupRide?: boolean) => void;
  closeBookingForm: () => void;
  openSubscriptions: (plan: MembershipPlan | null, isRenew?: boolean) => void;
  closeSubscriptions: () => void;
  selectedPlan: MembershipPlan | null;
  selectedTimeSlot: TimeSlot | null | undefined;
  groupRide: boolean;
  renewMembership: boolean;
}

export const BookingContext = React.createContext<BookingCtxValues>({
  openBookingForm: () => { },
  closeBookingForm: () => { },
  openSubscriptions: () => { },
  closeSubscriptions: () => { },
  selectedPlan: null,
  renewMembership: false,
  selectedTimeSlot: null,
  groupRide: false
});

const BookingProvider: FunctionComponent = ({ children }) => {
  const [isOpenBooking, setIsOpenBooking] = useState<boolean>(false);
  const [isOpenSubscriptions, setIsOpenSubscriptions] = useState<boolean>(
    false
  );
  const [selectedPlan, setSelectedPlan] = useState<MembershipPlan | null>(null);
  const [renewMembership, setRenewMembership] = useState<boolean>(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<
    TimeSlot | null | undefined
  >(null);
  const [groupRide, setGroupRide] = useState<boolean>(true);

  const values = useMemo(
    () => ({
      openBookingForm: (timeSlot?: TimeSlot | null, groupRide?: boolean) => {
        setSelectedTimeSlot(timeSlot ? timeSlot : null);
        setGroupRide(groupRide ? true : false);
        setIsOpenBooking(true);
        if (timeSlot) {
          let quantity = groupRide ? 2 : 1;
          publish('InitiateCheckout', {
            content_category: 'Time Slot',
            content_ids: [timeSlot.id],
            contents: [{
              id: timeSlot.id,
              quantity: quantity,
              name: timeSlot.name
            }],
            num_items: 1,
            currency: timeSlot.currency,
            value: parseFloat(timeSlot.price.toString()) * quantity
          });
        } else {
          publish('InitiateCheckout', {
            content_category: 'Time Slot',
            content_ids: [],
            contents: [],
            num_items: 0,
            currency: '',
            value: ''
          });
        }
      },
      closeBookingForm: () => {
        setIsOpenBooking(false);
        setSelectedTimeSlot(null);
        setGroupRide(false);
      },
      openSubscriptions: (
        selectedPlan: MembershipPlan | null,
        isRenew?: boolean
      ) => {
        if (selectedPlan) {
          setSelectedPlan(selectedPlan);
          publish('InitiateCheckout', {
            content_category: 'Membership Plan',
            content_ids: [selectedPlan.id],
            contents: [{
              id: selectedPlan.id,
              quantity: 1,
              name: selectedPlan.name
            }],
            num_items: 1,
            currency: selectedPlan.currency,
            value: selectedPlan.price
          });
        } if (isRenew) setRenewMembership(true);
        setIsOpenSubscriptions(true);
      },
      closeSubscriptions: () => {
        setIsOpenSubscriptions(false);
        setRenewMembership(false);
        setSelectedPlan(null);
      }
    }),
    []
  );

  return (
    <BookingContext.Provider
      value={{
        ...values,
        selectedPlan,
        selectedTimeSlot,
        groupRide,
        renewMembership
      }}
    >
      {children}
      <Dialog
        onClose={values.closeBookingForm}
        open={isOpenBooking}
        className="ru-dialog"
      >
        <BookingForm />
        <CloseDialog onClick={values.closeBookingForm} />
      </Dialog>
      <Dialog
        onClose={values.closeSubscriptions}
        open={isOpenSubscriptions}
        className="ru-dialog"
      >
        <SubscriptionForm />
        <CloseDialog onClick={values.closeSubscriptions} />
      </Dialog>
    </BookingContext.Provider>
  );
};

export default BookingProvider;
