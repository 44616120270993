const auth = {
  sideInfo: {
    title: 'Be a part of the RU Community!',
    p1:
      'Compare lap times with fellow racers or even F1 drivers and view circuit statistics to get the most out of your performance.',
    p5: 'As a member you can:',
    p2: 'Benefit from a 10% discount on every ride.',

    p3:
      'Book your rides in the Racing Lounge of your choice and your place in our monthly Championship comfortably via member platform or app.',
    p4:
      'Stay up to date at all times regarding new features, events and much more.',
    copyright: 'Racing Unleashed AG. All rights reserved.'
  },
  links: {
    tos: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    security: 'Security'
  },
  authAction: {
    signUp: "Haven't got an account yet?",
    signIn: 'Already have an account?',
    btnSignIn: 'Sign in',
    btnSignUp: 'Sign up'
  },
  signUp: {
    title: 'Sign Up for Membership',
    titleSilentMember: 'Complete the form to activate',
    subtitle: 'Become a member. It’s free.',
    confirmMembership: 'Confirm Membership',
    confirmText:
      'To unleash your racing experience confirm the link sent to <1> {{memberEmail}} </1> email.'
  },
  signIn: {
    title: 'Sign In to Members Area',
    subtitle: 'Enter your account details.'
  },
  forgotPassword: {
    title: 'Forgot your password?',
    subtitle: "We'll help you reset it and get back on track.",
    linkToPage: 'Forgot your password?'
  },
  resetPassword: {
    title: 'Create your new Password',
    subtitle: 'Ensure your new password has at least 8 characters'
  },
  backToSignIn: 'Back to sign in',
  unleashNow: 'Unleash now',
  welcomeText: 'Welcomes you to the Ultimate Experience'
};

export default auth;
