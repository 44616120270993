const ranking = {
  ranking: 'Rangliste',
  rank: 'Rang',
  racer: 'Fahrer',
  lapTime: 'Zeit',
  noItems: 'Keine Einträge.',
  selectTrack: 'Rennstrecke wählen.',
  viewAll: 'Alle Anzeigen'
};

export default ranking;
