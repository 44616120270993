const profile = {
  connections: 'Connections',
  connectionsSubtitles:
    'Connect your favourite social media services to share new posts and rankings with your friends.',
  profileDetails: 'Profile Details',
  deleteCustomer: 'Delete Profile',
  deleteTitle: 'Are you sure?',
  deleteSubtitle: 'Are you sure you want to delete your account? This action is irreversible and you will lose all your data and content related to this account.',
  yesConfirm: 'Delete',
  profileUpdateDetails: 'Profile Update',
  profileUpdateInfo: 'We recently changed our platform system, in this regards we kindly ask you to please update your profile with the missing details below.',
  updatePasswordTitle: 'Update Password'
};

export default profile;
