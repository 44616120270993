import { SetCustomerAction } from '../customer/customer.types';

export const GET_MEMBERSHIPS_PLANS = 'GET_MEMBERSHIPS_PLANS';
export const UPDATE_MEMBERSHIPS = 'UPDATE_MEMBERSHIPS';

export interface MembershipPlan {
  id: string;
  name: string;
  description: string;
  price?: string;
  currency?: string;
  level?: string;
  upgradeable?: boolean;
  invoiced_at?: 'string';
  expires_at?: string;
  base_price?: string;
  paid_price?: string;
  min_renewal_days?: string;
  renew_available?: boolean;
  renewal_price?: string;
  shareable?: boolean; 
  vat?: string;
}

export interface MembershipsState {
  plans: MembershipPlan[] | null;
}

interface GetMembershipPlansAction {
  type: typeof GET_MEMBERSHIPS_PLANS;
  payload: MembershipPlan[];
}

export type MembershipActionTypes = GetMembershipPlansAction | SetCustomerAction;
