import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { StripeProvider } from 'react-stripe-elements';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import store from './store';
import './styles/base.scss';
import './styles/image-viewer.scss';

import './config/api.config';
import { stripeKey } from './config/api.config';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider store={store}>
    <StripeProvider apiKey={stripeKey}>
      <App />
    </StripeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
