export default [
  {
    i18nKey: 'header.myProfile',
    to: '/profile'
  },
  {
    i18nKey: 'header.changePassword',
    to: '/change-password'
  },
  {
    i18nKey: 'header.billing',
    to: '/billing'
  },
  {
    i18nKey: 'header.membership',
    to: '/membership'
  },
  {
    i18nKey: 'header.notifications',
    to: '/notifications'
  }
];
