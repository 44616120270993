import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import { resetPassword } from '../../../store/customer/customer.actions';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button';
import validationSchema from './validationSchema';
import { formModel, initialValues } from './formModel';

const ForgotPasswordForm = () => {
  const { token } = useParams();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [t] = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        const response = await resetPassword(values, token);
        setSuccessMsg(response.meta.message);
      } catch (err) {
        setSubmitting(false);
        if (err.meta.message)
          formMethods.setFieldError('password_confirmation', err.meta.message);
        if (err.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
      }
    }
  });
  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <div className="ResetPasswordForm Auth-form">
      {successMsg ? (
        <p>
          {successMsg} <Link to="/auth/sign-in">{t('auth.backToSignIn')}</Link>
        </p>
      ) : (
        <div>
          <h2>{t('auth.resetPassword.title')}</h2>
          <h4>{t('auth.resetPassword.subtitle')}</h4>
          <form onSubmit={formik.handleSubmit}>
            {formModel.map(field => (
              <FormField
                key={field.name}
                i18nKey={field.i18nKey}
                type={field.type}
                errors={formik.errors}
                touched={formik.touched}
                setFieldValue={formik.setFieldValue}
                {...formik.getFieldProps(field.name)}
              />
            ))}
            <div className="Auth-form-footer">
              <Button
                type="submit"
                disabled={submitting || !isValidForm}
                submitting={submitting}
                label={t('field.cresetPassword')}
              />
              <Link to="/auth/sign-in">{t('auth.backToSignIn')}</Link>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
