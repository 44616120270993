import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingContext } from '../../providers/booking.provider';
import { getTimeSlots } from '../../store/booking/booking.actions';
import Button from '../Button';
import TimeSlots from '../TimeSlots';
import { TimeSlot } from '../../store/booking/booking.types';
import './BookingWidget.scss';

interface Props {
  isGroupRide?: boolean;
}

const BookingWidget = ({ isGroupRide }: Props) => {
  const [t] = useTranslation();
  const { openBookingForm } = useContext(BookingContext);
  const [timeSlot, setTimeSlot] = useState<TimeSlot | null>(null);
  // eslint-disable-next-line
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getTimeSlots(isGroupRide);
        setTimeSlots(response);
      } catch (err) {}
    })();
    // eslint-disable-next-line
  }, []);

  const clickHandler = () => {
    openBookingForm(timeSlot, !!isGroupRide);
  };

  return (
    <div className="BookingWidget dashboard-widget flex">
      <h3>{t(`booking.${isGroupRide ? 'groupTitle' : 'title'}`)}</h3>
      <p className="dashboard-widget-text">
        {t(`booking.widgetText${isGroupRide ? 'Group' : ''}`)}
      </p>
      <TimeSlots
        selected={timeSlot}
        selectSlot={setTimeSlot}
        timeSlots={timeSlots}
      />
      <Button label={t('booking.pageBtnLabel')} onClick={clickHandler} />
    </div>
  );
};

export default BookingWidget;
