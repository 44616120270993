import React from 'react';
import Select from '../FormField/Select';
import useWithSelection from '../../hooks/useWithSelection';
import { loungeSelector } from '../../store/lounge/lounge.selectors';
import { SetStateType } from '../../types/utils.types';

interface LoungeSelect {
  selected: string;
  setCenter: SetStateType<string>;
}

const Lounge = React.memo(({ selected, setCenter }: LoungeSelect) => {
  const lounges = useWithSelection(loungeSelector);
  const setFieldValue = (name: string, value: string) => {
    setCenter(value);
  };

  return (
    <div className="Lounge">
      <Select
        i18nKey="field.lounge_id"
        name="base-center"
        value={selected}
        list={lounges}
        setFieldValue={setFieldValue}
      />
    </div>
  );
});

export default Lounge;
