import { Dispatch } from 'react';
import api from '../../config/api.config';

import {
  GET_MEMBERSHIPS_PLANS,
  MembershipActionTypes,
  MembershipPlan
} from './membership.types';
import { SET_MEMBER } from '../customer/customer.types';

export const getMembershipPlans = async (
  dispatch: Dispatch<MembershipActionTypes>
): Promise<any> => {
  try {
    const response = await api.get('/memberships');
    if (typeof response === "undefined" || !response) return;
    
    dispatch({
      type: GET_MEMBERSHIPS_PLANS,
      payload: response.data.data
    });
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};

export const postMembershipConvert = async (data: object): Promise<any> => {
  try {
    const response = await api.post('/memberships/convert', { cookies: document.cookie, data: data });
    if (typeof response === "undefined" || !response) return;
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};

export const updateMembership = async (
  dispatch: Dispatch<MembershipActionTypes>,
  plan: MembershipPlan,
  card_token: string,
  save_card?: boolean
): Promise<any> => {
  try {
    const customer = await api.patch('/membership', {
      plan: plan.id,
      card_token,
      save_card
    });
    dispatch({
      type: SET_MEMBER,
      payload: customer.data.data
    });

    await getMembershipPlans(dispatch);

    return customer.data;
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};

export const renewMembershipAction = async (
  dispatch: Dispatch<MembershipActionTypes>,
  card_token: string,
  save_card?: boolean
): Promise<any> => {
  try {
    const customer = await api.patch('/membership/renew', {
      card_token,
      save_card
    });
    dispatch({
      type: SET_MEMBER,
      payload: customer.data.data
    });

    await getMembershipPlans(dispatch);

    return customer.data;
  } catch (error) {
    return Promise.reject(typeof error.response !== "undefined" ? error.response.data : error);
  }
};
