import React, {useState, ChangeEvent} from 'react';
import { useTranslation } from 'react-i18next';
import { SetStateType } from '../../types/utils.types';
import cancelIcon from '../../images/cancel.svg';

import Button from '../Button';

interface Props {
    applyCoupon: SetStateType<string | null>;
    coupon: string | null;
}

const CouponForm = ({ applyCoupon, coupon }: Props) => {
    
    const [value, setValue] = useState<string>(coupon ? coupon : '');

    const [t] = useTranslation();

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }

    const clickHandler = () => {
        applyCoupon(value);
    }

    const onDelete = () => {
        setValue('');
        applyCoupon(null);
    }
    return(
        <div className='coupon-form'>
            <input
                onChange={changeHandler}
                value={value}
                disabled={!!coupon}
                placeholder={t('booking.enterCouponCode')}
            />
            <Button
                label={t(`booking.${coupon ? 'couponApplied' : 'couponApply'}`)}
                small
                secondary disabled={!!coupon}
                onClick={clickHandler}
            />
            {
                coupon && <img src={cancelIcon} alt='Remove Coupon' onClick={ onDelete }/>  
            }
        </div>
    )
}

export default CouponForm;