import { Booking } from '../../../store/booking/booking.types';
import { IEventBookForm } from '../EventBooking';

export enum ECustomerType {
  company = 'company',
  private = 'private'
}

const defaultValues: IEventBookForm = {
  id: '',
  participantsAmount: 5,
  loungeId: '',
  durationId: '',
  duration: 0,
  date: null,
  time: '',
  timeSlotId: '',
  foodId: '',
  programId: '',
  selectedExtras: [],
  firstLine: '',
  secondLine: '',
  carBranding: null,
  trackBranding: null,
  organiserFirstName: '',
  organiserLastName: '',
  organiserEmail: '',
  organizerPhoneNumber: '',
  companyName: '',
  companyAddress1: '',
  companyAddress2: '',
  companyCity: '',
  companyRegion: '',
  companyCountryId: '',
  companyTax: '',
  companyZipCode: '',
  processignPersonalData: false,
  getUpdates: false,
  processingBillingData: false,
  isSpecialRequest: false,
  specialRequest: '',
  termsRead: false,
  privacyRead: false,
  simulatorsAmount: 1,
  coupon: '',
  additionalRequest: '',
  customerType: ECustomerType.company
};

export function generateEventForm(event?: Booking): IEventBookForm {
  if (!event) return defaultValues;

  return {
    ...defaultValues,
    id: event.id || '',
    participantsAmount:
      (event.event_information && event.event_information.ParticipantsCount) ||
      0,
    loungeId: event.lounge_id || '',
    durationId: event.time_slot ? event.time_slot.product_id : '',
    duration: event.duration_in_hours,
    date: event.start_time ? new Date(event.start_time) : null,
    time: event.start_time || '',
    timeSlotId: event.time_slot.id || '',
    foodId:
      (event.event_information && event.event_information.CateringProductID) ||
      '',
    programId:
      (event.event_information && event.event_information.FormatProductID) ||
      '',
    selectedExtras: event.event_information
      ? [
          event.event_information.TrophiesProductID || '',
          event.event_information.CarBrandingProductID || '',
          event.event_information.TrackBrandingProductID || ''
        ]
      : [],
    firstLine:
      (event.event_information && event.event_information.TrophiesFirstLine) ||
      '',
    secondLine:
      (event.event_information && event.event_information.TrophiesSecondLine) ||
      '',
    carBranding: event.car_branding_logo || null,
    trackBranding: event.track_branding_logo || null,
    organiserFirstName:
      (event.event_information &&
        event.event_information.Organiser &&
        event.event_information.Organiser.FirstName) ||
      '',
    organiserLastName:
      (event.event_information &&
        event.event_information.Organiser &&
        event.event_information.Organiser.LastName) ||
      '',
    organiserEmail:
      (event.event_information &&
        event.event_information.Organiser &&
        event.event_information.Organiser.Email) ||
      '',
    organizerPhoneNumber:
      (event.event_information &&
        event.event_information.Organiser &&
        event.event_information.Organiser.PhoneNumber) ||
      '',
    companyName:
      (event.event_information &&
        event.event_information.BillingData &&
        event.event_information.BillingData.CompanyName) ||
      '',
    companyAddress1:
      (event.event_information &&
        event.event_information.BillingData &&
        event.event_information.BillingData.AddressLine1) ||
      '',
    companyAddress2:
      (event.event_information &&
        event.event_information.BillingData &&
        event.event_information.BillingData.AddressLine2) ||
      '',
    companyCity:
      (event.event_information &&
        event.event_information.BillingData &&
        event.event_information.BillingData.City) ||
      '',
    companyRegion:
      (event.event_information &&
        event.event_information.BillingData &&
        event.event_information.BillingData.Region) ||
      '',
    companyCountryId:
      (event.event_information &&
        event.event_information.BillingData &&
        event.event_information.BillingData.CountryID) ||
      '',
    companyZipCode:
      (event.event_information &&
        event.event_information.BillingData &&
        event.event_information.BillingData.ZipCode) ||
      '',
    companyTax:
      (event.event_information &&
        event.event_information.BillingData &&
        event.event_information.BillingData.TaxNumber) ||
      '',
    getUpdates:
      (event.event_information &&
        event.event_information.Organiser &&
        !!+event.event_information.Organiser.Subscribe) ||
      false,
    isSpecialRequest: !!(
      event.event_information && event.event_information.CateringSpecialRequest
    ),
    specialRequest:
      (event.event_information &&
        event.event_information.CateringSpecialRequest) ||
      '',
    simulatorsAmount: event.lounge.available_simulators,
    customerType:
      event.event_information &&
      event.event_information.BillingData &&
      !!+event.event_information.BillingData.IsCompany
        ? ECustomerType.company
        : ECustomerType.private,
    termsRead: false,
    privacyRead: false
  };
}
