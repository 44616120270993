import React from 'react';
import styles from './BaseCard.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRight } from '../../../images/arrow-current-color.svg';
import Button from '../../Button';
import classNames from 'classnames';
import Loader from '../../Loader';

export interface IBaseCardProps {
  title: string;
  collapsed: boolean;
  forwardTitle: string;
  collapseData?: React.ReactNode | string | number;
  subtitle?: string;
  /**
   * @default true
   */
  displayBack?: boolean;
  /**
   * @default true
   */
  displayForward?: boolean;
  /**
   * @default false
   */
  invalid?: boolean;
  /**
   * @default false
   */
  isLoading?: boolean;
  children?: React.ReactNode;
  onBack?: () => void;
  onForward?: () => void;
  onClick?: () => void;
  revalidateStep?: () => void;
}

const BaseCard: React.FC<IBaseCardProps> = ({
  title,
  subtitle,
  collapsed,
  collapseData,
  forwardTitle,
  displayBack = true,
  displayForward = true,
  invalid = false,
  isLoading = false,
  onBack,
  onForward,
  onClick,
  children
}) => {
  const [t] = useTranslation();

  return (
    <div
      className={classNames(styles.basicCard, {
        [styles.basicCard_collapsed]: collapsed
      })}
      onClick={collapsed ? onClick : undefined}
    >
      <div className={styles.basicCard__header}>
        <h3
          className={classNames(styles.basicCard__title, {
            [styles.basicCard__title_collapsed]: collapsed,
            [styles.basicCard__title_invalid]: invalid
          })}
        >
          {t(title)} {collapsed ? <span>{collapseData}</span> : ''}
        </h3>
        {subtitle && !collapsed ? (
          <p className={styles.basicCard__subtitle}>{t(subtitle)}</p>
        ) : (
          ''
        )}
      </div>
      <div
        className={classNames(styles.basicCard__content, {
          [styles.basicCard__content_collapsed]: collapsed
        })}
      >
        {children}
      </div>
      {collapsed ? (
        ''
      ) : (
        <div className={styles.basicCard__footer}>
          {displayBack ? (
            <Button
              small
              outline
              className={styles.basicCard__backBtn}
              label={t('booking.steps.back')}
              icon={<ArrowRight className={styles.basicCard__arrowLeft} />}
              onClick={onBack}
              disabled={!onBack}
            />
          ) : (
            ''
          )}
          {displayForward ? (
            <Button
              noShadow
              small
              className={styles.basicCard__forwardBtn}
              label={t(forwardTitle)}
              icon={<ArrowRight />}
              onClick={onForward}
              disabled={!onForward}
            />
          ) : (
            ''
          )}
        </div>
      )}
      {isLoading ? (
        <Loader fullSize color="black" width={80} height={80} />
      ) : (
        ''
      )}
    </div>
  );
};

export default BaseCard;
