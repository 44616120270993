import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import { resetPasswordRequest } from '../../../store/customer/customer.actions';
import Button from '../../../components/Button';
import FormField from '../../../components/FormField';
import validationSchema from './validationSchema';

const ForgotPasswordForm = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [t] = useTranslation();
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: async (
      values,
      formMethods: FormikHelpers<FormikValues>
    ): Promise<any> => {
      try {
        setSubmitting(true);
        const response = await resetPasswordRequest(values.email);
        setSuccessMsg(response.meta.message);
      } catch (err) {
        setSubmitting(false);
        if (err.meta.message)
          formMethods.setFieldError('email', err.meta.message);
        if (err.meta.errors) {
          err.meta.errors.forEach((e: any) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
      }
    }
  });
  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <div className="ForgotPasswordForm Auth-form">
      <h2>{t('auth.forgotPassword.title')}</h2>
      <h4>{t('auth.forgotPassword.subtitle')}</h4>
      {successMsg ? (
        <p>{successMsg}</p>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <FormField
            key="email"
            i18nKey="field.email"
            type="text"
            errors={formik.errors}
            touched={formik.touched}
            setFieldValue={formik.setFieldValue}
            {...formik.getFieldProps('email')}
          />
          <div className="Auth-form-footer">
            <Button
              type="submit"
              disabled={submitting || !isValidForm}
              submitting={submitting}
              label={t('field.forgotPassword')}
            />
            <Link to="/auth/sign-in">{t('auth.backToSignIn')}</Link>
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
