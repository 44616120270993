import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toastUtil } from '../../utils/toast.utils';
import Summary from '../Summary';
import DialogSuccess from '../DialogSuccess';
import CardForm from '../CardForm';
import PaymentDetails from '../PaymentDetails';
import { BookingContext } from '../../providers/booking.provider';
import { MembershipPlan } from '../../store/membership/membership.types';
import { loungeSelector } from '../../store/lounge/lounge.selectors';
import { Lounge } from '../../store/lounge/lounge.types';
import {
  updateMembership,
  renewMembershipAction
} from '../../store/membership/membership.actions';
import useWithDispatch from '../../hooks/useWithDispatch';
import useWithSelection from '../../hooks/useWithSelection';
import { paymentInfoSelector } from '../../store/customer/customer.selectors';
import { customerSelector } from '../../store/customer/customer.selectors';
import PlansDropdown from './PlansDropdown';
import './SubscriptionForm.scss';
import { publish } from '../../events';

const SubscriptionForm = () => {
  const [t] = useTranslation();
  const paymentInfo = useWithSelection(paymentInfoSelector);
  const { membership_plan } = useWithSelection(customerSelector);
  const lounges = useWithSelection(loungeSelector);
  const { default_lounge_id } = useWithSelection(customerSelector);
  const { selectedPlan, closeSubscriptions, renewMembership } =
    useContext(BookingContext);
  const [selected, setSelected] = useState<MembershipPlan | null>(
    renewMembership ? membership_plan : selectedPlan
  );
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const updatePlan = useWithDispatch(updateMembership);
  const renewPlan = useWithDispatch(renewMembershipAction);

  const nextStep = () => {
    if (lounges.find((b: Lounge) => b.id === default_lounge_id).accepts_cash_only) {
      upgradePlan({
        card_token: '',
        save_card: false
      });
    } else {
      paymentInfo && !paymentInfo.card_number_fragment ? setStep(2) : setStep(1);
    }
  };

  const showCardForm = () => {
    setStep(2);
  };

  const upgradePlan = async ({
    card_token,
    save_card
  }: {
    card_token: string;
    save_card: boolean;
  }) => {
    try {
      setSubmitting(true);
      let response = renewMembership
        ? await renewPlan(card_token, save_card)
        : await updatePlan(selected, card_token, save_card);
      setSuccessMsg(response.meta.message);
      setStep(3);
      if (selected) {
        publish('Purchase', {
          content_category: 'Membership Plan',
          content_ids: [selected.id],
          contents: [{
            id: selected.id,
            quantity: 1,
            name: selected.description
          }],
          num_items: 1,
          currency: selected.currency,
          value: selected.price
        });
      }
    } catch (err) {
      if (err && err.meta) toastUtil('error', err.meta.message);
      setSubmitting(false);
    }
  };

  return (
    <div className={`SubscriptionForm ${step !== 0 ? 'on-success' : ''}`}>
      {step === 0 && (
        <>
          <div>
            <h2>
              {t(
                renewMembership
                  ? 'membership.formTitle'
                  : 'booking.choseMembershipPackageTitle'
              )}
            </h2>
            <h4>{t('booking.choseMembershipPackageSubtitle')}</h4>
            <PlansDropdown setPlan={setSelected} selected={selected} />
          </div>
          <Summary
            buttonLabel={t('booking.payNow')}
            onClick={nextStep}
            totalPrice={
              selected &&
              (selected.renewal_price
                ? +selected.renewal_price!
                : +selected.price!)
            }
            disabled={!selected}
            hasSummary={typeof selected != "undefined"}
            currency={selected && selected.currency}
            vat={selected && selected.vat}
          >
            {selected && (
              <>
                <div>
                  <span>{t('booking.package')}</span>
                  <span>{selected.name}</span>
                </div>
                <div className="description">
                  <span>{t('booking.description')}</span>
                  <span>{selected.description}</span>
                </div>
              </>
            )}
          </Summary>
        </>
      )}
      {step === 1 && (
        <PaymentDetails
          showCardForm={showCardForm}
          onPay={upgradePlan}
          isSubmitting={submitting}
        />
      )}
      {step === 2 && <CardForm newDetails onSubmitCallback={upgradePlan} />}
      {step === 3 && (
        <DialogSuccess
          onClick={closeSubscriptions}
          title={t('booking.subscriptionSuccessTitle')}
          body={successMsg!}
          label={t('booking.close')}
        />
      )}
    </div>
  );
};

export default SubscriptionForm;
