import React, { useMemo, useState } from 'react';
import styles from './EventSummary.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { IEventBookForm } from '../../EventBooking';
import { Lounge, Supervisor } from '../../../../store/lounge/lounge.types';
import useWithSelection from '../../../../hooks/useWithSelection';
import { loungeSelector } from '../../../../store/lounge/lounge.selectors';
import Button from '../../../../components/Button';
import { ReactComponent as EditIcon } from '../../../../images/edit.svg';
import { ReactComponent as CheckIcon } from '../../../../images/check-white.svg';
import oneStapAwayImg from '../../../../images/booking-summary/one-step-away.png';
import AppFormField from '../../../../components/AppFormField/AppFormField';
import AppTextarea from '../../../../components/AppTextarea';
import AppInput from '../../../../components/AppInput';
import CheckboxRadio from '../../../../components/FormField/CheckboxRadio';
import {
  EEventProductType,
  EventProducts
} from '../../../../store/eventProducts/eventProducts.types';
import Loader from '../../../../components/Loader';
import { add, format, parse } from 'date-fns';
import { timeFromIsoDate } from '../../../../utils/time-from-iso-date.utils';
import { getFormError } from '../../../../utils/get-form-error.utils';
import { emojisValidator } from '../../../../validators/emojis.validator';
import { removeEmojis } from '../../../../utils/remove-emoji.utils';
import { dateLocales } from '../../../../constants/dateLocales';
import i18n from '../../../../i18n';

interface IEventSummaryProps {
  supervisor: Supervisor | null;
  isPriceLoading: boolean;
  summary: React.ReactNode;
  eventOptions: EventProducts;
  toggleSummary: () => void;
  onSubmit: () => void;
}

const EventSummary: React.FC<IEventSummaryProps> = ({
  supervisor,
  isPriceLoading,
  summary,
  eventOptions,
  toggleSummary,
  onSubmit
}) => {
  const [t] = useTranslation();
  const lounges: Lounge[] = useWithSelection(loungeSelector);
  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext<IEventBookForm>();
  const [coupon, setCoupon] = useState<string>('');
  const [privacyRead, setPrivacyRead] = useState<boolean>(false);
  const [termsRead, setTermsRead] = useState<boolean>(false);

  const {
    participantsAmount,
    loungeId,
    foodId,
    programId,
    selectedExtras,
    time,
    duration
  } = getValues();

  const eventSummary = useMemo(() => {
    const lounge = lounges.find(({ id }) => id === loungeId);
    const loungeAddress = lounge
      ? [lounge.address1, lounge.zip_code, lounge.city, lounge.country]
          .filter(Boolean)
          .join(', ')
      : '';
    const food = eventOptions[EEventProductType.eventCatering].find(
      ({ id }) => id === foodId
    );
    const program = eventOptions[EEventProductType.eventStructure].find(
      ({ id }) => id === programId
    );
    const startTime = time ? timeFromIsoDate(time) : '';
    const dateTime = time
      ? `${format(new Date(time), 'dd MMMM yyy', {
          locale: dateLocales[i18n.language as string]
        })}, ${timeFromIsoDate(time)} - ${timeFromIsoDate(
          add(parse(startTime ? startTime[0] : '', 'HH:mm', new Date(time)), {
            minutes: duration * 60
          }).toString()
        )}`
      : '';

    const extra = selectedExtras
      .map((id) => {
        switch (id) {
          case eventOptions.event_trophies.length &&
            eventOptions.event_trophies[0].id:
            return eventOptions.event_trophies[0].label;
          case eventOptions.event_car_branding.length &&
            eventOptions.event_car_branding[0].id:
            return eventOptions.event_car_branding[0].label;
          case eventOptions.event_track_branding.length &&
            eventOptions.event_track_branding[0].id:
            return eventOptions.event_track_branding[0].label;
          default:
            return '';
        }
      })
      .filter(Boolean)
      .join(',\n');

    return (
      <>
        <div className={styles.summaryBlock}>
          <span>{t('booking.participants')}</span>
          <span className={styles.summaryBlock__value}>
            {participantsAmount}
          </span>
        </div>
        <div className={styles.summaryBlock}>
          <span>{t('booking.steps.summary.where')}</span>
          <span className={styles.summaryBlock__value}>{loungeAddress}</span>
        </div>
        <div className={styles.summaryBlock}>
          <span>{t('booking.steps.summary.when')}</span>
          <span className={styles.summaryBlock__value}>{dateTime}</span>
        </div>
        <div className={styles.summaryBlock}>
          <span>{t('booking.food')}</span>
          <span className={styles.summaryBlock__value}>
            {food ? food.label : ''}
          </span>
        </div>
        <div className={styles.summaryBlock}>
          <span>{t('booking.steps.summary.raceProgram')}</span>
          <span className={styles.summaryBlock__value}>
            {program ? program.label : ''}
          </span>
        </div>
        <div className={styles.summaryBlock}>
          <span>{t('booking.extra')}</span>
          <span className={styles.summaryBlock__value}>{extra || '-'}</span>
        </div>
      </>
    );
  }, [
    eventOptions,
    participantsAmount,
    loungeId,
    foodId,
    programId,
    selectedExtras,
    lounges,
    time,
    duration,
    t
  ]);

  const allTermsAgreed = privacyRead && termsRead;

  return (
    <div className={styles.eventSummary}>
      <div className={styles.eventSummary__column}>
        <h3 className={styles.eventSummary__title}>
          {t('booking.steps.summary.eventSummary')}
        </h3>
        {eventSummary}
        <Button
          small
          outline
          className={styles.eventSummary__button}
          icon={<EditIcon />}
          label={t('booking.steps.summary.editEventDetails')}
          onClick={toggleSummary}
        />
        <Controller
          control={control}
          name="additionalRequest"
          rules={{
            ...emojisValidator({
              invalid: t('field.validations.emojisNotAllowed')
            })
          }}
          render={({ value, onChange }) => (
            <AppFormField
              label="field.additionalRequest.label"
              currentLength={value.length}
              maxLength={512}
              error={getFormError('additionalRequest', errors)}
            >
              <AppTextarea
                value={value}
                rows={4}
                maxLength={512}
                placeholder="field.additionalRequest.placeholder"
                onChange={onChange}
              />
            </AppFormField>
          )}
        />
      </div>
      <div className={styles.eventSummary__column}>
        <h3 className={styles.eventSummary__title}>
          {t('booking.steps.summary.youAreOneStepAway')}
        </h3>
        <img
          className={styles.eventSummary__image}
          src={oneStapAwayImg}
          alt="You are one step away"
        />
        {supervisor ? (
          <div className={styles.supervisor}>
            <h3 className={styles.eventSummary__title}>
              {t('booking.steps.summary.yourEventSupervisor')}
            </h3>
            <p>
              {t('booking.steps.summary.supervisorInfo', {
                name: supervisor.first_name
              })}
            </p>
            <div className={styles.supervisor__card}>
              <img
                src={supervisor.profile_pic_url}
                alt={supervisor.first_name}
              />
              <div>
                <p className={styles.supervisor__name}>
                  {supervisor.first_name}
                </p>
                <p>{supervisor.phone_number}</p>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={styles.eventSummary__column}>
        <h3 className={styles.eventSummary__title}>
          {t('booking.priceSummary')}
        </h3>
        <div className={styles.priceSummary}>
          {summary}
          {isPriceLoading ? (
            <Loader fullSize color="black" width={80} height={80} />
          ) : (
            ''
          )}
        </div>
        <AppFormField label="field.coupon.label">
          <div className={styles.eventSummary__couponField}>
            <AppInput
              withClear
              placeholder="field.coupon.placeholder"
              value={coupon}
              maxLength={128}
              onClear={() => {
                setCoupon('');
                setValue('coupon', '');
              }}
              onChange={({ target: { value } }) =>
                setCoupon(removeEmojis(value || ''))
              }
            />
            <Button
              small
              outline
              label={t('booking.couponApply')}
              onClick={() => {
                setValue('coupon', coupon);
              }}
            />
          </div>
        </AppFormField>
        <div className={styles.eventSummary__checkboxes}>
          <CheckboxRadio
            primary
            required
            checked={privacyRead}
            name="privacyPolicy"
            value="privacyPolicy"
            i18nTrans={
              <Trans i18nKey="field.privacyPolicy.label">
                I have read and agree to the
                <a
                  className={styles.eventSummary__link}
                  href={t('field.privacyPolicy.link')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                ."
              </Trans>
            }
            onChange={({ target: { checked } }) => setPrivacyRead(checked)}
          />
          <CheckboxRadio
            primary
            required
            checked={termsRead}
            name="termsOfUse"
            value="termsOfUse"
            i18nTrans={
              <Trans i18nKey="field.termsOfUse.label">
                I have read and agree to the
                <a
                  className={styles.eventSummary__link}
                  href={t('field.termsOfUse.link')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
                ."
              </Trans>
            }
            onChange={({ target: { checked } }) => setTermsRead(checked)}
          />
        </div>
        <Button
          disabled={!allTermsAgreed}
          className={styles.eventSummary__confirmButton}
          label={t('booking.steps.summary.confirmButton')}
          icon={<CheckIcon />}
          onClick={allTermsAgreed ? () => onSubmit() : undefined}
        />
      </div>
    </div>
  );
};

export default EventSummary;
